import axios from "axios";
import { store } from "../store/store";

const axiosInstance = axios.create({
  baseURL: `https://thedon.onrender.com/`,
  /* baseURL: `http://localhost:8080/`, */
});

axiosInstance.interceptors.request.use((config) => {
  const state = store.getState();
  const token = state.auth.auth.idToken;

  config.params = config.params || {};
  config.params["auth"] = token;
  /*   config.headers.Authorization = token; */
  config.headers.common["x-access-token"] = token;
  return config;
});

export default axiosInstance;
