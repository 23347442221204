import { useHistory } from "react-router-dom";
import { imageBaseURL } from "../../utils/const";
const CollectionDataComp = ({ data, handleDelete, handleEdit }) => {
  const history = useHistory();
  return (
    <>
      {data.map((collection) => {
        return (
          <tr key={collection?.id}>
            <td>
              <>{collection?.slNo}</>
            </td>
            <td>
              <img
                src={`${imageBaseURL}${collection?.product_feature_image_url}`}
                width={"100px"}
                height={"80px"}
                alt="Product Image"
                style={{ objectFit: "contain" }}
              />
              {console.log(
                `${imageBaseURL}${collection?.product_feature_image_url}`,
                "image"
              )}
            </td>
            <td>
              <>{collection?.product_name}</>
            </td>
            <td>{collection?.product_care}</td>
            <td>{collection?.status}</td>

            <td>
              <div className="d-flex">
                <button
                  onClick={() => {
                    /*   handleEdit(collection); */
                    history.push("/all-collections", {
                      state: { id: collection?.id, editMode: "true" },
                    });
                  }}
                  className="btn btn-primary shadow btn-xs sharp mr-3"
                >
                  <i className="fa fa-pencil"></i>
                </button>
                <button
                  onClick={() => {
                    handleDelete(collection?.id);
                  }}
                  className="btn btn-danger shadow btn-xs sharp"
                >
                  <i className="fa fa-trash"></i>
                </button>
              </div>
            </td>
          </tr>
        );
      })}
    </>
  );
};

export default CollectionDataComp;
