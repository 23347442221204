import React, { useState, useEffect, useRef } from "react";
import { Card, Button } from "react-bootstrap";
import Select from "react-select";
import "./addfeaturedetails.css";
import { btnStyle } from "../../utils/commonStyles";
// Components
import TableDataCol from "./TableDataCol";
import { collectionList, CollectionList } from "../../utils/const";
import AddFeatureDetails from "./AddFeatureDetails";
// import AddFeature from './AddFeature';

import { inputStyle, customStyles } from "../../utils/commonStyles";
import ModalComp from "../../ModalComp/ModalComp";
import makeAnimated from "react-select/animated";
import { useHistory, useLocation } from "react-router-dom";
import camera from "../../images/camera.svg";
import { imageBaseURL } from "../../utils/const";

//api call
import {
  addCollection,
  addSliderCollectionImage,
  addFeatureDetails,
  getFeatureImg,
  deleteFeatureImg,
  updateSliderImg,
  updateFeatureImg,
  updateCollection,
} from "../../utils/apiService/collection";

import { getAddOnApi } from "../../utils/apiService/addOn";
import swal from "sweetalert";
import axios from "axios";
import AddFeature from "./AddFeature";

const AddCollection = () => {
  const animatedComponents = makeAnimated();
  const formRef = useRef();
  const location = useLocation();
  const history = useHistory();

  const [deleteModal, setDeleteModal] = useState(false);
  const [openAddFeatureModal, setOpenAddFeatureModal] = useState(false);
  const [openEditFeatureModal, setOpenEditFeatureModal] = useState(false);
  //data
  const [density, setDensity] = useState("");
  const [color, setColor] = useState("");
  const [lace, setLace] = useState("");
  const [hair, setHair] = useState("");
  const [size, setSize] = useState("");
  const [lengthData, setLengthData] = useState("");
  //id
  const [densityId, setDensityId] = useState("");
  const [colorId, setColorId] = useState("");
  const [laceId, setLaceId] = useState("");
  const [hairId, setHairId] = useState("");
  const [sizeId, setSizeId] = useState("");
  const [lengthId, setLengthId] = useState("");

  //Obj
  const [densityIdObj, setDensityIdObj] = useState("");
  const [colorIdObj, setColorIdObj] = useState("");
  const [laceIdObj, setLaceIdObj] = useState("");
  const [hairIdObj, setHairIdObj] = useState("");
  const [sizeIdObj, setSizeIdObj] = useState("");
  const [lengthIdObj, setLengthIdObj] = useState("");

  //image
  const [image, setImage] = useState("");
  const [imgUpload, setImgUpload] = useState([]);
  const [featureImg, setFeatureImg] = useState("");
  const [sliderImages, setSliderImages] = useState([]);
  const [sliderImageUrls, setSliderImageUrls] = useState([]);
  const [sliderImgFile, setSliderImgFile] = useState("");
  const [productId, setProductId] = useState("");
  const [addFeaturesData, setAddFeaturesData] = useState("");
  const [callFeatureModal, setCallFeatureModal] = useState(false);
  //Add feature Modal state
  const [featureDetails, setFeatureDetails] = useState("");
  const [editFeaObj, setEditFeaObj] = useState("");
  const [featureId, setFeatureId] = useState("");
  const [storeLocation, setStoreLocation] = useState("");
  const [variantIdObj, setVariantIdObj] = useState("");
  const [collectionID, setCollectionID] = useState("");
  const [featuresData, setFeaturesData] = useState("");
  const [modalFormData, setModalFormData] = useState("");
  const [saveProductId, setSaveProductId] = useState("");
  const [addData, setAddData] = useState({
    product_name: "",
    detailed_features: "",
    product_care: "",
    product_feature_image_url: "",
    varaintIds: [],
  });

  // Add features data
  const [addFeatureImage, setAddFeatureImage] = useState("");
  const [selectedImg, setSelectedImg] = useState("");
  const [addValues, setAddValues] = useState({
    productId: "",
    product_detail_title: "",
    product_detail_content: "",
    detail_image_url: "",
  });

  useEffect(() => {
    if (location.pathname === "/edit-collection") {
      setAddData({
        ...addData,
        product_name: location.state?.details?.product_name,
        detailed_features: location.state?.details?.detailed_features,
        product_care: location.state?.details?.product_care,
        product_feature_image_url:
          location.state?.details?.product_feature_image_url,
        varaintIds: location.state?.details?.variants_options,
      });
      setFeatureDetails(location.state?.details?.collection_details_images);
      location.state.details?.variants_options.map((ele) => {
        setVariantIdObj(ele);
      });
      setSliderImageUrls(location.state?.details?.collection_images);
      console.log(sliderImageUrls, "slider image urls");
      // setSliderImages(location.state?.details?.collection_images)
      let sliderImgs = [];
      location.state?.details?.collection_images.forEach((item) => {
        sliderImgs.push({ image_url: item?.image_url });

        // setSliderImages({ ...item, image_url: item.image_url });
      });
      setSliderImages(location.state?.details?.collection_images);
      // setSliderImages(sliderImgs);
      // console.log(sliderImgs, "slider images");
      //collection  id
      setCollectionID(location.state?.details?.id);
    }
  }, [location]);

  const handleImageChange = (e) => {
    const formData = new FormData();
    const reader = new FileReader();
    let selectedFile = e.target.files[0];
    const value = URL.createObjectURL(e.target.files[0]);
    setFeatureImg(value);
    reader.readAsDataURL(selectedFile);
    reader.onload = (readerEvent) => {
      formData.append("product_feature_image_url", selectedFile);
      setImage(selectedFile);
    };
    setImgUpload(selectedFile);
  };
  const onSliderImageChange = (e) => {
    const imagedata = e.target.files;
    // console.log("imagedata", imagedata);
    setSliderImages(imagedata);
    const newSliderImageUrls = [];
    sliderImages?.forEach((item) =>
      newSliderImageUrls.push(URL.createObjectURL(item))
    );
    setSliderImageUrls(newSliderImageUrls);
  };

  const handleDelete = (id) => {
    setFeatureId(id);
    setDeleteModal(true);
  };

  const handleAddFeature = () => {
    setOpenAddFeatureModal(true);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setAddData({ ...addData, [name]: value });
    // setAddData({ ...addData, product_feature_image_url: featureImg });
  };
  const handleHairSelect = (e) => {
    if (e) {
      let selectValue = [];
      selectValue = e;
      let arrValue = [];
      selectValue.forEach((item) => {
        arrValue.push(item.id);
      });
      // let name;
      // const value = e.value;
      // arrValue = selectValue.map((ele) => {
      //   return ele.id;
      // });
      // let arrValue = [...values];
      setHairId(arrValue);

      // setAddData({
      //   ...addData,
      //   varaintIds: [
      //     ...densityId,
      //     ...hairId,
      //     ...laceId,
      //     ...colorId,
      //     ...sizeId,
      //     ...lengthId,
      //   ],
      // });

      // setVar_id({...var_id, varaintIds: [arrValue] })
      // setAddData({ ...addData, varaintIds: [arrValue] });
    }
  };
  // console.log(hairId, "?hairid");
  const handleColorSelect = (e) => {
    if (e) {
      let selectValue = [];
      selectValue = e;
      let arrValue = [];
      selectValue.forEach((item) => {
        arrValue.push(item.id);
      });
      let name;
      // const value = e.value;
      // arrValue = selectValue.map((ele) => {
      //   return ele.id;
      // });
      // let arrValue = [...values];
      // setVar_id({...var_id, varaintIds: [arrValue] })
      // setAddData({ ...addData, varaintIds: [arrValue] });
      setColorId(arrValue);
      setAddData({
        ...addData,
        varaintIds: [
          ...densityId,
          ...hairId,
          ...laceId,
          ...colorId,
          ...sizeId,
          ...lengthId,
        ],
      });
    }
  };
  const handleDensitySelect = (e) => {
    if (e) {
      let selectValue = [];
      selectValue = e;
      let arrValue = [];
      selectValue.forEach((item) => {
        arrValue.push(item.id);
      });
      let name;
      // const value = e.value;
      // arrValue = selectValue.map((ele) => {
      //   return ele.id;
      // });
      // let arrValue = [...values];
      // setVar_id({...var_id, varaintIds: [arrValue] })
      // setAddData({ ...addData, varaintIds: [arrValue] });
      setDensityId(arrValue);
      setAddData({
        ...addData,
        varaintIds: [
          ...densityId,
          ...hairId,
          ...laceId,
          ...colorId,
          ...sizeId,
          ...lengthId,
        ],
      });
    }
  };
  const handleLaceSelect = (e) => {
    if (e) {
      let selectValue = [];
      selectValue = e;
      let arrValue = [];
      selectValue.forEach((item) => {
        arrValue.push(item.id);
      });
      let name;
      // const value = e.value;
      // arrValue = selectValue.map((ele) => {
      //   return ele.id;
      // });
      // let arrValue = [...values];
      // setVar_id({...var_id, varaintIds: [arrValue] })
      // setAddData({ ...addData, varaintIds: [arrValue] });
      // console.log(arrValue, "laceid");
      setLaceId(arrValue);
      setAddData({
        ...addData,
        varaintIds: [
          ...densityId,
          ...hairId,
          ...laceId,
          ...colorId,
          ...sizeId,
          ...lengthId,
        ],
      });
    }
  };
  const handleLengthSelect = (e) => {
    if (e) {
      let selectValue = [];
      selectValue = e;
      let arrValue = [];
      selectValue.forEach((item) => {
        arrValue.push(item.id);
      });
      let name;
      // const value = e.value;
      // arrValue = selectValue.map((ele) => {
      //   return ele.id;
      // });
      // let arrValue = [...values];
      // setVar_id({...var_id, varaintIds: [arrValue] })
      // setAddData({ ...addData, varaintIds: [arrValue] });
      setLengthId(arrValue);
      setAddData({
        ...addData,
        varaintIds: [
          ...densityId,
          ...hairId,
          ...laceId,
          ...colorId,
          ...sizeId,
          ...lengthId,
        ],
      });
    }
  };
  const handleSizeSelect = (e) => {
    if (e) {
      let selectValue = [];
      selectValue = e;
      let arrValue = [];
      selectValue.forEach((item) => {
        arrValue.push(item.id);
      });
      let name;
      // const value = e.value;
      // arrValue = selectValue.map((ele) => {
      //   return ele.id;
      // });
      // let arrValue = [...values];
      // setVar_id({...var_id, varaintIds: [arrValue] })
      // setAddData({ ...addData, varaintIds: [arrValue] });
      setSizeId(arrValue);
      setAddData({
        ...addData,
        varaintIds: [
          ...densityId,
          ...hairId,
          ...laceId,
          ...colorId,
          ...sizeId,
          ...lengthId,
        ],
      });
    }
  };
  const variantIdUpload = () => {
    if (densityId || hairId || laceId || colorId || sizeId || lengthId) {
      setAddData({
        ...addData,
        varaintIds: [
          ...densityId,
          ...hairId,
          ...laceId,
          ...colorId,
          ...sizeId,
          ...lengthId,
        ],
      });
    }
  };
  // console.log(addData.varaintIds, "varaint id");
  //
  // console.log(addData, "Select Varient id data");
  // const uploadFeatureImg = () => {
  //   if (featureImg) {
  //     setAddData({ ...addData, product_feature_image_url: "crm_i" });
  //   }
  // };
  useEffect(() => {
    if (location.pathname === "/edit-collection") {
      let hairData = location.state?.details?.variants_options.map((item) => {
        if (item?.variant_name.toLowerCase() === "hair") {
          const data = {
            label: item?.variant_option,
            value: item?.id,
          };
          return data;
        }
      });
      setHairIdObj(hairData);
      //length
      let lengthData = location.state?.details?.variants_options.map((item) => {
        if (item?.variant_name.toLowerCase() === "length") {
          const data = {
            label: item?.variant_option,
            value: item?.id,
          };
          return data;
        }
      });
      setLengthIdObj(lengthData);
      //density
      let densityData = location.state?.details?.variants_options.map(
        (item) => {
          if (item?.variant_name.toLowerCase() === "density") {
            const data = {
              label: item?.variant_option,
              value: item?.id,
            };
            return data;
          }
        }
      );
      setDensityIdObj(densityData);
      //color
      let colorData = location.state?.details?.variants_options.map((item) => {
        if (item?.variant_name.toLowerCase() === "color") {
          const data = {
            label: item?.variant_option,
            value: item?.id,
          };
          return data;
        }
      });
      setColorIdObj(colorData);
      //size
      let sizeData = location.state?.details?.variants_options.map((item) => {
        if (item?.variant_name.toLowerCase() === "size") {
          const data = {
            label: item?.variant_option,
            value: item?.id,
          };
          return data;
        }
      });
      setSizeIdObj(sizeData);
      //lace
      let laceData = location.state?.details?.variants_options.map((item) => {
        if (item?.variant_name.toLowerCase() === "lace") {
          const data = {
            label: item?.variant_option,
            value: item?.id,
          };
          return data;
        }
      });
      setLaceIdObj(laceData);
    }
  }, []);

  const handleCollectionSubmit = (e) => {
    const formData = new FormData();
    formData.append("product_name", addData.product_name);
    formData.append("detailed_features", addData.detailed_features);
    formData.append("product_care", addData.product_care);
    if (location.pathname === "/edit-collection") {
      if (location.state?.details?.product_feature_image_url === " ") {
        // console.log("calling 1st block", image);
        formData.append("product_feature_image_url", image);
        // || image?.type === "'image/png'" || image?.type === "'image/jpg'"
      } else if (
        image?.type === "image/jpeg" ||
        image?.type === "image/png" ||
        image?.type === "image/jpg"
      ) {
        // console.log("calling 2nd block", image);
        formData.append("product_feature_image_url", image);
      } else {
        // console.log(
        //   "calling 3rd block",
        //   location.state?.details?.product_feature_image_url
        // );
        formData.append(
          "product_feature_image_url",
          location.state?.details?.product_feature_image_url
        );
      }
      if (location.state?.details?.variants_options === " ") {
        formData.append("varaintIds", addData.varaintIds);
      } else {
        formData.append("varaintIds", addData.varaintIds);
      }
    } else {
      // console.log("calling 4th block", image);
      formData.append("product_feature_image_url", image);
      formData.append("varaintIds", JSON.stringify(addData.varaintIds));
    }

    if (location.pathname === "/edit-collection") {
      updateCollection(location.state?.details?.id, formData)
        .then((res) => {
          if (res?.hasError == false) {
            // console.log("calling Slider Image function", sliderImages);
            callSliderImage(location.state?.details?.id);
            callFeatureDeatils(location.state?.details?.id);
          }
          swal("Success!", "Data Updated succesfully", "success");
        })
        .catch((err) => {
          swal("Oops!, Something went wrong", "error");
          // console.log(err,"error")
        });
    } else {
      addCollection(formData)
        .then((res) => {
          if (res?.hasError === false) {
            // console.log(res, "response after adding");
            setProductId(res?.data?.id);
            if (res?.data?.id) {
              callSliderImage(res?.data?.id);
              setSaveProductId(res?.data?.id);
              callFeatureDeatils(res?.data?.id);
            }
            // console.log(res, "response after adding details");
            swal("Success!", "Data submitted succesfully", "success");
          } else {
            swal("Oops!", "Feature data not added", "error");
          }
        })
        .catch((err) => {
          swal("Oops", err, "error");
        });
    }
  };
  // console.log(sliderImages, "slider image");

  const callSliderImage = (id) => {
    // console.log("calling Slider block", sliderImages);
    const imagesData = new FormData();
    // imagesData.append("product_id", id);

    for (let i = 0; i < sliderImages.length; i++) {
      imagesData.append("product_id", id);
      imagesData.append("image_url", sliderImages[i]);
    }
    // console.log(sliderImages.length, "length");
    // if (location.pathname === "/edit-collection") {
    //   if (location.state?.details?.collection_images === "") {
    //     // console.log("calling 1st block", sliderImages);
    //     imagesData.append("image_url", sliderImages);
    //   } else {
    //     if (sliderImages.length > 0) {
    //       // console.log(
    //       //   "calling 2nd block",
    //       //   sliderImages
    //       // );
    //       imagesData.append("image_url", sliderImages);
    //     } else {
    //       // console.log(
    //       //   "calling 3nd block",
    //       //   location.state?.details?.collection_images
    //       // );
    //       imagesData.append(
    //         "image_url",
    //         location.state?.details?.collection_images
    //       );
    //     }
    //   }
    // } else {
    //   // console.log("calling 4th block", sliderImages);
    //   imagesData.append("image_url", sliderImages);
    // }
    if (location.pathname === "/edit-collection") {
      updateSliderImg(collectionID, imagesData)
        .then((res) => {
          if (res?.hasError === false) {
            swal("Success!", "Slider Images added successfully", "success");
            // console.log(res, "response after adding");
          } else {
            swal("Oops!", "Images didnot uploaded", "error");
          }
        })
        .catch((err) => {
          swal("Oops", err, "error");
          // console.log(err, "error");
        });
    } else {
      addSliderCollectionImage(imagesData)
        .then((res) => {
          // console.log(res, "response from slider");
          setCallFeatureModal(true);
        })
        .catch((err) => {
          swal("Oops", err, "error");
          // console.log(err, "error");
        });
    }
  };
  const handleBack = () => {
    history.push("/collections");
  };

  const callFeaturesData = (featuresData) => {
    // console.log(featuresData, "data call features");
    // addFeatureDetails(data)
    //   .then((res) => {
    //     swal("Good Job!", "Add features added successfully", "success");
    //     console.log(res, "response after adding");
    //     // closeModal(false);
    //   })
    //   .catch((err) => {
    //     swal("Oops!", err, "error");
    //     console.log(err, "error");
    //     // closeModal(false);
    //   });
  };

  // for feature details edit
  const handleEdit = (obj) => {
    setEditFeaObj(obj);
    // console.log(editFeaObj, "object edit");
    setOpenAddFeatureModal(true);
    // console.log(obj, "edit object");
  };
  // For variants
  useEffect(() => {
    getVariant();
    // getFeatureDetails();
  }, []);

  const variant_data = (data) => {
    let variantData;

    // if (location.pathname === "/edit-collection") {
    //   variantData = location.state.details?.variants_options;
    // } else {
    //   variantData = data;
    // }

    variantData = data;
    //density
    let densityData = variantData.filter((ele) => {
      return ele?.variant_name.toLowerCase() === "density";
    });
    densityData.map((ele) => {
      ele.label = ele?.variant_option;
      ele.value = ele?.id;
    });
    setDensity(densityData);

    // if (location.pathname === "/edit-collection") {
    //   setDensityIdObj(densityData);
    // } else {
    //   setDensity(densityData);
    // }

    //length
    let lengthValue = variantData.filter((item) => {
      return item.variant_name.toLowerCase() === "length";
    });
    lengthValue.map((ele) => {
      ele.label = ele?.variant_option;
      ele.value = ele?.id;
    });
    setLengthData(lengthValue);

    // if (location.pathname === "/edit-collection") {
    //   setLengthIdObj(lengthValue);
    // } else {
    //   setLengthData(lengthValue);
    // }

    // console.log(lengthData, "length data");
    //color
    let colorData = variantData.filter((item) => {
      return item.variant_name.toLowerCase() === "color";
    });
    colorData.map((ele) => {
      ele.label = ele?.variant_option;
      ele.value = ele?.id;
    });
    setColor(colorData);

    // if (location.pathname === "/edit-collection") {
    //   setColorIdObj(colorData);
    // } else {
    //   setColor(colorData);
    // }

    //lace
    let laceData = variantData.filter((item) => {
      return item.variant_name.toLowerCase() === "lace";
    });
    laceData.map((ele) => {
      ele.label = ele?.variant_option;
      ele.value = ele?.id;
    });
    setLace(laceData);

    // if (location.pathname === "/edit-collection") {
    //   setLaceIdObj(laceData);
    // } else {
    //   setLace(laceData);
    // }

    //size
    let sizeData = variantData.filter((item) => {
      return item.variant_name.toLowerCase() === "size";
    });
    sizeData.map((ele) => {
      ele.label = ele?.variant_option;
      ele.value = ele?.id;
    });
    setSize(sizeData);

    // if (location.pathname === "/edit-collection") {
    //   setSizeIdObj(sizeData);
    // } else {
    //   setSize(sizeData);
    // }

    //hair
    let hairData = variantData.filter((item) => {
      return item.variant_name.toLowerCase() === "hair";
    });
    hairData.map((ele) => {
      ele.label = ele?.variant_option;
      ele.value = ele?.id;
    });
    setHair(hairData);
    // console.log(hair, "hair");
    // if (location.pathname === "/edit-collection") {
    //   setHairIdObj(hairData);
    // } else {
    //   setHair(hairData);
    // }
  };

  const getVariant = () => {
    getAddOnApi()
      .then((res) => {
        variant_data(res?.data);
      })
      .catch((err) => {
        // console.log(err, "Error ");
      });
  };
  const getFeatureDetails = () => {
    getFeatureImg()
      .then((res) => {
        // setFeatureDetails(res?.data);
        console.log(res, "feature details");
      })
      .catch((err) => {
        // console.log(err, "error");
      });
  };
  const handleDeleteConfirm = (id) => {
    if (id) {
      deleteFeatureImg(id)
        .then((res) => {
          if (res?.hasError === false)
            swal("Well Done!", "Deleted successfully", "success");
          // console.log(res, "res");
          getFeatureDetails();
          setDeleteModal(false);
        })
        .catch((err) => {
          // console.log(err, "error");
          setDeleteModal(false);
        });
    }
  };
  // console.log(featureDetails, "feature deatils");

  const onAddFeaturesSubmit = (e) => {
    e.preventDefault();
    // console.log(e, "on add features submit");
  };

  // ----------Add features ----------------------------------------------------------------------------

  const handleChangeImg = (e) => {
    const value = URL.createObjectURL(e.target.files[0]);
    setAddFeatureImage(value);
    let img = e.target.files[0];
    setSelectedImg(img);
  };
  // console.log(selectedImg, "selected img");

  const handleCancel = () => {
    setOpenAddFeatureModal(false);
  };

  const handleAddChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setAddValues({ ...addValues, [name]: value });
  };
  // console.log(addValues, "add values");

  const callFeatureDeatils = (id) => {
    const addFormData = new FormData();
    addFormData.append("productId", id);
    addFormData.append("product_detail_title", addValues.product_detail_title);
    addFormData.append(
      "product_detail_content",
      addValues.product_detail_content
    );
    if (location.pathname === "/edit-collection") {
      if (addValues.detail_image_url === " ") {
        addFormData.append("detail_image_url", selectedImg);
      } else {
        addFormData.append("detail_image_url", editFeaObj?.detail_image_url);
      }
    } else {
      addFormData.append("detail_image_url", selectedImg);
    }

    if (location.pathname === "/edit-collection") {
      updateFeatureImg(location.state?.details?.id, addFormData)
        .then((res) => {
          if (res?.hasError === false) {
            swal("Success!", "Add features added successfully", "success");
            // console.log(res, "response after adding");
            setOpenAddFeatureModal(false);
          } else {
            swal("Oops!", "Feature data not added", "error");
          }
          // getList();
        })
        .catch((err) => {
          swal("Oops!", err, "error");
          // console.log(err, "error");
          setOpenAddFeatureModal(false);
        });
      // console.log(formData.get(),"form values")
    } else {
      addFeatureDetails(addFormData)
        .then((res) => {
          if (res?.hasError === false) {
            swal("Success!", "Add features added successfully", "success");
            console.log(res, "response after adding");
            setOpenAddFeatureModal(false);
          } else {
            swal("Oops!", "Feature data not added", "error");
          }
          // getList();
        })
        .catch((err) => {
          swal("Oops!", err, "error");
          // console.log(err, "error");
          setOpenAddFeatureModal(false);
        });
      // console.log(obj, "object details");
    }
  };

  // const handleSubmit = () => {

  // };
  const handleAddFeatureSubmit = () => {
    setOpenAddFeatureModal(false);
    // featureDeatils();
  };
  useEffect(() => {
    setAddFeatureDeatils();
  }, [editFeaObj]);
  const setAddFeatureDeatils = () => {
    if (editFeaObj) {
      // let pid;
      // let prod_Title;
      // let prod_content;
      // let img_url;
      // location.state?.details?.collection_details_images.map((item) => {
      //   // item?.productId;
      //   // pid =
      //   // prod_Title = item?.product_detail_title,
      //   // prod_content = item?.product_detail_content,
      //   // img_url = item?.img_url
      // });
      setAddValues({
        ...addValues,

        productId: editFeaObj?.productId,
        product_detail_title: editFeaObj?.product_detail_title,
        product_detail_content: editFeaObj?.product_detail_content,
        detail_image_url: editFeaObj?.detail_image_url,
      });
    }
  };
  return (
    <div>
      <div className="container">
        <button
          type="button"
          onClick={handleBack}
          className="btn btn-info btn-square "
        >
          Back
        </button>
        <div className="text-center">
          <h3 className="text-center" style={{ textAlign: "center" }}>
            {" "}
            {location.pathname === "/edit-collection" ? (
              <h3>Edit Collection</h3>
            ) : (
              <h3>Add Collection</h3>
            )}
          </h3>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <div className="card">
              <div className="card-body">
                <form
                  ref={formRef}
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleCollectionSubmit();
                  }}
                >
                  <div className="form-group">
                    <label className="text-dark" htmlFor="">
                      Title
                    </label>
                    <input
                      style={inputStyle}
                      type="text"
                      name="product_name"
                      id=""
                      className="form-control"
                      placeholder="Title"
                      onChange={handleChange}
                      value={addData.product_name}
                    />
                  </div>
                  <div className="form-group">
                    <label className="text-dark">Hair</label>
                    {location.pathname === "/edit-collection" ? (
                      <Select
                        styles={customStyles}
                        className="select"
                        placeholder="Select Hair"
                        options={hair}
                        components={animatedComponents}
                        multi
                        onChange={(e) => handleHairSelect(e)}
                        value={hairIdObj}
                      />
                    ) : (
                      <Select
                        styles={customStyles}
                        className="select"
                        placeholder="Select Hair"
                        options={hair}
                        components={animatedComponents}
                        isMulti
                        onChange={(e) => handleHairSelect(e)}
                      />
                    )}
                  </div>
                  <div className="form-group">
                    <label className="text-dark">Length</label>
                    {location.pathname === "/edit-collection" ? (
                      <Select
                        styles={customStyles}
                        className="select"
                        placeholder="Select Length"
                        options={lengthData}
                        components={animatedComponents}
                        isMulti
                        onChange={(e) => handleLengthSelect(e)}
                        value={lengthIdObj}
                      />
                    ) : (
                      <Select
                        styles={customStyles}
                        className="select"
                        placeholder="Select Length"
                        options={lengthData}
                        components={animatedComponents}
                        isMulti
                        onChange={(e) => handleLengthSelect(e)}
                      />
                    )}
                  </div>
                  <div className="form-group">
                    <label className="text-dark">Density</label>
                    {location.pathname === "/edit-collection" ? (
                      <Select
                        styles={customStyles}
                        className="select"
                        placeholder="Select Density"
                        options={density}
                        components={animatedComponents}
                        isMulti
                        onChange={(e) => handleDensitySelect(e)}
                        value={densityIdObj}
                      />
                    ) : (
                      <Select
                        styles={customStyles}
                        className="select"
                        placeholder="Select Density"
                        options={density}
                        components={animatedComponents}
                        isMulti
                        onChange={(e) => handleDensitySelect(e)}
                      />
                    )}
                  </div>
                  <div className="form-group">
                    <label className="text-dark">Color</label>
                    {location.pathname === "/edit-collection" ? (
                      <Select
                        styles={customStyles}
                        className="select"
                        placeholder="Select Color"
                        options={color}
                        components={animatedComponents}
                        isMulti
                        onChange={(e) => handleColorSelect(e)}
                        value={colorIdObj}
                      />
                    ) : (
                      <Select
                        styles={customStyles}
                        className="select"
                        placeholder="Select Color"
                        options={color}
                        components={animatedComponents}
                        isMulti
                        onChange={(e) => handleColorSelect(e)}
                      />
                    )}
                  </div>
                  <div className="form-group">
                    <label className="text-dark">Size</label>
                    {location.pathname === "/edit-collection" ? (
                      <Select
                        styles={customStyles}
                        className="select"
                        placeholder="Select Size"
                        options={size}
                        components={animatedComponents}
                        isMulti
                        onChange={(e) => handleSizeSelect(e)}
                        value={sizeIdObj}
                      />
                    ) : (
                      <Select
                        styles={customStyles}
                        className="select"
                        placeholder="Select Size"
                        options={size}
                        components={animatedComponents}
                        isMulti
                        onChange={(e) => handleSizeSelect(e)}
                      />
                    )}
                  </div>
                  <div className="form-group">
                    <label className="text-dark">Lace</label>
                    {location.pathname === "/edit-collection" ? (
                      <Select
                        styles={customStyles}
                        className="select"
                        placeholder="Select Lace"
                        options={lace}
                        components={animatedComponents}
                        isMulti
                        onChange={(e) => handleLaceSelect(e)}
                        value={laceIdObj}
                      />
                    ) : (
                      <Select
                        styles={customStyles}
                        className="select"
                        placeholder="Select Lace"
                        options={lace}
                        components={animatedComponents}
                        isMulti
                        onChange={(e) => handleLaceSelect(e)}
                      />
                    )}
                  </div>
                  <div className="form-group">
                    <label className="text-dark" htmlFor="">
                      Detailed Features
                    </label>
                    <textarea
                      style={{ borderRadius: "4px" }}
                      className="form-control"
                      name="detailed_features"
                      placeholder="Detailed Features"
                      id=""
                      rows="4"
                      onChange={handleChange}
                      value={addData.detailed_features}
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <label className="text-dark" htmlFor="">
                      Product Care
                    </label>
                    <textarea
                      style={{ borderRadius: "4px" }}
                      className="form-control"
                      name="product_care"
                      placeholder="Product Care"
                      id=""
                      rows="4"
                      onChange={handleChange}
                      value={addData.product_care}
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <label className="mb-5">Feature Image</label>
                    <div className="image-container">
                      {location.pathname === "/edit-collection" ? (
                        <img
                          className="upload-img"
                          src={`${imageBaseURL}${addData?.product_feature_image_url}`}
                          // src={addData?.product_feature_image_url}
                          alt="feature image"
                          key={addData?.product_feature_image_url}
                          width={"100px"}
                          height={"100px"}
                        />
                      ) : (
                        <img
                          className="upload-img"
                          src={featureImg || camera}
                          alt=""
                          width={"150px"}
                          height={"100px"}
                        />
                      )}

                      <input
                        className="img-inp"
                        onChange={(e) => {
                          handleImageChange(e);
                        }}
                        accept="image/*"
                        type="file"
                        name=""
                        id=""
                      />
                    </div>
                    <div>
                      {/* <Dropzone
                        inputContent={"Upload Feature Image"}
                        inputWithFilesContent={""}
                        // submitButtonContent={"Save"}
                        // getUploadParams={getUploadParams}
                        onChangeStatus={handleFeatureImg}
                        // onSubmit={handleFeatureImg}
                        maxFiles={1}
                        multiple={false}
                        accept="image/*"
                        styles={{
                          dropzone: {
                            cursor: "pointer",
                            minHeight: 150,
                            minWidth: 150,
                          },
                        }}
                      /> */}
                      {/* {featureImg && (
                        <img
                          id="blah"
                          style={{ width: "50px", height: "50px" }}
                          src={featureImg}
                          alt="preview"
                        />
                      )} */}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="mb-5">
                      Slider Image (Select Multiple Images)
                    </label>
                    <div>
                      <input
                        type="file"
                        multiple
                        accept="image/*"
                        onChange={onSliderImageChange}
                      />
                      {location.pathname === "/edit-collection" ? (
                        <div>
                          {sliderImageUrls.map((imageSrc) => (
                            <img
                              src={`${imageBaseURL}${imageSrc?.image_url}`}
                              alt="Slider Image"
                              style={{ margin: "10px 20px" }}
                              width={"150px"}
                              height={"100px"}
                            />
                          ))}
                        </div>
                      ) : (
                        <div>
                          {sliderImageUrls.map((imageSrc) => (
                            <img
                              src={imageSrc}
                              alt="Slider Image"
                              style={{ margin: "10px 20px" }}
                              width={"150px"}
                              height={"100px"}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                    <div>
                      {/* <Dropzone
                        inputContent={"Upload Slider Images"}
                        inputWithFilesContent={"Add More"}
                        // submitButtonContent={"Save"}
                        // getUploadParams={getUploadParams}
                        onChangeStatus={handleSliderImg}
                        // onSubmit={handleSliderImg}
                        maxFiles={5}
                        multiple={true}
                        accept="image/*"
                        styles={{
                          dropzone: {
                            color: "red",
                            minHeight: 150,
                            minWidth: 150,
                          },
                        }}
                      /> */}
                      {/* {featureImg && (
                        <img
                          id="blah"
                          style={{ width: "50px", height: "50px" }}
                          src={featureImg}
                          alt="preview"
                        />
                      )} */}
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="button float-right my-3">
                      <button
                        type="button"
                        data-target="#exampleModalCenter"
                        className="btn btn-square  btn-success"
                        onClick={handleAddFeature}
                      >
                        Add Details
                      </button>
                    </div>
                    <label className="text-dark" htmlFor="">
                      Product Details
                    </label>
                    <table className="table mt-4">
                      <thead style={{ background: "#888888" }}>
                        <tr>
                          <th>Preview</th>
                          <th>Title</th>
                          <th>Description</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {featureDetails && (
                          <TableDataCol
                            data={featureDetails}
                            handleDelete={(id) => handleDelete(id)}
                            handleEdit={(obj) => handleEdit(obj)}
                          />
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="row">
                    <div className="col-sm-2"></div>
                    <div className="col-sm-8">
                      <div className="container">
                        <div className="row text-center">
                          <div className="col-sm-6">
                            <button
                              type="button"
                              className="btn btn-square btn-danger"
                            >
                              Cancel
                            </button>
                          </div>

                          <div className="col-sm-6">
                            <button
                              type="submit"
                              className="btn btn-square btn-success"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-2"></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalComp
        isOpen={openAddFeatureModal}
        setisOpen={setOpenAddFeatureModal}
        title={
          location.pathname === "/edit-collection"
            ? "Edit Feature Details"
            : "Add Feature Details"
        }
        hideButtons
      >
        <>
          <Card className="light">
            <Card.Body className="">
              <form
                ref={formRef}
                onSubmit={(e) => {
                  e.preventDefault();
                  // handleAddFeatureSubmit();
                }}
                action=""
              >
                <div className="">
                  <div className="form-group">
                    <label className="text-dark" htmlFor="title">Title</label>
                    <input
                      className="input-fields title"
                      type="text"
                      placeholder="Title"
                      name="product_detail_title"
                      onChange={handleAddChange}
                      value={addValues.product_detail_title}
                    />
                  </div>
                  <div className="form-group">
                    <label className="text-dark" htmlFor="description">Description</label>
                    <textarea
                      placeholder="Description"
                      className="input-fields description"
                      name="product_detail_content"
                      id=""
                      cols="30"
                      rows="10"
                      onChange={handleAddChange}
                      value={addValues.product_detail_content}
                    ></textarea>
                  </div>
                  <div className="image-container">
                    {location.pathname === "/edit-collection" ? (
                      !addFeatureImage ? (
                        <img
                          className="upload-img"
                          src={`${imageBaseURL}${addValues.detail_image_url}`}
                          alt="Details image"
                        />
                      ) : (
                        <img
                          className="upload-img"
                          src={addFeatureImage || camera}
                          alt="Details image"
                        />
                      )
                    ) : (
                      <>
                        <img
                          className=" upload-img"
                          src={addFeatureImage || camera}
                          alt=""
                        />
                      </>
                    )}

                    <input
                      className="img-inp"
                      onChange={(e) => {
                        handleChangeImg(e);
                      }}
                      accept="image/*"
                      type="file"
                      name="image_url"
                      id=""
                    />
                  </div>
                  <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10 mt-3">
                      <div className="row text-center">
                        <div className="col-sm-5">
                          <Button
                            style={btnStyle}
                            onClick={handleCancel}
                            variant="danger light"
                            type="button"
                          >
                            Cancel
                          </Button>
                        </div>
                        <div className="col-sm-2"></div>
                        <div className="col-sm-5">
                          {location.pathname === "/edit-collection" ? (
                            <Button
                              style={btnStyle}
                              variant="success light"
                              type="submit"
                              onClick={handleCancel}
                            >
                              Update
                            </Button>
                          ) : (
                            <Button
                              style={btnStyle}
                              variant="success light"
                              type="submit"
                              onClick={handleCancel}
                              // onClick={() => {
                              //   handleSubmit;
                              //   handleCancel;
                              // }}
                            >
                              Add
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-1"></div>
                  </div>
                </div>
              </form>
            </Card.Body>
          </Card>
        </>
      </ModalComp>
      <ModalComp
        isOpen={deleteModal}
        setisOpen={setDeleteModal}
        handleConfirm={() => handleDeleteConfirm(featureId)}
        title="Delete Client"
      >
        <p className="text-center">Are you sure?</p>
      </ModalComp>

      {/* <AddFeature isOpen={isAddOpen} setisOpen={setIsAddOpen} /> */}
      {/* <ModalComp
        isOpen={openEditFeatureModal}
        setisOpen={setOpenEditFeatureModal}
        title="Edit Feature Details"
        hideButtons
      >
        <AddFeatureDetails
          id={productId}
          obj={editFeaObj}
          closeModal={setOpenEditFeatureModal}
          addFeaturesData={setCallFeatureModal}
          getList={getFeatureDetails}
        />
      </ModalComp> */}
      {/* <ModalComp
        isOpen={openAddFeatureModal}
        setisOpen={setOpenAddFeatureModal}
        title="Add Feature Details"
        hideButtons
      >
        <AddFeatureDetails
          id={productId}
          closeModal={setOpenAddFeatureModal}
          setOpenAddFeatureModal={setOpenAddFeatureModal}
          addFeaturesData={setCallFeatureModal}
          getList={getFeatureDetails}
          setModalFormData={setModalFormData}
        />
      </ModalComp> */}
    </div>
  );
};
export default AddCollection;
