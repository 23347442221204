export const inputStyle = {
  height: '50px',
  borderRadius: '4px',
  padding: '4px',
  outline: 'none',
  backgroundColor: 'transparent',
  border: '1px solid #5b5289',
  color: 'text-dark',
};
export const btnStyle = {
  height: '50px',
  width: '120px',
  borderRadius: '4px',
  padding: '4px',
};

// Dropdown
export const customStyles = {
  container: (provided, state) => ({
    ...provided,
    border: '1px solid gray',
    borderRadius: '4px',
    minWidth: '49%',
  }),
  control: (provided, state) => ({
    ...provided,
    border: 'none',
    borderColor: 'transparent',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: '50px',
    backgroundColor: 'white',
    borderRadius: '4px 0 0 4px',
    border: 'black',
    borderWidth: 1,
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '50px',
    backgroundColor: 'white',
    borderRadius: '0 4px 4px 0',
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px solid lightgrey',
    color: state.isSelected ? 'white' : 'black',
    padding: 10,
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#3B3363',

    border: '1px solid #5b5289',
    borderRadius: '3px',
    minWidth: 'fit-content',
    height: 'fit-content',
    padding: '4px 0',
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    // color: '#3B3363',
    color: 'white',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '4px',
    fontWeight: '500',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#3B3363',
    color: 'white',
    padding: '4px 8px',
    borderRadius: '3px',
    border: '1px solid #5b5289',
  }),
};

export const searchInputStyle = {
  height: '50px',
  width: '50%',
  borderRadius: '4px',
  padding: '4px',
  outline: 'none',
  backgroundColor: 'transparent',
  border: '1px solid #5b5289',
  color: 'white',
};
