import { useState, useEffect } from 'react';
import { Col, Card, Table } from 'react-bootstrap';
import swal from '@sweetalert/with-react';

// Components
import ModalComp from '../../ModalComp/ModalComp';
import AddonsTableComp from './AddonsTableComp';
import AddAdons from './AddAdons';
import PaginationComp from '../PaginationComp/PaginationComp';

// Utils
// import { addonList } from '../../utils/const';
import { modalOpener } from '../../utils/commonFn';
import { searchInputStyle } from '../../utils/commonStyles';

// API
import {
  getAddOnApi,
  deleteAddOnApi,
  getAddOnById,
} from '../../utils/apiService/addOn';

const Addons = () => {
  const [openDeleteAddonModal, setOpenDeleteAddonModal] = useState(false);
  const [openEditAddonModal, setOpenEditAddonModal] = useState(false);
  const [openAddAddonModal, setOpenAddAddonModal] = useState(false);

  const [displayData, setDisplayData] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSearchedPage, setCurrentSearchedPage] = useState(1);

  const [isSearched, setIsSearched] = useState(false);

  const [searchedText, setSearchedText] = useState('');
  const [searchedResult, setSearchedResult] = useState('');
  const [isNotFound, setIsNotFound] = useState(false);

  const [addonList, setAddonList] = useState('');
  const [addOnId, setaddOnId] = useState('');
  const [dataToEdit, setDataToEdit] = useState('');

  const disaplayDataHandler = (data) => {
    setDisplayData(data);
  };

  const handleDelete = (id) => {
    setaddOnId(id);
    modalOpener(setOpenDeleteAddonModal);
  };

  // Search
  const provideSearchData = (data, searchedText) => {
    const filteredArr = data.filter((item) => {
      return (
        item.variant_name?.toLowerCase().trim().includes(searchedText) ||
        item.variant_option?.toLowerCase().trim().includes(searchedText) ||
        // item.sNo.toString().toLowerCase().includes(searchedText) ||
        item.variant_amount?.toString().toLowerCase().includes(searchedText) ||
        item.status?.toLowerCase().trim().includes(searchedText)
      );
    });
    if (filteredArr.length) {
      setSearchedResult(filteredArr);
      setIsNotFound(false);
      return;
    }
    setIsNotFound(true);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    if (value) {
      const trimmedSearchedText = value.toString().toLowerCase().trim();
      setIsSearched(true);
      setSearchedText(value);
      provideSearchData(addonList, trimmedSearchedText);
      return;
    }
    setIsSearched(false);
    setSearchedText('');
    setCurrentSearchedPage(1);
    setSearchedResult('');
    setIsNotFound(false);

    return;
  };

  const handleEdit = (obj) => {
    setDataToEdit(obj);
    modalOpener(setOpenEditAddonModal);
  };

  // API Call
  const getAddonList = () => {
    getAddOnApi()
      .then((res) => {
        if (res.data.length) {
          res.data.map((addon, index) => {
            addon.sNo = index + 1;
          });
          setAddonList(res.data);
        }
      })
      .catch((err) => console.log(err));
  };

  // Confirm delete
  const handleConfirmDelete = (id) => {
    if (id) {
      deleteAddOnApi(id)
        .then((res) => {
          swal('Success', res.message, 'success');

          getAddonList();
          setaddOnId('');
        })
        .catch((err) => {
          console.log(err);
        });

      setOpenDeleteAddonModal(false);
    }
  };

  useEffect(() => {
    getAddonList();
  }, []);

  return (
    <div>
      <Col lg={12}>
        <Card>
          <Card.Header>
            <Card.Title>Addons</Card.Title>
            <button
              onClick={() => modalOpener(setOpenAddAddonModal)}
              className='btn btn-info shadow  '
            >
              Add Addon Variant
            </button>
          </Card.Header>
          <Card.Body>
            <div className='form-group'>
              <input
                type='search'
                style={searchInputStyle}
                aria-describedby='helpId'
                placeholder='Search anything....'
                onChange={(e) => handleSearch(e)}
              />
            </div>
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <strong>S.No</strong>
                  </th>
                  <th>
                    <strong>Variant name</strong>
                  </th>
                  <th>
                    <strong>Variant Value</strong>
                  </th>
                  <th>
                    <strong>Price</strong>
                  </th>
                  <th>
                    <strong>Status</strong>
                  </th>
                  <th>
                    <strong>Actions</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {!isNotFound && displayData ? (
                  <AddonsTableComp
                    data={displayData}
                    handleEdit={(obj) => handleEdit(obj)}
                    handleDelete={(id) => handleDelete(id)}
                  />
                ) : (
                  <tr>
                    <td>
                      <h2>No data found</h2>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Card.Body>
          <Card.Footer>
            {searchedResult ? (
              <PaginationComp
                data={searchedResult}
                currentPage={currentSearchedPage}
                setCurrentPage={setCurrentSearchedPage}
                disaplayDataHandler={disaplayDataHandler}
                searchedText={searchedText}
              />
            ) : addonList ? (
              <PaginationComp
                data={addonList}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                disaplayDataHandler={disaplayDataHandler}
              />
            ) : null}
          </Card.Footer>
        </Card>
      </Col>

      <ModalComp
        isOpen={openDeleteAddonModal}
        setisOpen={setOpenDeleteAddonModal}
        handleConfirm={() => handleConfirmDelete(addOnId)}
        title='Delete Addon'
      >
        <p>Are you sure?</p>
      </ModalComp>

      <ModalComp
        isOpen={openEditAddonModal}
        setisOpen={setOpenEditAddonModal}
        title='Edit Addon'
        hideButtons
      >
        <AddAdons
          obj={dataToEdit}
          getList={getAddonList}
          closeModal={setOpenEditAddonModal}
        />
      </ModalComp>

      <ModalComp
        isOpen={openAddAddonModal}
        setisOpen={setOpenAddAddonModal}
        title='Add Addon'
        hideButtons
      >
        <AddAdons getList={getAddonList} closeModal={setOpenAddAddonModal} />
      </ModalComp>
    </div>
  );
};

export default Addons;
