import axios from 'axios';
import axiosInstance from '../../../../services/AxiosInstance';

// Create new services
export async function addServiceApi(value) {
  try {
    const res = await axiosInstance.post('don/services', value);

    if (res?.status !== 200) {
      return 'No Response From API';
    }

    return res?.data;
  } catch (error) {
    return 'Server Error';
  }
}
export async function editServiceApi(id, value) {
  try {
    const res = await axiosInstance.put(`don/services/${id}`, value);

    if (res?.status !== 200) {
      return 'No Response From API';
    }

    return res?.data;
  } catch (error) {
    return 'Server Error';
  }
}

// Gives the service list
export async function getServicesApi() {
  try {
    const res = await axiosInstance.get('don/services');
    return res?.data;
  } catch (err) {
    console.log(err);
  }
}

export async function deleteServiceApi(id) {
  try {
    const res = await axiosInstance.delete(`don/services/${id}`);
    return res?.data;
  } catch (err) {
    console.log(err);
  }
}

// Time Slot
export async function addSdsTimeSlotApi(value) {
  try {
    const res = await axiosInstance.post('don/serv_time_slots', value);

    if (res?.status !== 200) {
      return 'No Response From API';
    }

    return res?.data;
  } catch (error) {
    return 'Server Error';
  }
}

export async function editSdsTimeSlotApi(id, value) {
  try {
    const res = await axiosInstance.put(`don/serv_time_slots/${id}`, value);

    if (res?.status !== 200) {
      return 'No Response From API';
    }

    return res?.data;
  } catch (error) {
    return 'Server Error';
  }
}

export async function getSdsSlotListApi() {
  try {
    const res = await axiosInstance.get('don/serv_time_slots');
    return res?.data;
  } catch (err) {
    console.log(err);
  }
}

export async function deleteSdsSlotApi(id) {
  try {
    const res = await axiosInstance.delete(`don/serv_time_slots/${id}`);
    return res?.data;
  } catch (err) {
    console.log(err);
  }
}
