const TableDataComp = ({ data, hanndleDelete, handleEdit }) => {
  return (
    <>
      {data.map((client, i) => {
        return (
          <tr key={client?.id}>
            <td className='py-3'>
              <>{client?.slNo}</>
            </td>
            <td className='py-3'>
              <>{`${client?.firstName} ${client?.lastName || ''}`}</>
            </td>
            <td className='py-3'>
              <>{client?.email_id}</>
            </td>
            {/* <td className='py-3'>
               {client?.delivery_options?.map((ele) => {
                return ele.phone ? ele.phone : '-';
              })}
               {client?.delivery_options.length === 0 ? '-' : null}
            </td> */}
            <td className='py-3'>{client?.status}</td>

            <td className='py-3'>
              <div className='d-flex'>
                <button
                  onClick={() => handleEdit(client)}
                  className='btn btn-primary shadow btn-xs sharp mr-3'
                >
                  <i className='fa fa-pencil'></i>
                </button>
                <button
                  onClick={() => {
                    hanndleDelete(client?.id);
                  }}
                  className='btn btn-danger shadow btn-xs sharp'
                >
                  <i className='fa fa-trash'></i>
                </button>
              </div>
            </td>
          </tr>
        );
      })}
    </>
  );
};

export default TableDataComp;
