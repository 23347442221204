import axios from 'axios';
import axiosInstance from '../../../../services/AxiosInstance';

// Create new services
export async function addSdiServiceApi(value) {
  try {
    const res = await axiosInstance.post('don/sameday-sub-services', value);

    if (res?.status !== 200) {
      return 'No Response From API';
    }

    return res?.data;
  } catch (error) {
    return 'Server Error';
  }
}

export async function editSubServiceApi(id, value) {
  try {
    const res = await axiosInstance.put(
      `don/sameday-sub-services/${id}`,
      value
    );

    if (res?.status !== 200) {
      return 'No Response From API';
    }

    return res?.data;
  } catch (error) {
    return 'Server Error';
  }
}

export async function getSdiServicesApi() {
  try {
    const res = await axiosInstance.get('don/sameday-sub-services');
    return res?.data;
  } catch (err) {
    console.log(err);
  }
}

export async function deleteSdiServiceApi(id) {
  try {
    const res = await axiosInstance.delete(`don/sameday-sub-services/${id}`);
    return res?.data;
  } catch (err) {
    console.log(err);
  }
}

// TIME SLOT

export async function addSdiTimeSlotApi(value) {
  try {
    const res = await axiosInstance.post('don/sdi-time-slots', value);

    if (res?.status !== 200) {
      return 'No Response From API';
    }

    return res?.data;
  } catch (error) {
    return 'Server Error';
  }
}

export async function editSdiTimeSlotApi(id, value) {
  try {
    const res = await axiosInstance.put(`don/sdi-time-slots/${id}`, value);

    if (res?.status !== 200) {
      return 'No Response From API';
    }

    return res?.data;
  } catch (error) {
    return 'Server Error';
  }
}

export async function getSdiSlotListApi() {
  try {
    const res = await axiosInstance.get('don/sdi-time-slots');
    return res?.data;
  } catch (err) {
    console.log(err);
  }
}

export async function deleteSdiSlotApi(id) {
  try {
    const res = await axiosInstance.delete(`don/sdi-time-slots/${id}`);
    return res?.data;
  } catch (err) {
    console.log(err);
  }
}
