import axios from "axios";
import axiosInstance from "../../../services/AxiosInstance";

// get all users
export async function getAllUsers() {
  try {
    const res = await axiosInstance.get("don/users");
    if (res?.status !== 200) {
      return "No Response from API";
    }
    return res?.data;
  } catch (error) {
    console.log("error", error);
    return false;
  }
}

// get all Members
export async function getAllMembers() {
  try {
    const res = await axiosInstance.get("don/getmembers");
    if (res?.status !== 200) {
      return "No Response from API";
    }
    return res?.data;
  } catch (error) {
    console.log("error", error);
    return false;
  }
}

// delete user

export async function deleteUser(userid) {
  console.log(userid, "userid");
  try {
    const res = await axiosInstance.delete(`don/users/${userid}`);
    if (res?.status !== 200) {
      return "No response from API";
    }
    return res?.data;
  } catch (error) {
    console.log("error", error);
    return false;
  }
}

//add user

export async function addUser(value) {
  try {
    console.log("VALUE", value);
    const res = await axiosInstance.post("don/users", value);
    if (res.status !== 200) {
      return "No response from API";
    }
    return res?.data;
  } catch (error) {
    console.log("error", error);
    return false;
  }
}
// update user
export async function updateUser(id, value) {
  try {
    const res = await axiosInstance.put(`don/users/${id}`, value);
    if (res.status !== 200) {
      return "No response from API";
    }
    return res?.data;
  } catch (error) {
    console.log("error", error);
    return false;
  }
}
