import { imageBaseURL } from "../../utils/const";

const TableData = ({ data, handleDelete, handleEdit }) => {
  return (
    <>
      {data?.map((collection) => {
        return (
          <tr key={collection?.id}>
            <td>
              <img
                src={`${imageBaseURL}/${collection?.detail_image_url}`}
                width={"100px"}
                height={"100px"}
              ></img>
            </td>
            <td>{collection?.product_detail_title}</td>
            <td>{collection?.product_detail_content}</td>
            <td>
              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-primary shadow btn-xs sharp mr-1"
                  onClick={() => handleEdit(collection)}
                >
                  <i className="fa fa-pencil"></i>
                </button>
                <button
                  onClick={() => handleDelete(collection?.id)}
                  type="button"
                  className="btn btn-danger shadow btn-xs sharp"
                >
                  <i className="fa fa-trash"></i>
                </button>
              </div>
            </td>
          </tr>
        );
      })}
    </>
  );
};
export default TableData;
