import { deleteAddOnApi } from '../../utils/apiService/addOn';
const AddonsTableComp = ({ data, handleDelete, handleEdit }) => {
  return (
    <>
      {data.map((addon, index) => {
        return (
          <tr key={addon.id}>
            <td className='py-3'>
              <>{addon.sNo}</>
            </td>
            <td className='py-3'>
              <>{addon.variant_name}</>
            </td>
            <td className='py-3'>{addon.variant_option}</td>
            <td className='py-3'>{addon.variant_amount}</td>
            <td className='py-3'>{addon.status}</td>

            <td className='py-3'>
              <div className='d-flex'>
                <button
                  onClick={() => handleEdit(addon)}
                  className='btn btn-primary shadow btn-xs sharp mr-3'
                >
                  <i className='fa fa-pencil'></i>
                </button>
                <button
                  onClick={() => {
                    handleDelete(addon.id);
                  }}
                  className='btn btn-danger shadow btn-xs sharp'
                >
                  <i className='fa fa-trash'></i>
                </button>
              </div>
            </td>
          </tr>
        );
      })}
    </>
  );
};

export default AddonsTableComp;
