import React, { useState } from "react";
import { imageBaseURL } from "../../../utils/const";

function CollectionInfo(props) {
  const featureimage = props.feature_img;
  /* 
  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  let imageurl = `${imageBaseURL}${props.allEditResData?.product_feature_image_url}`;

  var base64 = getBase64FromUrl(imageurl).then((res) => {}); */

  console.log("propsdatedit data", props.allEditResData); //null is default value
  return (
    <div>
      <div className="form-group">
        <label className="text-dark" htmlFor="">
          Collection Name *
        </label>
        <input
          /*           style={inputStyle} */
          type="text"
          name="product_name"
          id=""
          value={props.collectionInfo?.product_name}
          className="form-control"
          /*           placeholder="collection name" */
          onChange={props.collectionInfoHandleChange}
          /*         value={addData.product_name} */
        />
      </div>
      {/*       <div className="form-group">
        <label className="text-dark" htmlFor="">
          Price
        </label>
        <input
       
          type="text"
          name="product_price"
          id=""
          value={props.collectionInfo?.product_price}
          className="form-control"
          onChange={props.collectionInfoHandleChange}
     
        />
      </div> */}
      <div className="form-group">
        <label className="text-dark" htmlFor="">
          Product care *
        </label>
        <textarea
          /*           style={inputStyle} */
          type="text"
          name="product_care"
          id=""
          value={props.collectionInfo?.product_care}
          className="form-control"
          onChange={props.collectionInfoHandleChange}
          /*  placeholder="Title" */
          rows={4}
          /*           onChange={handleChange}
          value={addData.product_name} */
        />
      </div>
      <div className="form-group">
        <label className="text-dark" htmlFor="">
          Detailed features *
        </label>
        <textarea
          /*           style={inputStyle} */
          type="text"
          name="detailed_features"
          id=""
          value={props.collectionInfo?.detailed_features}
          className="form-control"
          onChange={props.collectionInfoHandleChange}
          /*           placeholder="Title" */
          rows={4}

          /*           onChange={handleChange}
          value={addData.product_name} */
        />
      </div>

      <div className="form-group">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            border: "1px solid grey",
            borderRadius: 8,
            minHeight: 50,
          }}
        >
          {props.feature_img === null && (
            <label className="text-dark" htmlFor="selectphoto">
              <span
                style={{
                  background: "orange",
                  padding: 10,
                  borderRadius: 20,
                  cursor: "pointer",
                }}
              >
                Feature Image *
              </span>
            </label>
          )}
          <input
            type="file"
            id="selectphoto"
            accept="image/*"
            style={{ display: "none" }}
            onChange={props.FeatureImageHandler}
            onClick={props.setFeature_IMG}
          />

          {/*   <i class="fa fa-picture-o" aria-hidden="true"></i>{" "} */}
          <span style={{ paddingLeft: 10 }}> </span>

          {props.feature_img !== null && (
            <>
              <img
                src={featureimage}
                height={200}
                width={200}
                style={{ objectFit: "contain" }}
              />
              <button
                className="btn btn-primary"
                onClick={() => props.setFeature_IMG(null)}
              >
                Remove
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default CollectionInfo;
