const DropOffSdsTable = ({ data, handleEdit, handleDelete }) => {
  return (
    <>
      {data.map((service) => {
        return (
          <tr key={service.id}>
            {/*             <td className='py-3'>
              <>{`${service.sNo}. ${service.service_type}`}</>
            </td> */}
            <td className="py-3">
              <>{service.service_name}</>
            </td>
            {/*             <td className="py-3">{service.service_price || "none"}</td>
            <td className="py-3">{`${+service.duration_value || "none"}`}</td> */}
            <td className="py-3">{service.status}</td>

            <td className="py-3">
              <div className="d-flex">
                <button
                  onClick={() => {
                    handleEdit(service);
                  }}
                  className="btn btn-primary shadow btn-xs sharp mr-3"
                >
                  <i className="fa fa-pencil"></i>
                </button>
                <button
                  onClick={() => {
                    handleDelete(service.id);
                  }}
                  className="btn btn-danger shadow btn-xs sharp"
                >
                  <i className="fa fa-trash"></i>
                </button>
              </div>
            </td>
          </tr>
        );
      })}
    </>
  );
};

export default DropOffSdsTable;
