const AdditionalServiceTable = ({
  data,
  handleEdit,
  handleDelete,
  handleTimeSlot,
}) => {
  return (
    <>
      {data.map((addService, i) => {
        return (
          <tr key={addService.id}>
            <td className='py-3'>
              <>{`${addService.sNo}. ${addService.adnl_services.additional_service_name}`}</>
            </td>
            <td className='py-3'>
              <>{addService.adnl_subservice_option}</>
            </td>
            <td className='py-3'>{addService.adnl_subservice_price}</td>
            <td className='py-3'>{`${addService.duration_value}${addService.duration_type}`}</td>
            <td className='py-3'>{addService.status}</td>

            <td className='py-3'>
              <div className='d-flex'>
                <button
                  onClick={() => handleEdit(addService)}
                  className='btn btn-primary shadow btn-xs sharp mr-3'
                >
                  <i className='fa fa-pencil'></i>
                </button>
                <button
                  onClick={() => {
                    handleDelete(addService.id);
                  }}
                  className='btn btn-danger shadow btn-xs sharp'
                >
                  <i className='fa fa-trash'></i>
                </button>
              </div>
            </td>
          </tr>
        );
      })}
    </>
  );
};

export default AdditionalServiceTable;
