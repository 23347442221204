import axiosInstance from "../../../../services/AxiosInstance";

// Create new services
export async function addAdditionalServiceApi(value) {
  try {
    const res = await axiosInstance.post("don/sameday-sub-services", value);

    if (res?.status !== 200) {
      return "No Response From API";
    }

    return res?.data;
  } catch (error) {
    return "Server Error";
  }
}

export async function editAdditionalServiceApi(id, value) {
  try {
    const res = await axiosInstance.put(
      `don/sameday-sub-services/${id}`,
      value
    );

    if (res?.status !== 200) {
      return "No Response From API";
    }

    return res?.data;
  } catch (error) {
    return "Server Error";
  }
}

export async function getAdditionalServicesApi() {
  try {
    const res = await axiosInstance.get("don/subservices");
    return res?.data;
  } catch (err) {
    console.log(err);
  }
}

export async function deleteAdditionalServiceApi(id) {
  try {
    const res = await axiosInstance.delete(`don/subservices/${id}`);
    return res?.data;
  } catch (err) {
    console.log(err);
  }
}

// STATIC datas

export async function getAdditionalStaticServicesApi() {
  try {
    const res = await axiosInstance.get("don/drop-off-services");
    return res?.data;
  } catch (err) {
    console.log(err);
  }
}
