import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useState } from "react";
import { imageBaseURL } from "../../../utils/const";

function CollectionGallery(props) {
  console.log("GALLERY EDITMODE", props.editMode);
  const deleteImageHandle = () => {
    alert("deleted");
  };

  const [editImagesData, setEditImagesData] = useState([]);

  const [imgsSrc, setImgsSrc] = useState([]);
  const ImageHandler = (e) => {
    for (const file of e.target.files) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64data = reader.result;
        let base64String = reader.result.split(",")[1];

        let imageFF = `data:image/jpeg;base64,  ${base64String}`;
        console.log("imageFF", imageFF);

        props.setGalleryImg((imgs) => [...imgs, imageFF]);
      };
      reader.onerror = () => {
        console.log(reader.error);
      };
    }
  };

  const DeleteImageHandle = (val) => {
    const finalData = props.galleryImg.filter((va, index) => index !== val);

    props.setGalleryImg(finalData);
  };

  return (
    <div>
      <div>
        <div
          style={{ display: "flex", justifyContent: "flex-end", padding: 10 }}
        >
          {/* 
<div
                      className="m-2"
                      style={{ maxHeight: "200px", maxWidth: "200px" }}
                      key={val.id}
                    > */}

          {/*           <label  htmlFor="selectphoto">
            <span
              style={{
                background: "orange",
                padding: 10,
                borderRadius: 20,
                cursor: "pointer",
              }}
            >
              Add Image
            </span>
    
          </label> */}
          <input
            type="file"
            id="selectphoto"
            accept="image/*"
            multiple
            onChange={(e) => {
              ImageHandler(e);
            }}
          />
        </div>
        <Grid
          container
          md={12}
          sm={12}
          xs={12}
          lg={12}
          style={{
            justifyContent: "flex-start",
            padding: 10,
          }}
          spacing={2}
        >
          {props.galleryImg?.map((val, i) => (
            <Grid
              key={i}
              item
              lg={4}
              md={4}
              sm={6}
              xs={6}
              style={{ borderRadius: 10, padding: 0 }}
            >
              <div>
                <div
                  style={{
                    margin: 5,

                    display: "flex",
                    justifyContent: "flex-end",
                    borderRadius: 10,
                    height: 250,
                    width: 200,
                    background: "#00000040",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      justifyContent: "flex-end",

                      cursor: "pointer",
                      /*background: "white", */
                      height: 25,
                      width: 25,

                      alignItems: "center",
                      display: "flex",
                      borderRadius: 15,
                      marginTop: -5,
                      marginRight: -5,
                    }}
                    onClick={() => DeleteImageHandle(i)}
                  >
                    <i
                      class="fa fa-times-circle fa-2x"
                      style={{
                        color: "white",
                      }}
                      aria-hidden="true"
                    ></i>
                  </div>
                  <img
                    src={val}
                    style={{
                      height: 250,
                      width: 200,
                      objectFit: "contain",
                    }}
                  />
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}

export default CollectionGallery;
