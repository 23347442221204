import React, { useState } from "react";
import { useEffect } from "react";
import Modal from "react-bootstrap/Modal";

function AddFeatureDetails(props) {
  const [show, setShow] = useState(false);

  const [fieldData, setFieldData] = useState({});
  const [imageData, setImageData] = useState(null);

  const [featureData, setFeatureData] = useState([]);

  const [editData, setEditData] = useState([]);

  useEffect(() => {
    if (props.editMode === true) {
      props.setAddFeatureData(props.allEditResData.collection_details_images);
    }
  }, [props.allEditResData]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  console.log("fieldData", fieldData);

  const HandleChange = (e) => {
    setFieldData({ ...fieldData, [e.target.name]: e.target.value });
  };

  const uploadImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let base64String = reader.result.split(",")[1];

      let imageFF = `data:image/jpeg;base64,  ${base64String}`;
      setImageData(imageFF);
      console.log("reader.result", reader.result);
    };
    reader.onerror = () => {
      console.log(reader.error);
    };
  };

  const saveHandle = () => {
    let dataLoad = [];

    dataLoad.push({
      product_detail_title: fieldData.product_detail_title,
      product_detail_content: fieldData.product_detail_content,
      detail_image_url: imageData,
    });

    if (featureData) {
      const addValues = [...props.addFeatureData, ...dataLoad];
      props.setAddFeatureData(addValues);
      setShow(false);
      setImageData(null);
      /*     setFeatureData(addValues); */
    } else {
      const addValues2 = [...dataLoad];
      /*    setFeatureData(addValues2); */
      props.setAddFeatureData(addValues2);
      setShow(false);
      setImageData(null);
    }
  };

  const removeItem = (item, index) => {
    const filterData = props.addFeatureData.filter((val, i) => i !== index);

    console.log("FILTERFINAL DATA", filterData);
    props.setAddFeatureData(filterData);
    setShow(false);
  };
  console.log("DATALOAD", props.addFeatureData);
  console.log("FIELDATA", fieldData);
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span style={{ color: "white" }}>Add Feature Details</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label className="text-dark" htmlFor="">
              Title
            </label>
            <input
              type="text"
              className="form-control"
              name="product_detail_title"
              onChange={HandleChange}
            />
          </div>
          <div className="form-group">
            <label className="text-dark" htmlFor="">
              Content
            </label>
            {/* <input type="text" className="form-control" /> */}
            <textarea
              className="form-control"
              rows={6}
              name="product_detail_content"
              onChange={HandleChange}
            />
          </div>

          <div className="form-group">
            {imageData === null ? (
              <>
                <label className="text-dark" htmlFor="selectphotonew">
                  <span
                    style={{
                      background: "orange",
                      padding: 10,
                      borderRadius: 20,
                      cursor: "pointer",
                    }}
                  >
                    Feature Image
                  </span>
                  {/*             <button className="btn btn-primary btn-xs" id="selectphoto">
              Add Image
            </button> */}
                </label>
                <input
                  type="file"
                  id="selectphotonew"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={uploadImage}
                />
              </>
            ) : (
              <img
                src={imageData}
                style={{ height: 150, width: 150, objectFit: "contain" }}
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary btn-sm" onClick={handleClose}>
            Close
          </button>
          <button className="btn btn-info btn-sm" onClick={saveHandle}>
            Add
          </button>
        </Modal.Footer>
      </Modal>

      <div style={{ display: "flex", justifyContent: "flex-end", padding: 10 }}>
        {props.addFeatureData.length >= 4 ? (
          <>
            <span className="text-warning">
              We can't add more than 4 feature details
            </span>
          </>
        ) : (
          <button
            className="btn btn-primary btn-xs"
            onClick={() => handleShow()}
          >
            Add Product Detail
          </button>
        )}
      </div>
      <table className="table">
        <thead>
          <th>Image Preview</th>
          <th>Title</th>
          <th>Content</th>
          <th>Actions</th>
        </thead>
        <tbody>
          {props?.addFeatureData?.slice(0, 4).map((val, index) => (
            <tr>
              <td>
                <img
                  src={val.detail_image_url}
                  style={{ height: 100, width: 150, objectFit: "contain" }}
                />
              </td>
              <td>
                <span>{val.product_detail_title}</span>
              </td>
              <td>
                <span>{val.product_detail_content}</span>
              </td>
              <td>
                {/*         <button className="btn btn-primary btn-xs">
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </button> */}
                <button
                  className="btn btn-primary btn-xs"
                  onClick={() => removeItem(val, index)}
                >
                  <i class="fa fa-trash-o" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AddFeatureDetails;
