import { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { Input, FormGroup } from 'reactstrap';

import swal from '@sweetalert/with-react';
import { btnStyle } from '../../utils/commonStyles';

// Api
import {
  addSdiTimeSlotApi,
  editSdiTimeSlotApi,
} from '../../utils/apiService/serviceApis/sameDayInstall';

const AddTimeSlot = ({ closeModal, id, obj, getList }) => {
  const [newSlot, setNewSlot] = useState({});

  const formRef = useRef();
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setNewSlot({ ...newSlot, [name]: value });
  };

  const handleSubmit = () => {
    if (newSlot.from && newSlot.to) {
      !obj
        ? addSdiTimeSlotApi({ ...newSlot, sdi_id: id })
            .then((res) => {
              swal('Success', res.message, 'success');
              getList();
              formRef.current.reset();
            })
            .catch((err) => console.log(err))
        : editSdiTimeSlotApi(obj.id, newSlot)
            .then((res) => {
              swal('Success', res.message, 'success');
              getList();
              formRef.current.reset();
            })
            .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (obj) {
      setNewSlot({
        ...newSlot,
        from: obj.from,
        to: obj.to,
        sdi_id: id,
      });
    }
  }, [obj]);

  return (
    <>
      <form
        ref={formRef}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className='d-flex flex-column my-3'>
          <FormGroup style={{}}>
            <label className="text-dark" htmlFor='from'>Start Time</label>

            <Input
              style={{ borderRadius: '4px' }}
              name='from'
              type='datetime-local'
              onChange={(e) => {
                handleChange(e);
              }}
              defaultValue={obj ? newSlot?.from?.replace('.000Z', '') : ''}
            />
          </FormGroup>
        </div>
        <div className='d-flex flex-column my-3'>
          <FormGroup style={{}}>
            <label className="text-dark" htmlFor='to'>End Time</label>

            <Input
              style={{ borderRadius: '4px' }}
              name='to'
              type='datetime-local'
              onChange={(e) => {
                handleChange(e);
              }}
              defaultValue={obj ? newSlot?.to?.replace('.000Z', '') : ''}
            />
          </FormGroup>
        </div>
        <div className='d-flex align-items-center justify-content-end my-4'>
          <Button
            style={btnStyle}
            className='mr-4'
            onClick={() => {
              closeModal(false);
            }}
            variant='danger light'
            type='button'
          >
            cancel
          </Button>
          <Button style={btnStyle} variant='success light' type='submit'>
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};
export default AddTimeSlot;
