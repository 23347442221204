import { useState, useEffect } from 'react';
import { Col, Card, Table } from 'react-bootstrap';

import swal from '@sweetalert/with-react';

import DiscountTableComp from './DiscountTableComp';
import AddDiscount from './AddDiscount';
import ModalComp from '../../ModalComp/ModalComp';
import PaginationComp from '../PaginationComp/PaginationComp';

// Utils
import { modalOpener } from '../../utils/commonFn';

// API
import {
  getDiscountApi,
  deleteDiscountApi,
} from '../../utils/apiService/discountManagement';

//utils
import { searchInputStyle } from '../../utils/commonStyles';

const DiscountManagement = () => {
  const [openDeleteDiscountModal, setOpenDeleteDiscountModal] = useState(false);
  const [openEditDiscountModal, setOpenEditDiscountModal] = useState(false);
  const [openAddDiscountModal, setOpenAddDiscountModal] = useState(false);

  const [displayData, setDisplayData] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSearchedPage, setCurrentSearchedPage] = useState(1);

  const [isSearched, setIsSearched] = useState(false);

  const [searchedText, setSearchedText] = useState('');
  const [searchedResult, setSearchedResult] = useState('');
  const [isNotFound, setIsNotFound] = useState(false);

  const [discountList, setDiscountList] = useState('');
  const [discountId, setDiscountId] = useState('');
  const [dataToEdit, setDataToEdit] = useState('');

  const disaplayDataHandler = (data) => {
    setDisplayData(data);
  };

  const handleDelete = (id) => {
    setDiscountId(id);
    modalOpener(setOpenDeleteDiscountModal);
  };

  const provideSearchData = (data, searchedText) => {
    const filteredArr = data.filter((item) => {
      return (
        item.coupon_code
          .toString()
          .toLowerCase()
          .trim()
          .includes(searchedText) ||
        item.valid_from
          .toString()
          .replace('T', ' ')
          .replace('.000Z', '')
          .toLowerCase()
          .trim()
          .includes(searchedText) ||
        item.valid_till
          .toString()
          .replace('T', ' ')
          .replace('.000Z', '')
          .toLowerCase()
          .trim()
          .includes(searchedText) ||
        item.discount_type.toLowerCase().includes(searchedText) ||
        item.discount_value.toString().toLowerCase().includes(searchedText) ||
        item.status.toLowerCase().trim().includes(searchedText)
      );
    });
    if (filteredArr.length) {
      setSearchedResult(filteredArr);
      setIsNotFound(false);
      return;
    }
    setIsNotFound(true);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    if (value) {
      const trimmedSearchedText = value.toString().toLowerCase().trim();
      setIsSearched(true);
      setSearchedText(value);

      provideSearchData(discountList, trimmedSearchedText);
      return;
    }
    setIsSearched(false);
    setSearchedText('');
    setCurrentSearchedPage(1);
    setSearchedResult('');
    setIsNotFound(false);

    return;
  };

  const handleEdit = (obj) => {
    setDataToEdit(obj);
    modalOpener(setOpenEditDiscountModal);
  };

  // API call
  const getDiscountList = () => {
    getDiscountApi()
      .then((res) => {
        if (res.data.length) {
          res.data.map((addon, index) => {
            addon.sNo = index + 1;
          });
          setDiscountList(res.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleConfirmDelete = (id) => {
    if (id) {
      deleteDiscountApi(id)
        .then((res) => {
          swal('Success', res.message, 'success');
          getDiscountList();
          setDiscountId('');
        })
        .catch((err) => {
          console.log(err);
        });

      setOpenDeleteDiscountModal(false);
    }
  };

  useEffect(() => {
    getDiscountList();
  }, []);

  return (
    <div>
      <Col lg={12}>
        <Card>
          <Card.Header>
            <Card.Title>Discount Management</Card.Title>
            <button
              onClick={() => {
                modalOpener(setOpenAddDiscountModal);
              }}
              className='btn btn-info shadow  '
            >
              Create Compaign Code
            </button>
          </Card.Header>
          <Card.Body>
            <div class='form-group'>
              <input
                type='search'
                style={searchInputStyle}
                aria-describedby='helpId'
                placeholder='Search anything....'
                onChange={(e) => handleSearch(e)}
              />
            </div>
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <strong>Cupon Code</strong>
                  </th>
                  <th>
                    <strong>Validity</strong>
                  </th>
                  <th>
                    <strong>
                      Discount <br /> {`(% / Rs)`}
                    </strong>
                  </th>

                  <th>
                    <strong>Status</strong>
                  </th>
                  <th>
                    <strong>Actions</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {!isNotFound && displayData ? (
                  <DiscountTableComp
                    data={displayData}
                    handleEdit={(obj) => handleEdit(obj)}
                    handleDelete={(id) => handleDelete(id)}
                  />
                ) : (
                  <tr>
                    <td>
                      <h2>No data found</h2>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Card.Body>
          <Card.Footer>
            {searchedResult.length ? (
              <PaginationComp
                data={searchedResult}
                currentPage={currentSearchedPage}
                setCurrentPage={setCurrentSearchedPage}
                disaplayDataHandler={disaplayDataHandler}
                searchedText={searchedText}
              />
            ) : discountList ? (
              <PaginationComp
                data={discountList}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                disaplayDataHandler={disaplayDataHandler}
              />
            ) : null}
          </Card.Footer>
        </Card>
      </Col>

      <ModalComp
        isOpen={openDeleteDiscountModal}
        setisOpen={setOpenDeleteDiscountModal}
        handleConfirm={() => handleConfirmDelete(discountId)}
        handleDelete={() => handleConfirmDelete(discountId)}
        title='Delete Discount'
      >
        <p>Are you sure?</p>
      </ModalComp>

      <ModalComp
        isOpen={openEditDiscountModal}
        setisOpen={setOpenEditDiscountModal}
        title='Edit Compaign Code'
        hideButtons
      >
        <AddDiscount
          obj={dataToEdit}
          getList={getDiscountList}
          closeModal={setOpenEditDiscountModal}
        />
      </ModalComp>
      <ModalComp
        isOpen={openAddDiscountModal}
        setisOpen={setOpenAddDiscountModal}
        title='Add Compaign Code'
        hideButtons
      >
        <AddDiscount
          getList={getDiscountList}
          closeModal={setOpenAddDiscountModal}
        />
      </ModalComp>
    </div>
  );
};

export default DiscountManagement;
