import axios from "axios";
import React, { useState, useEffect } from "react";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import { useDispatch } from "react-redux";
import axiosInstance from "../../../services/AxiosInstance";
import { addvariantAction } from "../../../store/actions/VariantActions";
import AddCollectionVariant from "./components/AddCollectionVariant";
import AddFeatureDetails from "./components/AddFeatureDetails";
import CollectionGallery from "./components/CollectionGallery";
import CollectionInfo from "./components/CollectionInfo";
import Alert from "react-bootstrap/Alert";

import { useHistory, useLocation } from "react-router-dom";
import LoadingBalls from "./components/LoadingBalls";
import { imageBaseURL } from "../../utils/const";
import Swal from "sweetalert2";

function CollectionNew() {
  const history = useHistory();

  const { state } = useLocation();

  // console.log("LOCATION STATE", state?.state?.id);

  const [collectionInfo, setCollectionInfo] = useState();
  const [selectvariantOption, setSelectVariantOption] = useState(null);
  const [selectVariant, setSelectVariant] = useState(null);

  const [selectedValues, setSelectedValues] = useState([]);

  const [labelnames, setLabelNames] = useState(null);
  const [variantOptions, setVariantOptions] = useState(null);
  const [feature_img, setFeature_IMG] = useState(null);
  const [galleryImg, setGalleryImg] = useState([]);

  const [addFeatureData, setAddFeatureData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [allEditResData, setAllEditResData] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const collectionInfoHandleChange = (e) => {
    setCollectionInfo({ ...collectionInfo, [e.target.name]: e.target.value });
  };

  // console.log("collectionInfo", collectionInfo);
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const getSingleColleciton = () => {
    axiosInstance
      .get(`don/collectionbyid/${state.state.id}`)
      .then(async (res) => {
        // console.log("EDIT DATA", res.data.data);
        setAllEditResData(res.data.data);

        let editcollectioninfo_payload = {
          product_name: res.data?.data.product_name,
          product_price: res.data?.data.product_price,
          product_care: res.data?.data.product_care,
          detailed_features: res.data?.data.detailed_features,
        };
        setCollectionInfo(editcollectioninfo_payload);

        const getBase64FromUrl = async (url) => {
          try {
            const data = await fetch(url);
            const blob = await data.blob();
            return new Promise((resolve) => {
              const reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = () => {
                const base64data = reader.result;
                let base64String = reader.result.split(",")[1];
                // console.log(
                //   "base64String",
                //   `data:image/jpeg;base64, ${base64String}`
                // );
                let imageFF = `data:image/jpeg;base64, ${base64String}`;

                resolve(imageFF);
              };
            });
          } catch (err) {
            console.log(err);
          }
        };

        // console.log("IMAGEBASEURL", res.data?.data?.product_feature_image_url);

        let imageurl = `${imageBaseURL}${res.data?.data?.product_feature_image_url}`;

        if (res.data?.data) {
          var base64 = await getBase64FromUrl(imageurl).then((res) => {
            setFeature_IMG(res);
          });
        }

        let galleryData = res.data?.data?.collection_images;
        let slideData = [];
        for (let i = 0; i < galleryData.length; i++) {
          let galleryImgurl = `${imageBaseURL}${galleryData[i]?.image_url}`;

          slideData.push(getBase64FromUrl(galleryImgurl));

          // console.log("IMAGE-URL", galleryImgurl);
        }
        Promise.all(slideData).then((res) => {
          setGalleryImg(res);
        });

        let featureEditData = res.data?.data?.collection_details_images;
        let promises = [];
        let featureDATA = [];

        for (let i = 0; i < featureEditData.length; i++) {
          let galleryImgurl4 = `${imageBaseURL}${featureEditData[i]?.detail_image_url}`;

          promises.push(
            getBase64FromUrl(galleryImgurl4).then((vas) => {
              let payload = {
                id: featureEditData[i].id,
                product_detail_title: featureEditData[i].product_detail_title,
                product_detail_content:
                  featureEditData[i].product_detail_content,
                detail_image_url: vas,
              };

              featureDATA.push(payload);
            })
          );
        }
        Promise.all(promises).then((res) => {
          /*    setAddFeatureData(featureDetails) */

          /*  console.log("FEATURE-44", featureDATA); */

          setAddFeatureData(featureDATA);
        });

        let editVariantData = [];
        res.data?.data?.variants_options.forEach((element, index) => {
          editVariantData.push({
            parent: element.variant_name,
            value: element.id,
            label: element.variant_option,
          });
        });

        setValueData(editVariantData);
      });
  };

  // console.log("COLLECTION INFO", collectionInfo);
  useEffect(() => {
    if (state?.state?.id !== undefined) {
      getSingleColleciton();
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  }, [state?.state?.id]);

  // console.log("EDITMODE", editMode);
  useEffect(() => {
    axios.get("https://thedon.onrender.com/don/variant").then((res) => {
      let labeldata = res.data.data
        .map((va) => va.variant_name)
        .filter(onlyUnique);
      setLabelNames(labeldata);
      setVariantOptions(res.data.data);
    });
  }, []);

  const [removevalues, setRemoveValues] = useState(null);

  const onSelect = (selectedList, selectedItem) => {
    const newvalue = {
      ...selectedValues,
      parent: selectVariant.label,
      id: selectedItem.id,
      name: selectedItem.name,
    };
    if (selectedValues) {
      const addnewdata = [
        ...selectedValues,
        {
          parent: selectVariant.label,
          id: selectedItem.id,
          name: selectedItem.name,
        },
      ];

      setSelectedValues(addnewdata);
    } else {
      const addnwa = [newvalue];
      setSelectedValues(addnwa);
    }
  };

  const onRemove = (selectedList, selectedItem) => {
    if (selectedValues) {
      const newDatas = selectedValues.filter((va) => va.id !== selectedItem.id);

      setSelectedValues(newDatas);
    }

    let removearr = [];

    const newvalue = {
      ...removevalues,
      parent: selectVariant.label,
      id: selectedItem.id,
      name: selectedItem.name,
    };

    setRemoveValues(newvalue);

    removevalues.forEach((sa) => {
      removearr.push({ parent: sa.parent, id: sa.id, name: sa.name });
    });

    setRemoveValues(removearr);
  };

  const [valueData, setValueData] = useState([]);
  const [addvariantModal, setAddVariantModal] = useState(false);

  console.log("selectVariant", selectVariant, selectvariantOption);
  const saveCloseHandle = () => {
    let ksa = [];

    ksa.push({
      parent: selectVariant.label,
      value: selectvariantOption.value,
      label: selectvariantOption.label,
    });

    if (valueData) {
      const addnewdata = [...valueData, ...ksa];

      const newFiltervariants = variantOptions.filter(
        (sa) => sa.id !== selectvariantOption.value
      );
      setVariantOptions(newFiltervariants);
      setValueData(addnewdata);
      setSelectVariantOption(null);
      setSelectVariant(null);
      setAddVariantModal(false);
    } else {
      const addnewdatav = [...ksa];

      setValueData(addnewdatav);
      setSelectVariantOption(null);
      setSelectVariant(null);
      setAddVariantModal(false);
    }
  };

  const saveHandle = () => {
    let ksa = [];

    ksa.push({
      parent: selectVariant.label,
      value: selectvariantOption.value,
      label: selectvariantOption.label,
    });

    if (valueData) {
      const addnewdata = [...valueData, ...ksa];

      const newFiltervariants = variantOptions.filter(
        (sa) => sa.id !== selectvariantOption.value
      );
      setVariantOptions(newFiltervariants);
      setValueData(addnewdata);
      setSelectVariantOption(null);
    } else {
      const addnewdatav = [...ksa];

      const newFiltervariants = variantOptions.filter(
        (sa) => sa.id !== selectvariantOption.value
      );
      setVariantOptions(newFiltervariants);

      setValueData(addnewdatav);
      setSelectVariantOption(null);
    }
  };

  const removeVariantItem = (itemvalue) => {
    let daos = [...variantOptions];

    daos.push({
      variant_name: itemvalue.parent,
      variant_option: itemvalue.label,
      id: itemvalue.value,
    });

    setVariantOptions(daos);
    const newData = valueData.filter((sa) => sa.value !== itemvalue.value);
    setValueData(newData);
  };

  const FeatureImageHandler = (e) => {
    /*     e.preventDefault(); */

    const file = e.target.files[0];

    console.log("FILE", file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFeature_IMG(reader.result);
    };
    reader.onerror = () => {
      console.log(reader.error);
    };

    /*    const data = e.target.files[0];
    setFeature_IMG(data);
    let formData = new FormData();

    formData.append("new", data);


    console.log("iamge", formData.get("new"));
    for (var pair of formData.entries()) {
      console.log(JSON.stringify(pair[0] + ", " + pair[1]));
    } */
  };
  /*   console.log("FEATURE IMAGE VVAR", galleryImg);
  console.log("FEATURE IMAGE", feature_img); */

  console.log("addFeatureData", addFeatureData);

  const SubmitDetails = () => {
    console.log("collectionINFO", collectionInfo);

    if (feature_img === null) {
      return Swal.fire({
        icon: "warning",
        title: "Please fill required fields!",
      });
    }

    if (collectionInfo === undefined) {
      return Swal.fire({
        icon: "warning",
        title: "Please fill required fieldss!",
      });
    }

    if (valueData?.length === 0) {
      return Swal.fire({
        icon: "warning",
        title: "Please fill required fieldss!",
      });
    }

    if (galleryImg?.length === 0) {
      return Swal.fire({
        icon: "warning",
        title: "Please fill required fieldss!",
      });
    }

    if (addFeatureData?.length === 0) {
      return Swal.fire({
        icon: "warning",
        title: "Please fill required fieldss!",
      });
    }

    const payload = {
      ...collectionInfo,
      product_feature_image_url: feature_img,
      variantIds: valueData?.map(({ value }) => value),
      sliderImages: galleryImg,
      featureDetails: addFeatureData,
    };

    console.log("final payload", payload);
    setLoading(true);

    axiosInstance.post("don/add_collection_api", payload).then((res) => {
      console.log("response", res);

      history.push("/collections");
      setLoading(false);
    });
  };

  const UpdateDetails = () => {
    // console.log("collectionINFO", collectionInfo);

    const payload = {
      ...collectionInfo,
      product_feature_image_url: feature_img?.split(",")[1],
      variantIds: valueData?.map(({ value }) => value),
      sliderImages: galleryImg,
      featureDetails: addFeatureData,
      collection_id: state?.state?.id,
    };

    // console.log("final payload", payload);
    setLoading(true);

    axiosInstance.put("don/update_collection", payload).then((res) => {
      // console.log("response", res);

      history.push("/collections");
      setLoading(false);
    });
  };
  // console.log("collectioninfo", valueData);
  return (
    <div>
      {loading && (
        <div>
          <LoadingBalls />
        </div>
      )}
      <Card>
        <Card.Header>
          <Card.Title>Add Collection</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Tab.Container defaultActiveKey="collectioninfo">
              <Col xl={3}>
                <Nav as="ul" className="flex-column nav-pills mb-3">
                  <Nav.Item as="li" key="collectioninfo">
                    <Nav.Link eventKey="collectioninfo">
                      Collection Info
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" key="variants">
                    <Nav.Link eventKey="variants">Variants</Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" key="gallery">
                    <Nav.Link eventKey="gallery">Gallery</Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li" key="feature-details">
                    <Nav.Link eventKey="feature-details">
                      Product Details
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col xl={9}>
                <Tab.Content className="ml-2">
                  <Tab.Pane eventKey="collectioninfo" key="collectioninfo">
                    <CollectionInfo
                      collectionInfoHandleChange={collectionInfoHandleChange}
                      FeatureImageHandler={FeatureImageHandler}
                      feature_img={feature_img}
                      setFeature_IMG={(e) => setFeature_IMG(null)}
                      allEditResData={allEditResData}
                      collectionInfo={collectionInfo}
                    />
                  </Tab.Pane>

                  <Tab.Pane eventKey="variants" key="variants">
                    <AddCollectionVariant
                      selectvariantOption={selectvariantOption}
                      setSelectVariantOption={setSelectVariantOption}
                      saveHandle={saveHandle}
                      selectVariant={selectVariant}
                      setSelectVariant={setSelectVariant}
                      setVariantOptions={setVariantOptions}
                      variantOptions={variantOptions}
                      labelnames={labelnames}
                      valueData={valueData}
                      saveCloseHandle={saveCloseHandle}
                      addvariantModal={addvariantModal}
                      setAddVariantModal={setAddVariantModal}
                      removeVariantItem={removeVariantItem}
                    />
                  </Tab.Pane>

                  <Tab.Pane eventKey="gallery" key="gallery">
                    <CollectionGallery
                      setGalleryImg={setGalleryImg}
                      galleryImg={galleryImg}
                      allEditResData={allEditResData}
                      editMode={editMode}
                    />
                  </Tab.Pane>

                  <Tab.Pane eventKey="feature-details" key="feature-details">
                    <AddFeatureDetails
                      setAddFeatureData={setAddFeatureData}
                      addFeatureData={addFeatureData}
                      allEditResData={allEditResData}
                      editMode={editMode}
                    />
                  </Tab.Pane>
                </Tab.Content>{" "}
              </Col>
            </Tab.Container>
          </Row>
        </Card.Body>
        <Card.Footer style={{ display: "flex", justifyContent: "flex-end" }}>
          {editMode ? (
            <button className="btn btn-info btn-sm" onClick={UpdateDetails}>
              Update
            </button>
          ) : (
            <button className="btn btn-info btn-sm" onClick={SubmitDetails}>
              Save & Publish
            </button>
          )}
        </Card.Footer>
      </Card>
    </div>
  );
}

export default CollectionNew;
