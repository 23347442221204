import { Card, Table } from "react-bootstrap";
import { modalOpener } from "../../utils/commonFn";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";

//comp
import { UserRoleList } from "../../utils/const";
import UserRoleTable from "./UserRoleTable";
import ModalComp from "../../ModalComp/ModalComp";
import AddUser from "./AddUser";

//utils
import { searchInputStyle } from "../../utils/commonStyles";
import PaginationComp from "../PaginationComp/PaginationComp";

// api call
import {
  getAllUserRole,
  deleteRole,
} from "../../utils/apiService/rolePermission";

const UserRole = () => {
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [displayData, setDisplayData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [foundResult, setFoundResult] = useState("");
  const [name, setName] = useState("");
  const [roleList, setRoleList] = useState("");
  const [roleData, setRoleData] = useState("");
  const [roleId, setRoleId] = useState("");

  useEffect(() => {
    getRoleList();
  }, []);

  const handleConfirm = () => {
    // console.log('confirmed');
  };
  const disaplayDataHandler = (data) => {
    setDisplayData(data);
  };
  const handleEdit = (obj) => {
    // console.log(obj, 'object');
    setRoleData(obj);
    modalOpener(setOpenEditUserModal);
  };
  const handleDelete = (id) => {
    setRoleId(id);
    // console.log(id, 'delete id');
    modalOpener(setOpenDeleteUserModal);
  };
  const handleDeleteConfirm = (id) => {
    if (id) {
      deleteRole(id)
        .then((res) => {
          swal("Well Done!", "Deleted successfully", "success");
          // console.log(res, 'res');
          getRoleList();
          setOpenDeleteUserModal(false);
        })
        .catch((err) => {
          // console.log(err, 'error');
          swal("Oops!", err, "error");
          setOpenDeleteUserModal(false);
        });
    }
  };
  const filter = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      const results = roleList.filter((user) => {
        return (
          user?.id.toString().toLowerCase().startsWith(keyword.toLowerCase()) ||
          user?.name.toLowerCase().startsWith(keyword.toLowerCase())
        );
        // user?.permissions.toLowerCase().startsWith(keyword.toLowerCase()) ||
        // user?.status.toLowerCase().startsWith(keyword.toLowerCase())
      });
      setFoundResult(results);
    } else {
      setFoundResult(roleList);
    }
    setName(keyword);
  };
  const getRoleList = () => {
    getAllUserRole()
      .then((res) => {
        let data = res?.data;
        data.map((ele, i) => {
          ele.slNo = i + 1;
        });
        setRoleList(data);
      })
      .catch((err) => {
        // console.log(err, 'error');
      });
  };
  // console.log(roleList, 'rolelist');

  return (
    <>
      <div className="col-sm-12">
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <Card>
              <Card.Header>
                <Card.Title>Users List</Card.Title>
                <button
                  onClick={() => modalOpener(setOpenAddUserModal)}
                  className="btn btn-info shadow"
                >
                  Add User
                </button>
              </Card.Header>
              <Card.Body>
                <div className="form-group">
                  <input
                    type="search"
                    onChange={filter}
                    value={name}
                    style={searchInputStyle}
                    id=""
                    aria-describedby="helpId"
                    placeholder="Search anything...."
                  />
                </div>
                <Table responsive>
                  <thead>
                    <tr>
                      <th scope="col" className="text-white">
                        Sl NO
                      </th>
                      <th scope="col" className="text-white">
                        User Role
                      </th>
                      <th scope="col" className="text-white">
                        Permissions
                      </th>
                      {/*       <th scope='col' className='text-white'>
                        Status
                      </th> */}
                      <th scope="col" className="text-white">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {foundResult && foundResult.length > 0 ? (
                                            <UserRoleTable data={foundResult}
                                                handleEdit={() => modalOpener(setOpenEditUserModal)}
                                                handleDelete={() => modalOpener(setOpenDeleteUserModal)} />
                                        ) : (
                                            <h6>No results found!</h6>
                                        )} */}
                    {displayData ? (
                      <UserRoleTable
                        data={displayData}
                        handleEdit={(obj) => handleEdit(obj)}
                        handleDelete={(id) => handleDelete(id)}
                      />
                    ) : (
                      <h6>No results found!</h6>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
              <Card.Footer>
                {foundResult ? (
                  <PaginationComp
                    data={foundResult}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    disaplayDataHandler={disaplayDataHandler}
                    searchedText={name}
                  />
                ) : roleList ? (
                  <PaginationComp
                    data={roleList}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    disaplayDataHandler={disaplayDataHandler}
                    searchedText={name}
                  />
                ) : null}
              </Card.Footer>
            </Card>
          </div>
        </div>
      </div>
      <ModalComp
        isOpen={openDeleteUserModal}
        setisOpen={setOpenDeleteUserModal}
        handleConfirm={() => handleDeleteConfirm(roleId)}
        title="Delete Member"
      >
        <p className="text-center">Are you sure?</p>
      </ModalComp>
      <ModalComp
        isOpen={openAddUserModal}
        setisOpen={setOpenAddUserModal}
        title="Add Member"
        hideButtons
      >
        <AddUser getList={getRoleList} closeModal={setOpenAddUserModal} />
      </ModalComp>
      <ModalComp
        isOpen={openEditUserModal}
        setisOpen={setOpenEditUserModal}
        title="Edit Member"
        hideButtons
      >
        <AddUser
          obj={roleData}
          getList={getRoleList}
          closeModal={setOpenEditUserModal}
          openEditUserModal={openEditUserModal}
        />
      </ModalComp>
    </>
  );
};
export default UserRole;
