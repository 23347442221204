import profile from "../../images/avatar/1.png";
import image from "../../images/contacts/Untitled-1.jpg";
import col1 from "../../images/don-images/hair1.png";
import col2 from "../../images/don-images/hair2.png";
import col3 from "../../images/don-images/hair3.png";
import col4 from "../../images/don-images/hair4.png";

//baseUrl

export const imageBaseURL = "https://thedon23.s3.ap-south-1.amazonaws.com/";

// Data for Components
export const clientlist = [
  {
    id: "1",
    name: "first name",
    email: "lorem@mail.com",
    phone_no: "1234567890",
    status: "active",
    price: "R255",
    img: image,
  },
  {
    id: "2",
    name: "second name",
    email: "lorem@mail.com",
    phone_no: "1234567890",
    status: "inactive",
    price: "R258",
    img: image,
  },
  {
    id: "3",
    name: "third name",
    email: "lorem@mail.com",
    phone_no: "1234567890",
    status: "inactive",
    price: "R298",
    img: image,
  },
  {
    id: "4",
    name: "forth name",
    email: "lorem@mail.com",
    phone_no: "1234567890",
    status: "active",
    price: "R300",
    img: image,
  },
  {
    id: "5",
    name: "no name",
    email: "lorem@mail.com",
    phone_no: "1234567890",
    status: "active",
    price: "R876",
    img: image,
  },
  {
    id: "6",
    name: "yes name",
    email: "lorem@mail.com",
    phone_no: "1234567890",
    status: "active",
    price: "R666",
    img: image,
  },
  {
    id: "7",
    name: "sec name2",
    email: "lorem@mail.com",
    phone_no: "1234567890",
    status: "inactive",
    price: "R777",
    img: image,
  },
  {
    id: "8",
    name: "no name2",
    email: "lorem@mail.com",
    phone_no: "1234567890",
    status: "inactive",
    price: "R888",
    img: image,
  },
  {
    id: "9",
    name: "second name2",
    email: "lorem@mail.com",
    phone_no: "1234567890",
    status: "active",
    price: "R999",
    img: image,
  },
  {
    id: "10",
    name: "second ",
    email: "lorem@mail.com",
    phone_no: "1234567890",
    status: "active",
    price: "R100",
    img: image,
  },
  {
    id: "11",
    name: "second3 name",
    email: "lorem@mail.com",
    phone_no: "1234567890",
    status: "active",
    price: "R123",
    img: image,
  },
  {
    id: "12",
    name: "second2 name",
    email: "lorem@mail.com",
    phone_no: "1234567890",
    status: "inactive",
    price: "R258",
    img: image,
  },
  {
    id: "13",
    name: "second1 name",
    email: "lorem@mail.com",
    phone_no: "1234567890",
    status: "inactive",
    price: "R298",
    img: image,
  },
  {
    id: "14",
    name: "lorem name",
    email: "lorem@mail.com",
    phone_no: "1234567890",
    status: "active",
    price: "R300",
    img: image,
  },
  {
    id: "15",
    name: "lorem name",
    email: "lorem@mail.com",
    phone_no: "1234567890",
    status: "active",
    price: "R876",
    img: image,
  },
];

export const collectionList = [
  {
    sNo: "1",
    image: profile,
    title: "R200",
    price: "R500",
    status: "inactive",
  },
  {
    sNo: "2",
    image: profile,
    title: "R224",
    price: "R600",
    status: "active",
  },
  {
    sNo: "3",
    image: profile,
    title: "R234",
    price: "R800",
    status: "active",
  },
  {
    sNo: "4",
    image: profile,
    title: "R244",
    price: "R900",
    status: "active",
  },
  {
    sNo: "5",
    image: profile,
    title: "R294",
    price: "R1400",
    status: "active",
  },
  {
    sNo: "6",
    image: profile,
    title: "R200",
    price: "R8500",
    status: "active",
  },
  {
    sNo: "7",
    image: profile,
    title: "R224",
    price: "R1800",
    status: "inactive",
  },
  {
    sNo: "8",
    image: profile,
    title: "R234",
    price: "R9500",
    status: "active",
  },
  {
    sNo: "9",
    image: profile,
    title: "R244",
    price: "R340",
    status: "active",
  },
  {
    sNo: "10",
    image: profile,
    title: "R294",
    price: "R614",
    status: "inactive",
  },
  {
    sNo: "11",
    image: profile,
    title: "R200",
    price: "R500",
    status: "active",
  },
  {
    sNo: "12",
    image: profile,
    title: "R224",
    price: "R500",
    status: "active",
  },
  {
    sNo: "13",
    image: profile,
    title: "R234",
    price: "R500",
    status: "active",
  },
  {
    sNo: "14",
    image: profile,
    title: "R244",
    price: "R500",
    status: "active",
  },
  {
    sNo: "15",
    image: profile,
    title: "R294",
    price: "R500",
    status: "active",
  },
];

export const CollectionList = [
  {
    id: "1",
    name: "Professionally Made",
    description:
      "Loremipsum Loremipsum Loremipsum  Loremipsum Loremipsum Loremipsum  ",
    phone_no: "1234567890",
    status: "active",
    price: "R255",
    img: col1,
  },
  {
    id: "2",
    name: "Professionally Made",
    description:
      "Loremipsum Loremipsum Loremipsum  Loremipsum Loremipsum Loremipsum  ",
    phone_no: "1234567890",
    status: "inactive",
    price: "R258",
    img: col2,
  },
  {
    id: "3",
    name: "Professionally Made",
    description:
      "Loremipsum Loremipsum Loremipsum  Loremipsum Loremipsum Loremipsum  ",
    phone_no: "1234567890",
    status: "inactive",
    price: "R298",
    img: col3,
  },
  {
    id: "4",
    name: "Professionally Made",
    description:
      "Loremipsum Loremipsum Loremipsum  Loremipsum Loremipsum Loremipsum  ",
    phone_no: "1234567890",
    status: "active",
    price: "R300",
    img: col4,
  },
];

export const addonList = [
  {
    id: "1",
    sNo: "1",
    variantName: "Size",
    variantValue: "XL",
    price: "250",
    status: "active",
  },
  {
    id: "2",
    sNo: "2",
    variantName: "Size",
    variantValue: "XL",
    price: "250",
    status: "active",
  },
  {
    id: "3",
    sNo: "3",
    variantName: "Size",
    variantValue: "XL",
    price: "255",
    status: "active",
  },
  {
    id: "4",
    sNo: "4",
    variantName: "Size",
    variantValue: "XL",
    price: "260",
    status: "active",
  },
  {
    id: "5",
    sNo: "5",
    variantName: "Size",
    variantValue: "XL",
    price: "250",
    status: "active",
  },
  {
    id: "6",
    sNo: "6",
    variantName: "Size",
    variantValue: "XL",
    price: "250",
    status: "active",
  },
  {
    id: "7",
    sNo: "7",
    variantName: "Size",
    variantValue: "XL",
    price: "250",
    status: "inactive",
  },
  {
    id: "8",
    sNo: "8",
    variantName: "Size",
    variantValue: "XL",
    price: "255",
    status: "active",
  },
  {
    id: "9",
    sNo: "9",
    variantName: "Size",
    variantValue: "XL",
    price: "260",
    status: "active",
  },
  {
    id: "10",
    sNo: "10",
    variantName: "Size",
    variantValue: "XL",
    price: "250",
    status: "active",
  },
  {
    id: "11",
    sNo: "11",
    variantName: "Size",
    variantValue: "XL",
    price: "250",
    status: "inactive",
  },
  {
    id: "12",
    sNo: "12",
    variantName: "Size",
    variantValue: "XL",
    price: "250",
    status: "inactive",
  },
  {
    id: "13",
    sNo: "13",
    variantName: "Size",
    variantValue: "XL",
    price: "255",
    status: "inactive",
  },
  {
    id: "14",
    sNo: "14",
    variantName: "Size",
    variantValue: "XL",
    price: "260",
    status: "inactive",
  },
  {
    id: "15",
    sNo: "15",
    variantName: "Size",
    variantValue: "XL",
    price: "250",
    status: "inactive",
  },
];

export const discountList = [
  {
    id: "1",
    cuponCode: "CAM33BLLLB",
    validity: "13/06/2022 11:21 AM TO 24/06/2022 11:21AM",
    discount: "15%",
    status: "active",
  },
  {
    id: "2",
    cuponCode: "CAM33BLB",
    validity: "13/06/2022 11:21 AM TO 24/06/2022 11:21AM",
    discount: "25%",
    status: "active",
  },
  {
    id: "3",
    cuponCode: "CAM33BLPPB",
    validity: "13/06/2022 11:21 AM TO 24/06/2022 11:21AM",
    discount: "15%",
    status: "inactive",
  },
  {
    id: "4",
    cuponCode: "CAM33BZZZB",
    validity: "13/06/2022 11:21 AM TO 24/06/2022 11:21AM",
    discount: "R200",
    status: "active",
  },
  {
    id: "5",
    cuponCode: "CAM33BKKKB",
    validity: "13/07/2022 11:21 AM TO 24/06/2022 11:21AM",
    discount: "55%",
    status: "expire",
  },
];

export const serviceList = [
  {
    id: "1",
    service: "Same day service",
    sub: "Sew in",
    price: "R200",
    status: "active",
  },
  {
    id: "2",
    service: "Same day install",
    sub: "Sew in",
    price: "R200",
    status: "inactive",
  },

  {
    id: "3",
    service: "Drop Off service",
    sub: "Glue Frontal Install",
    price: "R150",
    status: "active",
  },
  {
    id: "4",
    service: "Drop Off service",
    sub: "Installation",
    price: "R200",
    status: "expire",
  },
  {
    id: "5",
    service: "Same day service",
    sub: "Sew in",
    price: "R200",
    status: "active",
  },
  {
    id: "6",
    service: "Same day install",
    sub: "Sew in",
    price: "R200",
    status: "inactive",
  },

  {
    id: "7",
    service: "Drop Off service",
    sub: "Glue Frontal Install",
    price: "R150",
    status: "active",
  },
  {
    id: "8",
    service: "Drop Off service",
    sub: "Installation",
    price: "R200",
    status: "expire",
  },
  {
    id: "9",
    service: "Same day service",
    sub: "Sew in",
    price: "R200",
    status: "active",
  },
  {
    id: "10",
    service: "Same day install",
    sub: "Sew in",
    price: "R200",
    status: "inactive",
  },

  {
    id: "11",
    service: "Drop Off service",
    sub: "Glue Frontal Install",
    price: "R150",
    status: "active",
  },
  {
    id: "12",
    service: "Drop Off service",
    sub: "Installation",
    price: "R200",
    status: "expire",
  },
  {
    id: "13",
    service: "Same day service",
    sub: "Sew in",
    price: "R200",
    status: "active",
  },
  {
    id: "14",
    service: "Same day install",
    sub: "Sew in",
    price: "R200",
    status: "inactive",
  },

  {
    id: "15",
    service: "Drop Off service",
    sub: "Glue Frontal Install",
    price: "R150",
    status: "active",
  },
  {
    id: "16",
    service: "Drop Off service",
    sub: "Installation",
    price: "R200",
    status: "expire",
  },
];
export const addServiceList = [
  {
    id: "1",
    service: "Wig Make",
    sub: "Glue Frontal Install",
    price: "R200",
    duration: "2 Hours",
    status: "active",
  },
  {
    id: "2",
    service: "Bleach knots",
    sub: "Glue Closure Install",
    price: "R200",
    duration: "2 Hours",
    status: "active",
  },
  {
    id: "3",
    service: "Wash",
    sub: "Glue Frontal Install",
    price: "R200",
    duration: "2 Hours",
    status: "inactive",
  },
  {
    id: "4",
    service: "Wig Make",
    sub: "Glue Closure Install",
    price: "R150",
    duration: "2 Hours",
    status: "active",
  },
  {
    id: "5",
    service: "Wig Make",
    sub: "Lace and Budles",
    price: "R200",
    duration: "2 Hours",
    status: "active",
  },
  {
    id: "6",
    service: "Bleach knots",
    sub: "Sew in",
    price: "R200",
    duration: "2 Hours",
    status: "active",
  },
  {
    id: "7",
    service: "Wash",
    sub: "Glue Frontal Install",
    price: "R200",
    duration: "2 Hours",
    status: "inactive",
  },
  {
    id: "8",
    service: "Wig Make",
    sub: "Remake Wig",
    price: "R150",
    duration: "2 Hours",
    status: "active",
  },
  {
    id: "9",
    service: "Wig Make",
    sub: "Lace and Budles",
    price: "R200",
    duration: "2 Hours",
    status: "active",
  },
  {
    id: "10",
    service: "Bleach knots",
    sub: "Sew in",
    price: "R200",
    duration: "2 Hours",
    status: "active",
  },
  {
    id: "11",
    service: "Wash",
    sub: "Glue Frontal Install",
    price: "R200",
    duration: "2 Hours",
    status: "inactive",
  },
  {
    id: "12",
    service: "Wig Make",
    sub: "Remake Wig",
    price: "R150",
    duration: "2 Hours",
    status: "active",
  },
  {
    id: "13",
    service: "Wig Make",
    sub: "Lace and Budles",
    price: "R200",
    duration: "2 Hours",
    status: "active",
  },
  {
    id: "14",
    service: "Bleach knots",
    sub: "Sew in",
    price: "R200",
    duration: "2 Hours",
    status: "active",
  },
  {
    id: "15",
    service: "Wash",
    sub: "Glue Frontal Install",
    price: "R200",
    duration: "2 Hours",
    status: "inactive",
  },
  {
    id: "16",
    service: "Wig Make",
    sub: "Remake Wig",
    price: "R150",
    status: "active",
  },
];

export const memberList = [
  {
    id: "1",
    name: "name1",
    email: "kij8862@mail.com",
    phone_no: "96485123",
    status: "active",
    role: "R255",
  },
  {
    id: "2",
    name: "name2",
    email: "ku841@mail.com",
    phone_no: "3254896",
    status: "inactive",
    role: "R258",
  },
  {
    id: "3",
    name: "name3",
    email: "25145@mail.com",
    phone_no: "64582139",
    status: "inactive",
    role: "R298",
  },
  {
    id: "4",
    name: "name4",
    email: "2158@mail.com",
    phone_no: "325148",
    status: "active",
    role: "R300",
  },
  {
    id: "5",
    name: "name5",
    email: "5556k@mail.com",
    phone_no: "6952365",
    status: "active",
    role: "R876",
  },
  {
    id: "6",
    name: "name6",
    email: "lorem25@mail.com",
    phone_no: "1234567890",
    status: "active",
    role: "R876",
  },
  {
    id: "7",
    name: "name7",
    email: "lorem@mail.com",
    phone_no: "1234567890",
    status: "active",
    role: "R876",
  },
  {
    id: "8",
    name: "name8",
    email: "lorem@mail.com",
    phone_no: "1234567890",
    status: "active",
    role: "R876",
  },
  {
    id: "9",
    name: "name9",
    email: "lorem@mail.com",
    phone_no: "474258",
    status: "active",
    role: "R876",
  },
  {
    id: "10",
    name: "name10",
    email: "lorem@mail.com",
    phone_no: "1234587",
    status: "active",
    role: "R876",
  },
  {
    id: "11",
    name: "name11",
    email: "lorem@mail.com",
    phone_no: "9648521",
    status: "active",
    role: "R876",
  },
  {
    id: "12",
    name: "name12",
    email: "lorem@mail.com",
    phone_no: "6476216",
    status: "active",
    role: "R876",
  },
  {
    id: "13",
    name: "name13",
    email: "lorem@mail.com",
    phone_no: "1234567890",
    status: "active",
    role: "R876",
  },
  {
    id: "14",
    name: "name14",
    email: "lorem@mail.com",
    phone_no: "1234567890",
    status: "active",
    role: "R876",
  },
  {
    id: "15",
    name: "name15",
    email: "lorem@mail.com",
    phone_no: "1234567890",
    status: "active",
    role: "R876",
  },
];

export const UserRoleList = [
  {
    id: "1",
    userRole: "admin",
    permissions: "orders, collections,members",
    status: "Active",
  },
  {
    id: "2",
    userRole: "admin",
    permissions: "orders, collections,members,discount, addon, services",
    status: "Active",
  },
  {
    id: "3",
    userRole: "staff",
    permissions: "orders, collections,members,discount, addon, services",
    status: "Active",
  },
  {
    id: "4",
    userRole: "staff",
    permissions: "discount, addon, services",
    status: "Active",
  },
  {
    id: "5",
    userRole: "admin",
    permissions: "orders, collections,members",
    status: "Active",
  },
  {
    id: "6",
    userRole: "admin",
    permissions: "orders, ",
    status: "Inactive",
  },
  {
    id: "7",
    userRole: "staff",
    permissions: "orders, collections,members,discount, addon, services",
    status: "Active",
  },
  {
    id: "8",
    userRole: "staff",
    permissions: "orders, collections,members,discount, addon, services",
    status: "Inactive",
  },
  {
    id: "9",
    userRole: "admin",
    permissions: "members",
    status: "Active",
  },
  {
    id: "10",
    userRole: "admin",
    permissions: "orders, collections,members,discount, addon, services",
    status: "Active",
  },
  {
    id: "11",
    userRole: "staff",
    permissions: "orders, collections,members,discount, addon, services",
    status: "Inactive",
  },
  {
    id: "12",
    userRole: "staff",
    permissions: "orders, collections,members,discount, addon, services",
    status: "Active",
  },
];

//  Data for Dropdowns

export const memberPermissions = [
  { label: "clients", value: "client", id: "1" },
  { label: "orders", value: "orders", id: "2" },
  { label: "collections", value: "collections", id: "3" },
  { label: "services", value: "services", id: "4" },
];

//data for Order
export const orderType = [
  { label: "COD", value: "cod" },
  { label: "PREPAID", value: "prepaid" },
];
export const userRole = [
  {
    label: "Staff",
    value: "staff",
  },
  {
    label: "Admin",
    value: "admin",
  },
];

export const VariantList = [
  {
    label: "Size",
    value: "size",
  },
  {
    label: "Color",
    value: "color",
  },
];

export const status = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Inactive",
    value: "inactive",
  },
  {
    label: "Expired",
    value: "expired",
  },
];

export const discountType = [
  {
    label: "Percentage",
    value: "percentage",
  },
  {
    label: "Amount",
    value: "amount",
  },
];

export const services = [
  {
    label: "Same day sevice",
    value: "same day sevice",
  },
  {
    label: "Drop off service",
    value: "drop off service",
  },
  {
    label: "Same day install",
    value: "same day install",
  },
];
export const subServices = [
  {
    label: "Installation",
    value: "Installation",
  },
  {
    label: "Sew-in",
    value: "Sew-in",
  },
  {
    label: "Style an install",
    value: "Style an install",
  },
  {
    label: "Style & fix an install",
    value: "Style & fix an install",
  },
];
export const additionalServices = [
  {
    label: "Sew-in",
    value: "Sew-in",
  },
  {
    label: "Lace and Budles",
    value: "lace and Budles",
  },
  {
    label: "Bleach knots",
    value: "bleach knots",
  },
  {
    label: "Wash",
    value: "wash",
  },
  {
    label: "Remake Wig",
    value: "remake wig",
  },
];

export const serviceStatus = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Inactive",
    value: "inactive",
  },
];
export const duration = [
  {
    label: "Hour",
    value: "Hours",
  },
  {
    label: "Minute",
    value: "Minutes",
  },
];

// export const duration = [
//   {
//     label: 'Days',
//     value: 'days',
//   },
//   {
//     label: 'Min',
//     value: 'min',
//   },
// ];

export const TimeSlotList = [
  {
    id: "1",
    timeSlot: "9:00AM To 9:00PM",
  },
  {
    id: "2",
    timeSlot: "10:00AM To 9:00PM",
  },
  {
    id: "3",
    timeSlot: "11:00AM To 9:00PM",
  },
  {
    id: "4",
    timeSlot: "11:45AM To 9:00PM",
  },
  {
    id: "5",
    timeSlot: "10:30AM To 9:00PM",
  },
];
