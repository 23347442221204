import moment from "moment";
import { useHistory } from "react-router-dom";

const BookingsData = ({ data }) => {
  const history = useHistory();

  return (
    <>
      {data
        ?.sort?.((a, b) => b.id - a.id)
        ?.map?.((order) => {
          return (
            <tr key={order?.id}>
              <td>{moment(order?.updatedAt).format("DD/MM/YYYY hh:mm A")}</td>
              <td>{order?.booking_type}</td>
              <td>{order?.id}</td>
              <td>R{order?.total_amount}</td>

              {/*    <td>{order.user?.email_id}</td> */}
              <td>
                <span
                  style={{
                    background:
                      order.order_status === "processing"
                        ? "#337e056e"
                        : order.order_status === "cancelled"
                        ? "#c628287a"
                        : order.order_status === "completed"
                        ? "#51a6f547"
                        : "grey",
                    padding: "5px",
                    borderRadius: "25px",
                    color: "white",
                    fontSize: 12,
                  }}
                >
                  {order.order_status?.toUpperCase()}
                </span>
              </td>
              {/* <td>
                            <button className="btn btn-success">Details</button>
                        </td> */}
              <td className="py-1" style={{ whiteSpace: "nowrap" }}>
                <div>
                  <button
                    //   onClick={() => handleTimeSlot(service.id)}
                    className="btn btn-primary shadow sharp mr-3"
                    onClick={() =>
                      history.push({
                        pathname: "/booking_details",
                        state: { id: order?.id, type: order?.booking_type },
                      })
                    }
                  >
                    <i class="fa fa-file-text-o" aria-hidden="true" />{" "}
                    <span>Details</span>
                  </button>
                </div>
              </td>
            </tr>
          );
        })}
    </>
  );
};
export default BookingsData;
