import React from "react";
import { Blocks } from "react-loader-spinner";
import Spinner from "react-bootstrap/Spinner";
function LoadingBalls() {
  return (
    <div
      style={{
        justifyContent: "center",

        display: "flex",
        position: "fixed",
        zIndex: 99,
        left: "60%",
        top: "40%",
      }}
    >
      <div
        style={{
          height: 100,
          width: 100,
          background: "white",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          borderRadius: 10,
          display: "flex",
        }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
      {/* <Blocks
        visible={true}
        height="80"
        width="80"
        color="#ffb716"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
      /> */}
    </div>
  );
}

export default LoadingBalls;
