import axiosInstance from "../../../services/AxiosInstance";

export async function getAllUserRole() {
  try {
    const res = await axiosInstance.get("don/roles");
    if (res?.status !== 200) {
      return "No Response from API";
    }
    return res?.data;
  } catch (error) {
    return false;
  }
}

export async function getRoleByID(id) {
  try {
    const res = await axiosInstance.get(`don/roles/${id}`);
    if (res?.status !== 200) {
      return "No Response from API";
    }
    return res?.data;
  } catch (error) {
    return false;
  }
}

export async function addRole(obj) {
  try {
    const res = await axiosInstance.post("don/roles", obj);
    if (res?.status !== 200) {
      return "No Response from API";
    }
    return res?.data;
  } catch (error) {
    return false;
  }
}

export async function updateRole(id, obj) {
  try {
    const res = await axiosInstance.put(
      `don/user-roles-permissions/${id}`,
      obj
    );
    if (res?.status !== 200) {
      return "No Response from API";
    }
    return res?.data;
  } catch (error) {
    return false;
  }
}

export async function deleteRole(id) {
  try {
    const res = await axiosInstance.delete(`don/roles/${id}`);
    if (res?.status !== 200) {
      return "No Response from API";
    }
    return res?.data;
  } catch (error) {
    return false;
  }
}
