import axiosInstance from "../../../../services/AxiosInstance";

export async function getDropoffServicesApi() {
  try {
    const res = await axiosInstance.get("don/drop-off-services");
    return res?.data;
  } catch (err) {
    console.log(err);
  }
}
export async function getDropoffSubServicesApi() {
  try {
    const res = await axiosInstance.get("don/dropoff_sub_services");
    console.log("res", res);
    return res?.data;
  } catch (err) {
    console.log(err);
  }
}
