import { useState, useEffect, useRef } from "react";

import swal from "sweetalert";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { Card, Button } from "react-bootstrap";

import { serviceStatus } from "../../utils/const";
import { btnStyle, inputStyle, customStyles } from "../../utils/commonStyles";

// API
import { addAddOnApi, editAddOnApi } from "../../utils/apiService/addOn";

const AddAdons = ({ closeModal, getList, obj }) => {
  const animatedComponents = makeAnimated();
  const [newAddOn, setNewAddOn] = useState({
    variant_option: "",
    variant_name: "",
    variant_amount: "",
    status: "",
  });

  const formRef = useRef();

  const checkValidation = () => {
    if (!newAddOn.variant_name) {
      swal("Error", "Variant name required!", "error");
    } else if (!newAddOn.variant_option) {
      swal("Error", "Variant value required!", "error");
    } else if (!newAddOn.variant_amount) {
      swal("Error", "Amount required!", "error");
    } else if (!newAddOn.status) {
      swal("Error", "Status required!", "error");
    }
  };

  const handleCancel = () => {
    closeModal(false);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setNewAddOn({ ...newAddOn, [name]: value });
  };

  console.log("newaddon", newAddOn);
  const handleChangeDrpdwn = (e, key) => {
    const value = e.value;
    setNewAddOn({ ...newAddOn, [key]: value });
  };

  const handleSubmit = () => {
    if (
      newAddOn?.variant_option &&
      newAddOn?.variant_name &&
      newAddOn?.variant_amount &&
      newAddOn?.status
    ) {
      !obj
        ? addAddOnApi(newAddOn)
            .then((res) => {
              closeModal(false);
              swal("Success", res.message, "success");
              getList();
              formRef.current.reset();
              setNewAddOn({});
            })
            .catch((err) => console.log(err))
        : editAddOnApi(obj.id, newAddOn)
            .then((res) => {
              closeModal(false);
              swal("Success", res.message, "success");
              getList();
              formRef.current.reset();
              setNewAddOn({});
            })
            .catch((err) => {
              swal("Error", err, "error");
            });
    } else {
      checkValidation();
    }
  };

  useEffect(() => {
    if (obj)
      setNewAddOn({
        ...newAddOn,
        variant_name: obj.variant_name,
        variant_option: obj.variant_option,
        variant_amount: obj.variant_amount,
        status: obj.status,
      });
  }, []);

  return (
    <Card className="  light">
      <Card.Body className=" mb-0 container-div">
        <form
          ref={formRef}
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          action=""
        >
          <div className="d-flex flex-column ">
            <label className="text-dark" htmlFor="variant_name">
              Variant Name
            </label>
            <input
              onChange={(e) => handleChange(e)}
              style={inputStyle}
              type="text"
              name="variant_name"
              value={newAddOn.variant_name}
            />
          </div>

          <div className="d-flex flex-column my-4">
            <label className="text-dark" htmlFor="variant_option">
              Variant Value
            </label>
            <input
              onChange={(e) => handleChange(e)}
              style={inputStyle}
              type="text"
              name="variant_option"
              value={newAddOn.variant_option}
            />
          </div>
          <div className="d-flex flex-column my-4">
            <label className="text-dark" htmlFor="variant_amount">
              Amount
            </label>
            <input
              onChange={(e) => handleChange(e)}
              style={inputStyle}
              type="text"
              name="variant_amount"
              value={newAddOn.variant_amount}
            />
          </div>

          <div className="d-flex flex-column ">
            <label className="text-dark">Variant Name</label>
            <Select
              styles={customStyles}
              placeholder="Status"
              options={serviceStatus}
              components={animatedComponents}
              isMulti={false}
              onChange={(e) => handleChangeDrpdwn(e, "status")}
              defaultValue={
                obj
                  ? {
                      label: obj.status,
                      value: obj.status,
                    }
                  : ""
              }
            />
          </div>

          <div className="d-flex align-items-center justify-content-end my-4">
            <Button
              style={btnStyle}
              className="mr-4"
              onClick={handleCancel}
              variant="danger light"
              type="button"
            >
              cancel
            </Button>
            <Button style={btnStyle} variant="success light" type="submit">
              Submit
            </Button>
          </div>
        </form>
      </Card.Body>
    </Card>
  );
};

export default AddAdons;
