import { useState, useEffect, useRef } from "react";
import swal from "@sweetalert/with-react";

import Select from "react-select";
import makeAnimated from "react-select/animated";

import { Card, Button } from "react-bootstrap";
import {
  inputStyle,
  btnStyle,
  customStyles,
} from "../../../utils/commonStyles";

import { duration } from "../../../utils/const";

// API
import {
  addServiceApi,
  editServiceApi,
} from "../../../utils/apiService/serviceApis/sameDayService";

const AddDropOffSds = ({ closeModal, options2, getList, obj }) => {
  const animatedComponents = makeAnimated();
  console.log(obj);
  const [isSDI, setIsSDI] = useState(false);
  const [newService, setNewService] = useState({
    service_type: "dropoff",
    service_name: "",
    service_price: "null",
    status: "",
    duration_value: "null",
    duration_type: "null",
    having_subservices: isSDI,
  });

  const formRef = useRef();

  const checkValidation = () => {
    if (!newService.service_name) {
      swal("Error", "Service  required!", "error");
    } else if (
      !newService.service_price ||
      newService.service_price === "null"
    ) {
      swal("Error", "Price required!", "error");
    } else if (
      !newService.duration_value ||
      newService.duration_value === "null"
    ) {
      swal("Error", "Duration value required!", "error");
    } else if (
      !newService.duration_type ||
      newService.duration_type === "null"
    ) {
      swal("Error", "Duration type required!", "error");
    } else if (!newService.status) {
      swal("Error", "Status required!", "error");
    }
  };

  const handleCancel = () => {
    closeModal(false);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setNewService({ ...newService, [name]: value });
  };

  const handleChangeDrpdwn = (e, key) => {
    const value = e.value;
    setNewService({ ...newService, [key]: value });
  };

  const handleSubmit = () => {
    if (
      newService?.service_name &&
      newService?.status &&
      newService.service_type
    ) {
      if (!obj) {
        addServiceApi(newService)
          .then((res) => {
            closeModal(false);
            swal("Success", res.message, "success");
            getList();
            formRef.current.reset();
            setNewService({
              service_type: "dropoff",
              service_name: "",
              service_price: null,
              status: "",
              duration_value: null,
              duration_type: null,
              having_subservices: !isSDI,
            });
          })
          .catch((err) => console.log(err));
      } else {
        if (newService.price > 0) newService.having_subservices = "true";
        editServiceApi(obj.id, newService)
          .then((res) => {
            closeModal(false);
            swal("Success", res.message, "success");
            getList();
            formRef.current.reset();
            setNewService({
              service_type: "same day  service",
              service_name: "",
              service_price: null,
              status: "",
              duration_value: null,
              duration_type: null,
              having_subservices: !isSDI,
            });
          })
          .catch((err) => console.log(err));
      }
    } else {
      checkValidation();
    }
  };

  useEffect(() => {
    if (obj) obj.having_subservices === "0" ? setIsSDI(true) : setIsSDI(false);
  }, [obj]);

  useEffect(() => {
    if (obj)
      setNewService({
        ...newService,
        service_type: obj.service_type,
        service_name: obj.service_name,
        service_price: obj.service_price === "null" ? null : obj.service_price,
        status: obj.status,
        duration_value:
          obj.duration_value === "null" ? null : obj.duration_value,
        duration_type: obj.duration_type,
        having_subservices: obj.having_subservices,
      });
  }, []);

  return (
    <>
      <Card className="light">
        <Card.Body className=" mb-0 container-div">
          <form
            ref={formRef}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            action=""
          >
            <div className="d-flex flex-column my-3">
              <label className="text-dark" htmlFor="service_name">
                Service
              </label>
              <input
                onChange={(e) => handleChange(e)}
                style={inputStyle}
                type="text"
                name="service_name"
                value={newService.service_name}
              />
            </div>
            {/* <div className='d-flex  my-3'> */}
            {/* {obj ? (
                <input
                  style={{ marginLeft: '0', marginBottom: '0' }}
                  className='form-check-input'
                  type='checkbox'
                  id='customCheckBox1'
                  name='having_subservices'
                  onChange={() => {
                    setIsSDI(!isSDI);
                  }}
                  checked={isSDI}
                />
              ) : (
                <input
                  style={{ marginLeft: '0', marginBottom: '0' }}
                  className='form-check-input'
                  type='checkbox'
                  id='customCheckBox1'
                  name='having_subservices'
                  onChange={() => {
                    setIsSDI(!isSDI);
                  }}
                />
              )}

              <label
                style={{ marginLeft: '20px' }}
                htmlFor='having_subservices'
                className="text-dark"
              >
                Having same day install services
              </label> */}
            {/* </div> */}
            {/* {!isSDI && ( */}
            <div>
              {/*            <div className="d-flex flex-column ">
                <label className="text-dark" htmlFor="price">Price</label>
                <input
                  onChange={(e) => handleChange(e)}
                  style={inputStyle}
                  type="text"
                  name="service_price"
                  value={
                    newService.service_price === "null"
                      ? ""
                      : newService.service_price
                  }
                />
              </div> */}

              {/*       <div className='d-flex flex-column my-3'>
                <label className="text-dark" htmlFor='duration'>Duration</label>

                <div className='d-flex  my-3'>
                  <input
                    style={{
                      ...inputStyle,
                      maxWidth: '49%',
                      marginRight: '2%',
                    }}
                    type='text'
                    name='duration_value'
                    onChange={(e) => handleChange(e)}
                    value={
                      newService.duration_value === 'null'
                        ? ''
                        : newService.duration_value
                    }
                  />
                  <Select
                    styles={customStyles}
                    placeholder=''
                    options={duration}
                    components={animatedComponents}
                    isMulti={false}
                    onChange={(e) => handleChangeDrpdwn(e, 'duration_type')}
                    defaultValue={
                      obj
                        ? {
                            label:
                              obj.duration_type === 'null'
                                ? ''
                                : obj.duration_type === 'm'
                                ? 'Minute'
                                : 'Hour',
                            value:
                              obj.duration_type === 'null'
                                ? ''
                                : obj.duration_type === 'm'
                                ? 'Minute'
                                : 'Hoor',
                          }
                        : ''
                    }
                  />
                </div>
              </div> */}
            </div>

            <div className="d-flex flex-column my-3">
              <label className="text-dark">Status</label>
              <Select
                styles={customStyles}
                placeholder="Select status"
                options={options2}
                components={animatedComponents}
                isMulti={false}
                onChange={(e) => handleChangeDrpdwn(e, "status")}
                defaultValue={
                  obj
                    ? {
                        label: obj.status,
                        value: obj.status,
                      }
                    : ""
                }
              />
            </div>
            <div className="d-flex align-items-center justify-content-end my-4">
              <Button
                style={btnStyle}
                className="mr-4"
                onClick={handleCancel}
                variant="danger light"
                type="button"
              >
                cancel
              </Button>
              <Button style={btnStyle} variant="success light" type="submit">
                Submit
              </Button>
            </div>
          </form>
        </Card.Body>
      </Card>
    </>
  );
};

export default AddDropOffSds;
