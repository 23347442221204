import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Button } from "react-bootstrap";
// import { memberPermissions } from '../../utils/const';
import { btnStyle, customStyles } from "../../utils/commonStyles";
import { useRef, useState, useEffect } from "react";
import swal from "sweetalert";
import MultiSelect from "react-multiselect-dropdown-bootstrap";
import { Form } from "react-bootstrap";

//api call
import { addRole, updateRole } from "../../utils/apiService/rolePermission";
import axiosInstance from "../../../services/AxiosInstance";

const AddUser = ({ closeModal, getList, obj, openEditUserModal }) => {
  const formRef = useRef();
  const animatedComponents = makeAnimated();
  const [roleName, setRoleName] = useState(obj?.name || null);
  const [roleValue, setRoleValue] = useState("");
  const [roleSelected, setRoleSelected] = useState(null);

  const [rolePermissions, setRolePermissions] = useState(null);
  const [addUserRole, setAddUserRole] = useState({
    role_name: "",
    route_data: "",
  });

  console.log("obj", obj);
  const memberPermissions = [
    { label: "clients", value: "client" },
    { label: "orders", value: "orders" },
    { label: "collections", value: "collections" },
    { label: "members", value: "members" },
  ];

  // From Api
  const [addRoleDataRes, setAddRoleDataRes] = useState({
    success: "",
    error: "",
  });

  const getPermissoins = () => {
    let AllPermissions = [];
    let finalVA = obj.user_roles_permissions.filter((sa) => {
      let temp = memberPermissions.map((sv) => sv.label === sa.route_name);
      return temp;
    });

    for (let i = 0; i < finalVA.length; i++) {
      let payload = {
        label: finalVA[i].route_name,
        value: finalVA[i].route_value,
      };

      AllPermissions.push(payload);
    }

    console.log("SA", AllPermissions);

    setRoleSelected(AllPermissions);
  };

  useEffect(() => {
    if (openEditUserModal) {
      getPermissoins();
    }
  }, [openEditUserModal]);

  const handleCancel = () => {
    closeModal(false);
  };
  const handleChange = (e) => {
    setRoleName(e.target.value);
    /*     const name = e.target.name;
    const value = e.target.value;

    


    setAddUserRole({ ...addUserRole, [name]: value }); */
  };
  const handleelect = (e) => {
    const value = e;
    setAddUserRole({ ...addUserRole, route_data: value });
    console.log(value, "values");
  };

  console.log("ROLE NAME", roleName);
  const handleOnChange = (e) => {
    console.log("calling handle select", e);

    setRoleSelected(e);

    // const value = e;
    // console.log(value, "value");
    // let route_data = [];
    // let route_name;
    // let route_value;
    // let id;
    // e?.forEach((e) => {
    //   route_data.push({
    //     route_name: e?.label,
    //     route_value: e?.value,
    //     id: e?.id,
    //   });
    //   setAddUserRole({ ...addUserRole, route_data: route_data });
    // });
  };
  // console.log(addUserRole, "user role add");
  const handleSubmit = () => {
    let rolesList = [];
    for (let i = 0; i < roleSelected.length; i++) {
      let payload = {
        route_name: roleSelected[i].label,
        route_value: roleSelected[i].value,
      };
      rolesList.push(payload);
    }
    let finalPayload = {
      role_name: roleName,
      route_data: rolesList,
    };

    if (openEditUserModal) {
      axiosInstance
        .put(`don/user-roles-permissions/${obj.id}`, finalPayload)
        .then((res) => {
          console.log("RES", res);
          swal("Well Done!", "Role Updated successfully", "success");
          closeModal(false);
          getList();
        });
    } else {
      axiosInstance
        .post(`don/user-roles-permissions`, finalPayload)
        .then((res) => {
          console.log("RES", res);
          swal("Well Done!", "Role added successfully", "success");
          closeModal(false);
          getList();
        });
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <form
              ref={formRef}
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              action=""
            >
              <div className="form-group">
                <label className="text-dark" htmlFor="role">Role Name</label>
                <input
                  type="text"
                  name="role_name"
                  id="role"
                  className="form-control"
                  placeholder="Role Name"
                  onChange={handleChange}
                  value={roleName}
                />
              </div>
              <div className="d-flex flex-column my-3">
                <label className="text-dark">Permissions</label>
                {/* {obj ? (
                  <Select
                    styles={customStyles}
                    className='select'
                    placeholder='Select Permissions'
                    options={memberPermissions}
                    components={animatedComponents}
                    isMulti
                    onChange={handleSelect}
                    value={roleSelected}
                  />
                ) : (
                  <Select
                    styles={customStyles}
                    className='select'
                    placeholder='Select Permissions'
                    options={memberPermissions}
                    components={animatedComponents}
                    isMulti
                    onChange={handleSelect}
                  />
                )} */}
                {memberPermissions ? (
                  <>
                    <Select
                      styles={customStyles}
                      placeholder="Select Role"
                      options={memberPermissions}
                      components={animatedComponents}
                      isMulti={true}
                      onChange={handleOnChange}
                      // onBlur={handleBlur}
                      value={roleSelected}
                    />
                    {/* 
                  <MultiSelect
                    onChange={handleOnChange}
                    options={memberPermissions}
                    placeholder="Select Permissions"
               
                  /> */}
                  </>
                ) : null}
                {/* <select multiple={true} onChange={handleOnChange} id="months">
                  <option value="1">January</option>
                  <option value="12">December</option>
                </select> */}
                {/* <div class="form-group">
                  <label for="">Permissions</label>
                  <select class="custom-select" multiple name="" id="">
                    <option selected>Select one</option>
                    <option value="">one</option>
                    <option value="">two</option>
                    <option value="">three</option>
                  </select>
                </div> */}
                {/* <Form.Control
                  required
                  as="select"
                  type="select"
                  id="instructors"
                  // value={instructors}
                  onChange={handleChange}
                  multiple
                >
                  <option value key="blankChoice">
                    inst
                  </option>
                  <option value key="blankChoice">
                    pi
                  </option>
                  <option value key="blankChoice">
                    pi
                  </option>
                  {items
                    ? items.map((item, index) => {
                        return <option value={item.id}>test{index}</option>;
                      })
                    : null}
                </Form.Control> */}
              </div>
              <div className="row">
                <div className="col-sm-2"></div>
                <div className="col-sm-8 mt-3">
                  <div className="container">
                    <div className="row text-center">
                      <div className="col-sm-6">
                        <Button
                          style={btnStyle}
                          className="mr-4"
                          onClick={handleCancel}
                          variant="danger light"
                          type="button"
                        >
                          cancel
                        </Button>
                      </div>

                      <div className="col-sm-6">
                        <Button
                          style={btnStyle}
                          variant="success light"
                          type="submit"
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-2"></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddUser;
