import axios from 'axios';
export async function getOrderCollectionVariantApi() {
  try {
    const res = await axios.get(
      'https://thedon.onrender.com/don/order-collection-variant'
    );
    return res?.data;
  } catch (err) {
    console.log(err);
  }
}
