const CardComp = ({ hdng, obj }) => {
  return (
    <div className=''>
      <h3>{hdng}</h3>
      {obj
        ? Object.keys(obj).map((key) => {
            return <p>{`${key}: ${obj[key]}`}</p>;
          })
        : 'No data'}
    </div>
  );
};

export default CardComp;
