import { useEffect } from "react";

import Select from "react-select";
// Comp
import CardComp from "./CardComp";

// API
import { getOrderCollectionVariantApi } from "../../../utils/apiService/order/orderDetails";
const OrderDetails = () => {
  useEffect(() => {
    getOrderCollectionVariantApi();
  }, []);

  return (
    <div>
      <div>
        <CardComp hdng="Customer Details" />
        <CardComp hdng="Order Summary" />
      </div>
      <div></div>
      <div>
        <CardComp hdng="Delivery Options" />
        <CardComp hdng="Order History" />
      </div>
      <div>
        <Select name="" id="" />
        <button>Update</button>
      </div>
    </div>
  );
};

export default OrderDetails;
