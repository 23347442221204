import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import makeAnimated from "react-select/animated";
import Select from "react-select";

import { inputStyle, customStyles } from "../../../utils/commonStyles";

export default function AddCollectionVariant(props) {
  const animatedComponents = makeAnimated();
  const handleClose = () => props.setAddVariantModal(false);
  const handleShow = () => props.setAddVariantModal(true);

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
  let UniqueDatas = props.valueData.map((va) => va.parent).filter(onlyUnique);

  console.log("valueData", props.valueData);
  return (
    <div>
      <div>
        <Modal show={props.addvariantModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add variant options</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Select
                styles={customStyles}
                className="select"
                placeholder="Select Variant"
                options={props.labelnames?.map((va, i) => {
                  return { value: i, label: va };
                })}
                components={animatedComponents}
                value={props.selectVariant}
                onChange={(e) => {
                  props.setSelectVariant(e);
                  props.setSelectVariantOption(null);
                }}
              />
            </div>

            <div>
              <Select
                styles={customStyles}
                className="select"
                placeholder="Select Values"
                options={props.variantOptions
                  ?.filter(
                    (fil) => fil?.variant_name === props.selectVariant?.label
                  )
                  ?.map((va, i) => {
                    return {
                      value: va.id,
                      label: `${va.variant_option} - R${va.variant_amount}`,
                    };
                  })}
                components={animatedComponents}
                value={props.selectvariantOption}
                onChange={props.setSelectVariantOption}
              />
            </div>
          </Modal.Body>
          <Modal.Footer
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <button className="btn btn-primary btn-sm">Cancel</button>

            <div>
              <button
                onClick={props.saveCloseHandle}
                className="btn btn-info btn-sm mx-3"
              >
                Save
              </button>
              <button
                onClick={props.saveHandle}
                className="btn btn-warning btn-sm text-white"
              >
                Save & Next
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>

      <div style={{ display: "flex", justifyContent: "flex-end", padding: 10 }}>
        <button onClick={handleShow} className="btn btn-primary btn-xs">
          Add Variant
        </button>
      </div>
      <div>
        <table className="table">
          <thead>
            <th>Variant Name</th>
            <th>Variant Options</th>
            {/*         <th>Actions</th> */}
          </thead>
          <tbody>
            {props.valueData.length === 0 && (
              <tr>
                <td
                  colSpan={2}
                  style={{ textAlign: "center", color: "orange" }}
                >
                  No Variants Selected, Please Add Variants.{" "}
                  <button
                    className="btn btn-xs btn-primary"
                    onClick={handleShow}
                  >
                    Add
                  </button>
                </td>
              </tr>
            )}
            {UniqueDatas.map((va) => {
              return (
                <tr>
                  <td>{va}</td>
                  <td style={{ display: "flex", flexWrap: "wrap" }}>
                    {props.valueData
                      .filter((se) => se.parent === va)
                      .map((sa) => (
                        <div>
                          <div
                            style={{
                              margin: 5,
                              width: 70,
                              display: "flex",
                              background: "white",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 10,
                            }}
                          >
                            <span style={{ color: "black" }}>{sa.label}</span>
                            <i
                              onClick={() => props.removeVariantItem(sa)}
                              class="fa fa-times-circle "
                              aria-hidden="true"
                              style={{
                                color: "red",
                                marginLeft: 10,
                                cursor: "pointer",
                              }}
                            ></i>
                          </div>
                        </div>
                      ))}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
