const SameDayInstallTable = ({
  data,
  handleEdit,
  handleDelete,
  handleTimeSlot,
}) => {
  return (
    <>
      {data.map((subService) => {
        return (
          <tr key={subService.id}>
            <td className="py-3">
              {/*  <>{`${subService.sNo}. Same  day install`}</> */}
              {subService.service.service_name}
            </td>
            <td className="py-3">
              <>{subService.sub_service_name}</>
            </td>
            <td className="py-3">{subService.price}</td>
            <td className="py-3">{`${subService.duration_value}${subService.duration_type}`}</td>
            <td className="py-3">{subService.status}</td>

            <td className="py-3">
              <div className="d-flex">
                {/*             <button
                  onClick={() => handleTimeSlot(subService.id)}
                  className="btn btn-primary shadow btn-xs sharp mr-3"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-clock"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                  </svg>
                </button> */}
                <button
                  onClick={() => handleEdit(subService)}
                  className="btn btn-primary shadow btn-xs sharp mr-3"
                >
                  <i className="fa fa-pencil"></i>
                </button>
                <button
                  onClick={() => {
                    handleDelete(subService.id);
                  }}
                  className="btn btn-danger shadow btn-xs sharp"
                >
                  <i className="fa fa-trash"></i>
                </button>
              </div>
            </td>
          </tr>
        );
      })}
    </>
  );
};

export default SameDayInstallTable;
