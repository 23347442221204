import { Input, FormGroup } from 'reactstrap';

const DateTimeComp = ({ name, label, ...rest }) => {
  return (
    <>
      <FormGroup style={{}}>
        <label className="text-dark" htmlFor={name}>{label}</label>

        <Input
          style={{ borderRadius: '4px' }}
          name={name}
          placeholder={label}
          type='datetime-local'
          {...rest}
        />
      </FormGroup>
    </>
  );
};

export default DateTimeComp;
