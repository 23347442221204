import moment from "moment";

const DiscountTableComp = ({ data, handleEdit, handleDelete }) => {
  return (
    <>
      {data.map((discount) => {
        return (
          <tr key={discount.id}>
            <td className="py-3">
              {/*    <>{`${discount.sNo}.  ${discount.coupon_code}`}</> */}
              {discount.coupon_code}
            </td>
            <td className="py-3">
              {moment(discount.valid_from).format("DD/MM/YYYY hh:mm A")} -To-{" "}
              {moment(discount.valid_till).format("DD/MM/YYYY hh:mm A")}
            </td>
            <td className="py-3">{discount.discount_value}</td>
            <td className="py-3">{discount.status}</td>

            <td className="py-3">
              <div className="d-flex">
                <button
                  onClick={() => handleEdit(discount)}
                  className="btn btn-primary shadow btn-xs sharp mr-3"
                >
                  <i className="fa fa-pencil"></i>
                </button>
                <button
                  onClick={() => {
                    handleDelete(discount.id);
                  }}
                  className="btn btn-danger shadow btn-xs sharp"
                >
                  <i className="fa fa-trash"></i>
                </button>
              </div>
            </td>
          </tr>
        );
      })}
    </>
  );
};

export default DiscountTableComp;
