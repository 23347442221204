import { useState, useEffect } from "react";
import usePagination from "../../utils/customHooks";

import "./paginationcomp.css";

// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

// import { memberList } from '../../utils/const'

import { customStyles } from "../../utils/commonStyles";
const PaginationComp = ({
  data,
  disaplayDataHandler,
  currentPage,
  setCurrentPage,
  searchedText,
}) => {
  const [itemsPerPage, setItemsPerPage] = useState(5);

  console.log("ITEMSPAGE", itemsPerPage);
  const pagination = usePagination(
    data,
    currentPage,
    setCurrentPage,
    itemsPerPage
  );

  const handleChange = (e) => {
    setItemsPerPage(+e.target.value);
  };

  const setData = () => {
    const data = pagination.currentData();
    disaplayDataHandler(data);
  };

  useEffect(() => {
    if (currentPage !== 1) setCurrentPage(1);
    setData();
    return;
  }, [itemsPerPage]);

  useEffect(() => {
    if (currentPage) setData();
  }, [currentPage, searchedText, data]);

  return (
    <div className="pagination-container">
      <div className="pagination-select-container">
        <label className="paginatiion-label" htmlFor="item_no">
          Rows per page:
        </label>
        <select
          styles={customStyles}
          onChange={(e) => handleChange(e)}
          className="pagination-select"
          name="item_no"
          id=""
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="15">15</option>
        </select>
      </div>
      <div className="pagination-btn-container">
        <button
          className="pagination-btn"
          type="button"
          onClick={() => pagination.first()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="black"
            className="bi bi-chevron-bar-left"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M11.854 3.646a.5.5 0 0 1 0 .708L8.207 8l3.647 3.646a.5.5 0 0 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 0 1 .708 0zM4.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 1 0v-13a.5.5 0 0 0-.5-.5z"
            />
          </svg>
        </button>
        <button
          className="pagination-btn"
          type="button"
          onClick={() => pagination.prev()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="black"
            className="bi bi-chevron-left"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
        </button>
        <button
          className="pagination-btn"
          type="button"
          onClick={() => pagination.next()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="black"
            className="bi bi-chevron-right"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
            />
          </svg>
        </button>
        <button
          className="pagination-btn"
          type="button"
          onClick={() => pagination.last()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="black"
            className="bi bi-chevron-bar-right"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M4.146 3.646a.5.5 0 0 0 0 .708L7.793 8l-3.647 3.646a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708 0zM11.5 1a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-1 0v-13a.5.5 0 0 1 .5-.5z"
            />
          </svg>
        </button>
      </div>
      <div className="pagination-page-info">
        <span className="p-1 ml-auto display-block">
          {`${(currentPage - 1) * itemsPerPage + 1}-${
            (currentPage - 1) * itemsPerPage + pagination.currentData().length
          }/${data.length}`}
        </span>
      </div>
    </div>
  );
};

export default PaginationComp;
