import React, { useContext } from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

import { ThemeContext } from "../context/ThemeContext";
/// Dashboard
import Orders from "./components/Orders/Orders";
import Clients from "./components/Clients/Clients";
import Collections from "./components/Collections/Collections";
import AddCollection from "./components/AddCollections/AddCollections";
import Members from "./components/Members/Members";
import Addons from "./components/Addons/Addons";
import DiscountManagement from "./components/DiscountManagement/DiscountManagement";
import UserRole from "./components/UserRole/UserRole";
import AddUser from "./components/UserRole/AddUser";
import Services from "./components/Services/Services";
import DropOffService from "./components/Services/DropOffService/DropOffService";
import OrderDetails from "./components/Orders/OrderDetails/OrderDetails";
import OrderDetailScreen from "./components/Orders/components/OrderDetailScreen";
import CollectionNew from "./pages/Collections/CollectionNew";
import Bookings from "./components/Bookings/Bookings";
import BookingDetailsPage from "./components/Bookings/BookingDetailsPage";
const routes = [
  /// Dashboard

  { url: "", component: Clients },
  { url: "clients", component: Clients },
  { url: "orders", component: Orders },
  { url: "order-details", component: OrderDetails },
  { url: "collections", component: Collections },
  { url: "add-collection", component: AddCollection },
  { url: "edit-collection", component: AddCollection },
  { url: "members", component: Members },
  { url: "addons", component: Addons },
  { url: "discount-management", component: DiscountManagement },
  { url: "user-role", component: UserRole },
  { url: "add-user", component: AddUser },
  { url: "same-day", component: Services },
  { url: "drop-off", component: DropOffService },
  { url: "orderdetailsscreen", component: OrderDetailScreen },
  { url: "all-collections", component: CollectionNew },
  { url: "all-bookings", component: Bookings },
  { url: "booking_details", component: BookingDetailsPage },
];

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      {/* <Setting /> */}
    </>
  );
};

export default Markup;
