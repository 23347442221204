import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Card, Button } from "react-bootstrap";
import swal from "sweetalert";

//utils
import { userRole } from "../../utils/const";
import { btnStyle, inputStyle, customStyles } from "../../utils/commonStyles";
import { useState, useEffect, useRef } from "react";

//api call
import { addUser, updateUser } from "../../utils/apiService/commonUser";
import { getAllUserRole } from "../../utils/apiService/rolePermission";

const initialValues = {
  fullname: "",
  email_id: "",
};
const AddMember = ({ closeModal, obj, getlist, editModal }) => {
  const memberPermissions = [
    { label: "clients", value: "client", id: "1" },
    { label: "orders", value: "orders", id: "2" },
    { label: "collections", value: "collections", id: "3" },
    { label: "services", value: "services", id: "4" },
  ];

  const [selectedPermission, setSelectedPermission] = useState("");
  const [userRollData, setUserRollData] = useState("");
  const [roleOptions, setRoleOptions] = useState("");

  console.log("editModal", editModal);

  const formRef = useRef();
  const animatedComponents = makeAnimated();
  const [addMember, setAddMember] = useState({
    firstName: "",
    lastName: "",
    email_id: "",
    user_type: "staff",
    status: "active",
  });

  // From Api
  const [addMemberDataRes, setAddMemberDataRes] = useState({
    success: "",
    error: "",
  });

  const handleCancel = () => {
    closeModal(false);
  };
  const handleAddMember = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setAddMember({ ...addMember, [name]: value });
  };
  const handleSelect = (e) => {
    const value = e.value;
    setAddMember({ ...addMember, user_role: value });
    if (userRollData.length) {
      const filteredData = userRollData.filter((item) => {
        return item.name === e.value;
      });

      console.log("userRollData", filteredData[0]);

      setSelectedPermission(filteredData[0]);
    }
  };

  // const handleSelectPermission = (e) => {
  //   setSelectedPermission(e);
  // };

  // console.log(addMember, 'add value');
  // console.log(selectedPermission, 'sp');

  const getUserRoleList = () => {
    getAllUserRole()
      .then((res) => {
        if (res.data.length) {
          setUserRollData(res.data);
          let arr = [];

          res?.data?.map((item) => {
            const data = {
              label: item.name,
              value: item.name,
            };
            arr.push(data);
          });
          setRoleOptions(arr);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSubmit = (values) => {
    console.log("values", addMember);
    if (
      addMember?.firstName &&
      addMember?.lastName &&
      addMember?.email_id &&
      addMember?.password &&
      addMember?.user_role
    )
      !obj
        ? addUser(addMember)
            .then((res) => {
              setAddMemberDataRes({
                ...addMemberDataRes,
                success: res.message,
                error: "",
              });
              swal("Well Done!", "Data submitted successfully", "success");
              closeModal(false);
              getlist();
            })
            .catch((err) => {
              swal(err, "Please check the fields", "error");
              closeModal(false);
            })
        : updateUser(obj.id, addMember)
            .then((res) => {
              setAddMemberDataRes({
                ...addMemberDataRes,
                success: res.message,
                error: "",
              });
              swal("Well Done!", "Data submitted successfully", "success");
              closeModal(false);
              getlist();
            })
            .catch((err) => {
              swal(err, "Please check the fields", "error");
              closeModal(false);
            });
  };
  useEffect(() => {
    if (addMemberDataRes.success) {
      swal("Good Job!", "Data submitted successfully", "success");
      setAddMemberDataRes({});
    }
  }, [addMemberDataRes]);
  useEffect(() => {
    if (obj) {
      setAddMember({
        ...addMember,
        firstName: obj.firstName,
        lastName: obj.lastName,
        email_id: obj.email_id,
        password: obj.password,
        user_type: "staff",
        status: obj.status,
      });
    }

    getUserRoleList();
  }, []);

  return (
    <>
      <Card className="light">
        <Card.Body className=" mb-0 container-div">
          <form
            ref={formRef}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            action=""
          >
            <div className="form-group">
              <label className="text-dark" htmlFor="email">First Name</label>
              <input
                style={inputStyle}
                type="text"
                name="firstName"
                id="email"
                className="form-control"
                defaultValue={addMember.firstName}
                onChange={handleAddMember}
              />
            </div>
            <div className="form-group">
              <label className="text-dark" htmlFor="email">Last Name</label>
              <input
                style={inputStyle}
                type="text"
                name="lastName"
                id="email"
                className="form-control"
                defaultValue={addMember.lastName}
                onChange={handleAddMember}
              />
            </div>
            <div className="form-group">
              <label className="text-dark" htmlFor="email">Email_Id</label>
              <input
                style={inputStyle}
                type="email"
                name="email_id"
                id="email"
                className="form-control"
                defaultValue={addMember.email_id}
                onChange={handleAddMember}
              />
            </div>
            {/* <div className='d-flex flex-column my-3'>
              <label className="text-dark" htmlFor='email'>Email Id</label>
              <input
                style={inputStyle}
                onChange={handleAddMember}
                type='email'
                name='email_id'
                // onBlur={handleBlur}
                value={addMember.email_id}
              />
            </div> */}
            <div className="d-flex flex-column my-3">
              <label className="text-dark" htmlFor="name">Password</label>
              <input
                style={inputStyle}
                onChange={handleAddMember}
                type="password"
                name="password"
                // onBlur={handleBlur}
                value={addMember.password}
              />
            </div>
            {/* <div className="d-flex flex-column my-3">
              <label className="text-dark" htmlFor="name">Phone No</label>
              <input
                style={inputStyle}
                onChange={handleAddMember}
                type="tel"
                name="phone"
                // onBlur={handleBlur}
                value={addMember.phone}
              />
            </div> */}

            <div className="d-flex flex-column my-3">
              <label className="text-dark">User Role</label>
              {roleOptions.length ? (
                <Select
                  styles={customStyles}
                  placeholder="Select Role"
                  options={roleOptions}
                  components={animatedComponents}
                  isMulti={false}
                  onChange={handleSelect}
                  // onBlur={handleBlur}
                  // value={addMember.user_role}
                />
              ) : null}
            </div>
            <div className="d-flex flex-column my-3">
              <p>
                <span className="text-dark"> PERMISSIONS :</span>{" "}
                {selectedPermission &&
                  selectedPermission.user_roles_permissions.map((item) => (
                    <span
                      style={{
                        padding: 5,

                        marginLeft: 5,
                        marginRight: 5,
                        border: "1px solid grey",
                        borderRadius: 10,
                        color: "white",
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}
                    >
                      {item?.route_name}
                    </span>
                  ))}
              </p>
            </div>

            <div className="d-flex align-items-center justify-content-end my-4">
              <Button
                style={btnStyle}
                className="mr-4"
                onClick={handleCancel}
                variant="danger light"
                type="button"
              >
                cancel
              </Button>
              <Button style={btnStyle} variant="success light" type="submit">
                Submit
              </Button>
            </div>
          </form>
        </Card.Body>
      </Card>
    </>
  );
};

export default AddMember;
