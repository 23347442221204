import React, { useState, useEffect } from "react";

import { Box, Card, Grid } from "@material-ui/core";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import axiosInstance from "../../../../services/AxiosInstance";
import Select from "react-select";
import { customStyles } from "../../../utils/commonStyles";
import swal from "sweetalert";

function OrderDetailScreen(route) {
  const { state } = useLocation();

  const [orderDetails, setOrderDetails] = useState({
    orderinfo: null,
    order_items: null,
    billing_address: null,
  });
  const [loading, setLoading] = useState(false);

  console.log("ORDERID", state.id);

  console.log("loading", loading);

  const getCollectionDetails = () => {
    setLoading(true);
    axiosInstance.get(`don/getorderbyid/${state.id}`).then((res) => {
      console.log("DATA", res.data);
      setOrderDetails(res.data);

      if (res.status === 200) {
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    getCollectionDetails();

    return () => {
      getCollectionDetails();
    };
  }, []);

  const [orderStatus, setOrderStatus] = useState(null);
  const options = [
    { value: "purchased", label: "PURCHASED" },
    { value: "dispatched", label: "DISPATCHED" },
    { value: "out for delivery", label: "OUT FOR DELIVERY" },
    { value: "DELIVERY", label: "DELIVERY" },
  ];

  const onUpdateChange = (e) => {
    console.log("EE", e);
    setOrderStatus(e);
  };

  const onUpdateSubmitHandle = () => {
    console.log("SUBMITTED");

    let payload = {
      track_name: orderStatus.value,
      track_datetime: new Date(),
      status: "done",
    };

    axiosInstance
      .put(`don/update_order_status/${state.id}`, payload)
      .then((res) => {
        console.log("RES", res);

        if (res.data.hasError !== true) {
          swal("Order Status Updated!", "", "success");
        }
      });
  };

  return (
    <>
      {loading === true ? (
        <div>
          <span>Loading</span>
        </div>
      ) : (
        <div className="col-md-12">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ paddingBottom: 10 }}>
              <div
                styles={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                }}
              >
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: 20,
                    color: "text-dark",
                  }}
                >
                  Order Number
                </span>
                <span
                  style={{ fontWeight: "bold", fontSize: 20, color: "orange" }}
                >
                  {" "}
                  {orderDetails?.orderInfo?.id}
                </span>
              </div>
            </div>

            <div style={{ paddingBottom: 10 }}>
              <div
                style={{
                  background: "#0080002b",
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                <span
                  style={{
                    color: "yellowgreen",
                    fontSize: 20,
                    fontWeight: "bold",
                  }}
                >
                  {orderDetails?.orderInfo?.order_status}
                </span>
              </div>
            </div>
          </div>

          <Grid
            container
            md={12}
            sm={12}
            xs={12}
            style={{
              fontSize: 14,
              display: "flex",
              justifyContent: "space-between",
            }}
            spacing={2}
          >
            <Grid item md={6} sm={12} xs={12}>
              <div style={{ marginBottom: 5, marginTop: 20 }}>
                <span
                  style={{
                    color: "text-dark",
                    fontSize: 16,
                  }}
                >
                  Customer Details
                </span>
              </div>
              <div
                style={{
                  borderRadius: 10,
                  padding: 10,

                  flexDirection: "column",
                  display: "flex",
                  boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                  background: "white",
                  minHeight: 220,
                }}
              >
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <span style={{ minWidth: 130 }}>Customer Name </span>
                  <span>
                    :{" "}
                    {orderDetails.orderInfo?.user?.firstName +
                      " " +
                      orderDetails.orderInfo?.user?.lastName}
                  </span>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <span style={{ minWidth: 130 }}>Email Id </span>

                  <span>: {orderDetails.orderInfo?.user?.email_id}</span>
                </div>

                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <span style={{ minWidth: 130 }}>Phone</span>
                  <span> : {orderDetails.orderInfo?.user?.email_id}</span>{" "}
                </div>

                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <span style={{ minWidth: 130 }}> Preferred Name</span>

                  <span> : {orderDetails.orderInfo?.user?.preferred_name}</span>
                </div>

                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <span style={{ minWidth: 130 }}> Pronouns</span>
                  <span> : {orderDetails.orderInfo?.user?.pronouns}</span>
                </div>

                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <span style={{ minWidth: 130 }}> DOB</span>
                  <span> : {orderDetails.orderInfo?.user?.dob}</span>{" "}
                </div>

                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <span style={{ minWidth: 130 }}> Occupation</span>
                  <span> : {orderDetails.orderInfo?.user?.occupation}</span>
                </div>

                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <span style={{ minWidth: 130 }}>Skintone</span>
                  <span> : {orderDetails.orderInfo?.user?.skintone}</span>
                </div>

                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <span style={{ minWidth: 130 }}>Head Size</span>
                  <span> : {orderDetails.orderInfo?.user?.headsize}</span>
                </div>
              </div>
            </Grid>

            <Grid item md={6} sm={12} xs={12}>
              <div style={{ marginBottom: 5, marginTop: 20 }}>
                <span
                  style={{
                    color: "grey",
                    fontSize: 16,
                  }}
                >
                  Billing Address
                </span>
              </div>

              <div
                style={{
                  borderRadius: 10,
                  padding: 10,
                  flexDirection: "column",
                  display: "flex",
                  boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                  background: "white",
                  fontSize: 14,
                  minHeight: 220,
                }}
              >
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <span style={{ whiteSpace: "nowrap", minWidth: 120 }}>
                    Street Name
                  </span>
                  <span>
                    {" "}
                    : {orderDetails?.billing_address?.delivery_address ?? "NA"}
                  </span>
                </div>

                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <span style={{ whiteSpace: "nowrap", minWidth: 120 }}>
                    Area
                  </span>
                  <span> : {orderDetails?.billing_address?.area ?? "NA"}</span>
                </div>

                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <span style={{ whiteSpace: "nowrap", minWidth: 120 }}>
                    Province
                  </span>
                  <span>
                    {" "}
                    : {orderDetails?.billing_address?.province ?? "NA"}
                  </span>
                </div>

                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <span style={{ whiteSpace: "nowrap", minWidth: 120 }}>
                    City
                  </span>
                  <span> : {orderDetails?.billing_address?.city ?? "NA"}</span>
                </div>

                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <span style={{ whiteSpace: "nowrap", minWidth: 120 }}>
                    Postal Code
                  </span>
                  <span>
                    {" "}
                    : {orderDetails?.billing_address?.postalcode ?? "NA"}
                  </span>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <span style={{ whiteSpace: "nowrap", minWidth: 120 }}>
                    Phone
                  </span>
                  <span> : {orderDetails?.billing_address?.phone ?? "NA"}</span>
                </div>
              </div>
            </Grid>
            {/*   <Grid item md={6} sm={6} xs={6}>
        <div
          style={{
            border: "1px solid grey",
            borderRadius: 10,
            padding: 10,
            margin: 10,
            flexDirection: "column",
            display: "flex",
          }}
        >
          <span style={{ color: "white", fontSize: 19 }}>Order Summary</span>
          <span>Date : Sun, may 2, 2022, 06:24 AM</span>

          <span>Sub Total : R250</span>
          <span>Discount : R150</span>

          <span>Total Amount : R250</span>
        </div>
      </Grid> */}
          </Grid>

          <div>
            <div style={{ marginBottom: 5, marginTop: 20 }}>
              <span
                style={{
                  color: "grey",
                  fontSize: 16,
                }}
              >
                Collection Summary
              </span>
            </div>

            <Grid
              container
              md={12}
              sm={12}
              xs={12}
              style={{
                borderRadius: 10,
                boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                background: "white",
              }}
            >
              <Grid item md={12} sm={12} xs={12}>
                <div
                  style={{
                    margin: 10,
                  }}
                >
                  <table className="table">
                    <thead style={{ color: "text-dark", textAlign: "left" }}>
                      <th style={{ color: "text-dark", textAlign: "left" }}>
                        Collections
                      </th>
                      <th style={{ color: "text-dark", textAlign: "left" }}>
                        Qty
                      </th>
                      <th style={{ color: "text-dark", textAlign: "left" }}>
                        Price
                      </th>
                      <th style={{ color: "text-dark", textAlign: "left" }}>
                        Variants Price
                      </th>
                      <th style={{ color: "text-dark", textAlign: "left" }}>
                        Total
                      </th>
                    </thead>
                    <tbody style={{ color: "text-dark" }}>
                      {orderDetails?.order_items?.map((vas) => {
                        return (
                          <tr>
                            <td>
                              <div>
                                <span style={{ color: "text-dark" }}>
                                  {vas.collection_name}
                                </span>
                              </div>

                              <div style={{ marginTop: 0 }}>
                                <span
                                  style={{
                                    fontSize: 12,
                                    fontWeight: "bold",
                                    color: "orange",
                                  }}
                                >
                                  Variants
                                </span>
                                {vas.order_items_variants.map((sa) => {
                                  return (
                                    <div style={{ fontSize: 12 }}>
                                      <span>
                                        {sa.variant_name} : {sa.variant_value}
                                      </span>
                                    </div>
                                  );
                                })}

                                {/*            <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    fontSize: 12,
                                  }}
                                >
                                  <span>size : xl</span>
                                  <span>color : blue</span>
                                  <span>length : 24</span>
                                  <span>test : 26</span>
                                </div> */}
                              </div>
                            </td>
                            <td>
                              <span>{vas.collection_qty}</span>
                            </td>
                            <td>
                              <span>R{vas.collection_price}</span>
                            </td>
                            <td>
                              R
                              {vas.order_items_variants.reduce(
                                (a, v) => (a = a + v.variant_price),
                                0
                              )}
                            </td>
                            <td>
                              <span>
                                R
                                {`${
                                  vas.order_items_variants.reduce(
                                    (a, v) => (a = a + v.variant_price),
                                    0
                                  ) + parseInt(vas.collection_price)
                                }`}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Grid>
            </Grid>
          </div>

          <div>
            <Grid
              container
              md={12}
              sm={12}
              xs={12}
              style={{
                justifyContent: "space-between",
                padding: 10,
                marginBottom: 100,
              }}
              spacing={2}
            >
              <Grid
                item
                md={6}
                sm={12}
                xs={12}
                style={{ borderRadius: 10, padding: 0 }}
              >
                <div style={{ marginBottom: 5, marginTop: 20 }}>
                  <span
                    style={{
                      color: "grey",
                      fontSize: 16,
                    }}
                  >
                    Delivery Details
                  </span>
                </div>

                <div
                  style={{
                    borderRadius: 10,
                    padding: 10,
                    flexDirection: "column",
                    display: "flex",
                    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                    background: "white",
                    fontSize: 14,
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span style={{ minWidth: 100 }}>Delivery to The Don</span>
                    <span> No</span>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span style={{ whiteSpace: "nowrap", minWidth: 120 }}>
                      Delivery Options
                    </span>
                    <span>
                      {" "}
                      {orderDetails?.orderInfo?.delivery_option?.title}
                    </span>
                  </div>

                  {orderDetails?.orderInfo?.delivery_ToDon === "false" && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ whiteSpace: "nowrap", minWidth: 120 }}>
                          Street Name
                        </span>
                        <span>
                          {" "}
                          {orderDetails?.shipping_address?.street_name}
                        </span>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ whiteSpace: "nowrap", minWidth: 120 }}>
                          Area
                        </span>
                        <span> {orderDetails?.shipping_address?.area}</span>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ whiteSpace: "nowrap", minWidth: 120 }}>
                          Province
                        </span>
                        <span> {orderDetails?.shipping_address?.province}</span>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ whiteSpace: "nowrap", minWidth: 120 }}>
                          City
                        </span>
                        <span> {orderDetails?.shipping_address?.city}</span>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ whiteSpace: "nowrap", minWidth: 120 }}>
                          Postal Code
                        </span>
                        <span>
                          {" "}
                          {orderDetails?.shipping_address?.postal_code}
                        </span>
                      </div>
                    </>
                  )}
                  <div style={{ marginBottom: 5, marginTop: 20 }}>
                    <span
                      style={{
                        color: "grey",
                        fontSize: 16,
                      }}
                    >
                      Order Status Update
                    </span>
                  </div>

                  <div
                    style={{
                      borderRadius: 10,
                      padding: 10,
                      flexDirection: "column",
                      display: "flex",
                      boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                      background: "white",
                      fontSize: 14,
                    }}
                  >
                    {/*         <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span style={{ minWidth: 100 }}>Delivery to The Don</span>
                    <span> No</span>
                  </div> */}
                    <Select
                      /*         value={orderStatus} */
                      onChange={onUpdateChange}
                      options={options}
                      styles={customStyles}
                      className="select"
                    />
                    <br />

                    <button
                      className="btn btn-primary btn-sm"
                      onClick={onUpdateSubmitHandle}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </Grid>
              <Grid
                item
                md={5}
                sm={12}
                xs={12}
                style={{ borderRadius: 10, padding: 0 }}
              >
                <div style={{ marginBottom: 5, marginTop: 20 }}>
                  <span
                    style={{
                      color: "grey",
                      fontSize: 16,
                    }}
                  >
                    Order Summary
                  </span>
                </div>
                <div
                  style={{
                    borderRadius: 10,
                    padding: 10,

                    flexDirection: "column",
                    display: "flex",
                    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                    background: "white",
                    fontSize: 14,
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span>Date </span>
                    <span>May 2, 2022 06:24 PM</span>
                  </div>

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span>Sub Total </span>
                    <span>R{orderDetails?.orderInfo?.sub_total}</span>
                  </div>

                  {orderDetails?.orderInfo?.discount_amount > 0 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>Discount </span>
                      <span>R{orderDetails?.orderInfo?.discount_amount}</span>
                    </div>
                  )}
                  <div
                    style={{
                      borderBottom: "1px solid grey",
                      paddingTop: 5,
                      paddingBottom: 5,
                    }}
                  />

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span style={{ fontSize: 20 }}>Total Amount </span>
                    <span style={{ fontSize: 20 }}>
                      R{orderDetails?.orderInfo?.total_amount}
                    </span>
                  </div>
                  <div
                    style={{
                      borderTop: "1px solid grey",
                      paddingTop: 5,
                      paddingBottom: 5,
                    }}
                  />

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span>Payment Status </span>
                    <span>
                      {orderDetails?.orderInfo?.payment_detail?.payment_status}
                    </span>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span>Payment ID</span>
                    <span>
                      {orderDetails?.orderInfo?.payment_detail?.PG_payment_id}
                    </span>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </>
  );
}

export default OrderDetailScreen;
