function usePagination(data, currentPage, setCurrentPage, itemsPerPage) {
  const maxPage = Math.ceil(data?.length / itemsPerPage);

  function currentData() {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    const newData = data.slice(begin, end);
    return newData;
  }

  function first() {
    setCurrentPage(1);
  }

  function last() {
    setCurrentPage(maxPage);
  }

  function next() {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  }

  function jump(page) {
    const pageNumber = Math.max(1, page);
    setCurrentPage((currentPage) => Math.min(pageNumber, maxPage));
  }

  return { next, prev, jump, first, last, currentData, currentPage, maxPage };
}

export default usePagination;
