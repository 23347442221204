import { Button, Modal } from 'react-bootstrap';
import { btnStyle } from '../utils/commonStyles';
const ModalComp = ({
  isOpen,
  setisOpen,
  handleConfirm,
  children,
  title,
  hideButtons,
}) => {
  return (
    <div>
      <Modal className='fade' show={isOpen} onHide={setisOpen}>
        <Modal.Header>
          <h4>
            <span>{title}</span>
          </h4>
          <Button onClick={() => setisOpen(false)} variant='' className='close'>
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div>{children}</div>
        </Modal.Body>
        {!hideButtons && (
          <Modal.Footer>
            <div className='container'>
              <div className='row'>
                <div className='col-sm-1'></div>
                <div className='col-sm-5 col-lg-5'>
                  <Button
                    style={btnStyle}
                    onClick={() => setisOpen(false)}
                    variant='danger light'
                  >
                    Close
                  </Button>
                </div>
                <div className='col-sm-5 col-lg-5'>
                  <Button
                    style={btnStyle}
                    onClick={() => {
                      handleConfirm();
                    }}
                    variant='success light'
                  >
                    Confirm
                  </Button>
                </div>
                <div className='col-sm-1'></div>
              </div>
            </div>
          </Modal.Footer>
        )}
      </Modal>
    </div>
  );
};

export default ModalComp;
