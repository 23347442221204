import { Card, Modal, Table } from 'react-bootstrap';
import { useState } from 'react';

import { modalOpener } from '../../utils/commonFn';
import AddTimeSlot from './AddTimeSlot';
import ModalComp from '../../ModalComp/ModalComp';
import { TimeSlotList } from '../../utils/const';

const SubTimeSlotModal = ({
  data,
  handleEdit,
  handleDelete,
  handleAddSlot,
}) => {
  const [openAddDateModal, setOpenAddDateModal] = useState(false);

  return (
    <>
      <button
        onClick={handleAddSlot}
        className='btn btn-info shadow  float-right'
      >
        Add Slot
      </button>
      <Table responsive>
        <thead>
          <tr>
            <th>
              <strong>Sl. No</strong>
            </th>
            <th>
              <strong>Time Slots</strong>
            </th>
            <th>
              <strong>Actions</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((time) => {
              return (
                <tr key={time.id}>
                  <td className='py-3'>
                    <>{`${time.from.replace('T', ' ').replace('.000Z', '')}`}</>
                  </td>
                  <td className='py-3'>
                    <>{time.to.replace('T', ' ').replace('.000Z', '')}</>
                  </td>
                  <td className='py-3'>
                    <div className='d-flex'>
                      <button
                        onClick={() => handleEdit(time, time.id)}
                        className='btn btn-primary shadow btn-xs sharp mr-3'
                      >
                        <i className='fa fa-pencil'></i>
                      </button>
                      <button
                        onClick={() => {
                          handleDelete(time.id);
                        }}
                        className='btn btn-danger shadow btn-xs sharp'
                      >
                        <i className='fa fa-trash'></i>
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      <ModalComp
        isOpen={openAddDateModal}
        setisOpen={setOpenAddDateModal}
        title='Time Allotment'
        hideButtons
      >
        <AddTimeSlot data={TimeSlotList} handleAddSlot={handleAddSlot} />
      </ModalComp>
    </>
  );
};
export default SubTimeSlotModal;
