import axiosInstance from '../../../services/AxiosInstance';
export async function addAddOnApi(value) {
  try {
    const res = await axiosInstance.post('don/variant', value);

    if (res.hasError) {
      throw 'Server error';
    }

    return res?.data;
  } catch (error) {
    return error;
  }
}
export async function editAddOnApi(id, value) {
  try {
    const res = await axiosInstance.put(`don/variant/${id}`, value);

    if (res?.status !== 200) {
      return 'No Response From API';
    }

    return res?.data;
  } catch (error) {
    console.log('=>', error);

    return false;
  }
}

export async function getAddOnApi() {
  try {
    const res = await axiosInstance.get('don/variant');
    return res?.data;
  } catch (err) {
    console.log(err);
  }
}
export async function deleteAddOnApi(id) {
  try {
    const res = await axiosInstance.delete(`don/variant/${id}`);
    return res?.data;
  } catch (err) {
    console.log(err);
  }
}
