const UserRoleTable = ({ data, handleDelete, handleEdit }) => {
  return (
    <>
      {data.map((user) => {
        return (
          <tr key={user?.id}>
            <td>{user?.slNo}</td>
            <td>{user?.name}</td>
            <td>
              {user?.user_roles_permissions.map((item, index) => {
                return (index ? ", " : "") + item?.route_name;
              })}
            </td>
            {/*             <td>{user?.status}</td> */}
            <td>
              <div className="d-flex">
                <button
                  onClick={() => handleEdit(user)}
                  className="btn btn-primary shadow btn-xs sharp mr-1"
                >
                  <i className="fa fa-pencil"></i>
                </button>
                <button
                  onClick={() => handleDelete(user.id)}
                  type="button"
                  className="btn btn-danger shadow btn-xs sharp"
                >
                  <i className="fa fa-trash"></i>
                </button>
              </div>
            </td>
          </tr>
        );
      })}
    </>
  );
};
export default UserRoleTable;
