import { useEffect, useState, useRef } from 'react';
import makeAnimated from 'react-select/animated';
import swal from 'sweetalert';
import Select from 'react-select';

import { Card, Button } from 'react-bootstrap';

import { serviceStatus } from '../../utils/const';
import { btnStyle, inputStyle, customStyles } from '../../utils/commonStyles';
//api call
import { updateUser, getAllUsers } from '../../utils/apiService/commonUser';

const EditClient = ({ closeModal, obj, getList }) => {
  const formRef = useRef();
  const animatedComponents = makeAnimated();
  const [addMember, setAddMember] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});
  const [clientId, setClientId] = useState();
  const [membersData, setMembersData] = useState({
    fullname: '',
    email_id: '',
    status: '',
  });
  // From Api
  const [addMemberDataRes, setAddMemberDataRes] = useState({
    success: '',
    error: '',
  });

  const handleCancel = () => {
    closeModal(false);
  };
  const handleAddMember = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setAddMember({ ...addMember, [name]: value });
  };
  // const handleSelect = (e) => {
  //   const value = e.value;
  //   setAddMember({ ...addMember, user_role: value });
  // };
  const handleChangeDrpdwn = (e, key) => {
    const value = e.value;
    setAddMember({ ...addMember, [key]: value });
  };

  const handleSubmit = () => {
    if (
      membersData?.firstName &&
      membersData?.email_id
      // &&
      // addMember?.phone &&
      // addMember?.user_role
    ) {
      updateUser(obj?.id, addMember)
        .then((res) => {
          setAddMemberDataRes({
            ...addMemberDataRes,
            success: res.message,
            error: '',
          });
          swal('Good Job!', 'Data updated successfully', 'success');
          closeModal(false);
          getList();
        })
        .catch((err) => {
          swal(err, 'Please check the fields', 'error');
          closeModal(false);
        });
    } else {
      swal('Oops!', 'Please check the fields', 'error');
      closeModal(false);
    }
  };
  useEffect(() => {
    if (obj)
      setMembersData({
        ...membersData,
        firstName: obj.firstName,
        lastName: obj.lastName,
        email_id: obj.email_id,
        status: obj.status,
      });
  }, []);

  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12 col-lg-12'>
            <form
              ref={formRef}
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              action=''
            >
              <div class='form-group'>
                <label className="text-dark" htmlFor='fullname'>First Name</label>
                <input
                  style={inputStyle}
                  type='text'
                  name='firstName'
                  id='firstName'
                  class='form-control'
                  placeholder='First Name'
                  aria-describedby='helpId'
                  onChange={handleAddMember}
                  defaultValue={membersData.firstName}
                />
              </div>
              <div class='form-group'>
                <label className="text-dark" htmlFor='fullname'>Last Name</label>
                <input
                  style={inputStyle}
                  type='text'
                  name='lastName'
                  id='lastName'
                  class='form-control'
                  placeholder='Last Name'
                  aria-describedby='helpId'
                  onChange={handleAddMember}
                  defaultValue={membersData.lastName}
                />
              </div>
              <div class='form-group'>
                <label className="text-dark" htmlFor='email'>Email</label>
                <input
                  style={inputStyle}
                  type='email'
                  name='email_id'
                  id='email'
                  class='form-control'
                  placeholder='Email'
                  aria-describedby='helpId'
                  onChange={handleAddMember}
                  defaultValue={membersData.email_id}
                />
              </div>
              <div className='d-flex flex-column '>
                <label className='text-dark'>Status</label>
                <Select
                  styles={customStyles}
                  placeholder='Status'
                  options={serviceStatus}
                  components={animatedComponents}
                  isMulti={false}
                  onChange={(e) => handleChangeDrpdwn(e, 'status')}
                  defaultValue={
                    obj
                      ? {
                          label: obj.status,
                          value: obj.status,
                        }
                      : ''
                  }
                />
              </div>
              <div className='row'>
                <div className='col-sm-12 mt-3'>
                  <div className='container'>
                    <div className='row '>
                      <div className='col-sm-4'></div>
                      <div className='col-sm-8'>
                        <div className='row'>
                          <div className='col-sm-6'>
                            <Button
                              style={btnStyle}
                              className='mr-4'
                              onClick={handleCancel}
                              variant='danger light'
                              type='button'
                            >
                              Cancel
                            </Button>
                          </div>
                          <div className='col-sm-6'>
                            <Button
                              style={btnStyle}
                              variant='success light'
                              type='submit'
                            >
                              Update
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditClient;
