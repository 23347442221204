import { useState, useEffect } from "react";
import { Card, Table } from "react-bootstrap";

import swal from "@sweetalert/with-react";

// Comp
import DropOffSdsTable from "./DropOffSdsTable";
import AddDropOffSds from "./AddDropOffSds";
import ModalComp from "../../../ModalComp/ModalComp";
import PaginationComp from "../../PaginationComp/PaginationComp";

// Utils
import { services, serviceStatus } from "../../../utils/const";
import { modalOpener } from "../../../utils/commonFn";
import { searchInputStyle } from "../../../utils/commonStyles";

// API
import { deleteServiceApi } from "../../../utils/apiService/serviceApis/sameDayService";
import { getDropoffServicesApi } from "../../../utils/apiService/serviceApis/dropOffService";

const DropOffSds = () => {
  const [openDeleteServiceModal, setOpenDeleteServiceModal] = useState(false);
  const [openEditServiceModal, setOpenEditServiceModal] = useState(false);
  const [openAddServiceModal, setOpenAddServiceModal] = useState(false);

  const [displayData, setDisplayData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSearchedPage, setCurrentSearchedPage] = useState(1);

  const [isSearched, setIsSearched] = useState(false);

  const [searchedText, setSearchedText] = useState("");
  const [searchedResult, setSearchedResult] = useState("");
  const [isNotFound, setIsNotFound] = useState(false);

  const [serviceList, setserviceList] = useState("");
  const [serviceId, setserviceId] = useState("");
  const [dataToEdit, setDataToEdit] = useState("");

  const disaplayDataHandler = (data) => {
    setDisplayData(data);
  };

  const handleDelete = (id) => {
    setserviceId(id);
    modalOpener(setOpenDeleteServiceModal);
  };

  const handleEdit = (obj) => {
    setDataToEdit(obj);
    modalOpener(setOpenEditServiceModal);
  };

  const getServiceList = () => {
    getDropoffServicesApi()
      .then((res) => {
        res.data.map((item, index) => {
          item.sNo = index + 1;
        });

        setserviceList(res.data);
      })
      .catch((err) => console.log(err));
  };

  const handleConfirmDelete = (id) => {
    if (id) {
      deleteServiceApi(id)
        .then((res) => {
          swal("Success", res.message, "success");
          getServiceList();
          setserviceId("");
        })
        .catch((err) => {
          console.log(err);
        });

      setOpenDeleteServiceModal(false);
    }
  };

  useEffect(() => {
    getServiceList();
  }, []);

  const provideSearchData = (data, searchedText) => {
    const filteredArr = data.filter((item) => {
      return (
        item.service_type.toLowerCase().trim().includes(searchedText) ||
        item.service_name.toLowerCase().trim().includes(searchedText) ||
        item.service_price
          .toString()
          .toLowerCase()
          .trim()
          .includes(searchedText) ||
        item.duration_value.toString().toLowerCase().includes(searchedText) ||
        item.status.toLowerCase().trim().includes(searchedText)
      );
    });
    if (filteredArr.length) {
      setSearchedResult(filteredArr);
      setIsNotFound(false);
      return;
    }
    setIsNotFound(true);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    if (value) {
      const trimmedSearchedText = value.toString().toLowerCase().trim();
      setIsSearched(true);
      setSearchedText(value);

      provideSearchData(serviceList, trimmedSearchedText);
      return;
    }
    setIsSearched(false);
    setSearchedText("");
    setCurrentSearchedPage(1);
    setSearchedResult("");
    setIsNotFound(false);

    return;
  };

  return (
    <div>
      <div>
        <Card.Header>
          <Card.Title>Services</Card.Title>

          <button
            onClick={() => {
              modalOpener(setOpenAddServiceModal);
            }}
            className="btn btn-info shadow  "
          >
            Add Services
          </button>
        </Card.Header>
        <Card.Body>
          <div className="form-group">
            <input
              type="search"
              style={searchInputStyle}
              aria-describedby="helpId"
              placeholder="Search anything...."
              onChange={(e) => handleSearch(e)}
            />
          </div>
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <strong>Services</strong>
                </th>

                {/*             <th>
                  <strong>Price</strong>
                </th>
                <th>
                  <strong>Duration</strong>
                </th> */}
                <th>
                  <strong>Status</strong>
                </th>
                <th>
                  <strong>Actions</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              {!isNotFound && displayData ? (
                <DropOffSdsTable
                  data={displayData}
                  handleEdit={(obj) => handleEdit(obj)}
                  handleDelete={(id) => handleDelete(id)}
                />
              ) : (
                <tr>
                  <td>
                    <h2>No data found</h2>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer>
          {searchedResult ? (
            <PaginationComp
              data={searchedResult}
              currentPage={currentSearchedPage}
              setCurrentPage={setCurrentSearchedPage}
              disaplayDataHandler={disaplayDataHandler}
              searchedText={searchedText}
            />
          ) : serviceList ? (
            <PaginationComp
              data={serviceList}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              disaplayDataHandler={disaplayDataHandler}
            />
          ) : null}
        </Card.Footer>

        <ModalComp
          isOpen={openDeleteServiceModal}
          setisOpen={setOpenDeleteServiceModal}
          handleConfirm={() => handleConfirmDelete(serviceId)}
          title="Delete Service"
        >
          <p>Are you sure?</p>
        </ModalComp>

        <ModalComp
          isOpen={openEditServiceModal}
          setisOpen={setOpenEditServiceModal}
          title="Edit Service"
          hideButtons
        >
          <AddDropOffSds
            options1={services}
            options2={serviceStatus}
            closeModal={setOpenEditServiceModal}
            getList={getServiceList}
            obj={dataToEdit}
          />
        </ModalComp>

        <ModalComp
          isOpen={openAddServiceModal}
          setisOpen={setOpenAddServiceModal}
          title="Add Service"
          hideButtons
        >
          <AddDropOffSds
            options1={services}
            options2={serviceStatus}
            closeModal={setOpenAddServiceModal}
            getList={getServiceList}
          />
        </ModalComp>
      </div>
    </div>
  );
};

export default DropOffSds;
