import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Col, Card, Table } from "react-bootstrap";

// Components
import ModalComp from "../../ModalComp/ModalComp";
import CollectionDataComp from "./CollectionDataComp";
import PaginationComp from "../PaginationComp/PaginationComp";
// Utils

//utils
import { searchInputStyle } from "../../utils/commonStyles";
//api call
import {
  addCollection,
  addCollectionImage,
  deleteCollection,
  getAllCollection,
} from "../../utils/apiService/collection";
import { getAddOnApi } from "../../utils/apiService/addOn";
import swal from "sweetalert";
import LoadingBalls from "../../pages/Collections/components/LoadingBalls";

const Collections = () => {
  const history = useHistory();

  const [displayData, setDisplayData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [foundResult, setFoundResult] = useState("");
  const [name, setName] = useState("");
  const [collection, setCollection] = useState("");
  const [collectionId, setCollectionId] = useState("");
  const [editCollectionData, setEditCollectionData] = useState("");
  const [loading, setLoading] = useState(false);
  const handleConfirm = () => {
    console.log("confirmed");
  };

  const disaplayDataHandler = (data) => {
    setDisplayData(data);
  };
  const filter = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      const results = collection.filter((user) => {
        return (
          user?.slNo
            ?.toString()
            .toLowerCase()
            .startsWith(keyword.toLowerCase()) ||
          user?.product_name?.toLowerCase().startsWith(keyword.toLowerCase()) ||
          user?.status?.toLowerCase().startsWith(keyword.toLowerCase()) ||
          user?.product_care?.toLowerCase().startsWith(keyword.toLowerCase()) ||
          user?.status?.toLowerCase().startsWith(keyword.toLowerCase())
        );
      });
      setFoundResult(results);
    } else {
      setFoundResult(collection);
    }
    setName(keyword);
  };

  const handleEdit = (obj) => {
    setEditCollectionData(obj);
    console.log("calling edit ", obj);
    history.push({
      pathname: "/edit-collection",
      state: { details: obj },
    });
  };
  console.log(editCollectionData, "edit collection");

  const hanndleDeleteCollection = (id) => {
    setCollectionId(id);
    setOpenModal(true);
  };
  const handleDeleteConfirmation = (id) => {
    if (id) {
      deleteCollection(id)
        .then((res) => {
          swal("Well Done!", "Deleted successfully", "success");
          console.log(res, "res");
          getCollection();
          setOpenModal(false);
        })
        .catch((err) => {
          console.log(err, "error");
          setOpenModal(false);
        });
    }
  };
  const addCollectionFn = () => {
    //
    history.push("/all-collections");
  };

  useEffect(() => {
    getCollection();
  }, []);
  const getCollection = () => {
    setLoading(true);
    getAllCollection()
      .then((res) => {
        setLoading(false);
        let dataItem = res?.data;
        dataItem?.map((ele, i) => {
          ele.slNo = i + 1;
        });
        setCollection(dataItem);
        console.log(res, "collection");
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  return (
    <div>
      <Col lg={12}>
        {loading && <LoadingBalls />}
        <Card>
          <Card.Header>
            <Card.Title>Collections</Card.Title>
            <button onClick={addCollectionFn} className="btn btn-info shadow  ">
              Add Collection
            </button>
          </Card.Header>
          <Card.Body>
            <div className="form-group">
              <input
                type="search"
                onChange={filter}
                value={name}
                style={searchInputStyle}
                id=""
                aria-describedby="helpId"
                placeholder="Search anything...."
              />
            </div>
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <strong>S.No</strong>
                  </th>
                  <th>
                    <strong>Image</strong>
                  </th>
                  <th>
                    <strong>Product Title</strong>
                  </th>
                  <th>
                    <strong>Product Care</strong>
                  </th>
                  <th>
                    <strong>Status</strong>
                  </th>
                  <th>
                    <strong>Actions</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* {displayData && (
                  <CollectionDataComp
                    data={displayData}
                    handleDelete={hanndleDeleteCollection}
                  />

                )} */}
                {displayData ? (
                  <CollectionDataComp
                    data={displayData}
                    handleEdit={(obj) => handleEdit(obj)}
                    handleDelete={(id) => hanndleDeleteCollection(id)}
                  />
                ) : (
                  <h6>No results found!</h6>
                )}
              </tbody>
            </Table>
          </Card.Body>
          <Card.Footer>
            {foundResult ? (
              <PaginationComp
                data={foundResult}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                disaplayDataHandler={disaplayDataHandler}
                searchedText={name}
              />
            ) : collection ? (
              <PaginationComp
                data={collection}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                disaplayDataHandler={disaplayDataHandler}
                searchedText={name}
              />
            ) : null}
          </Card.Footer>
        </Card>
      </Col>
      <ModalComp
        isOpen={openModal}
        setisOpen={setOpenModal}
        handleConfirm={() => handleDeleteConfirmation(collectionId)}
        title="Delete Collection"
      >
        <p>Are you sure?</p>
      </ModalComp>
    </div>
  );
};

export default Collections;
