import { useState } from "react";
import { useEffect } from "react";
import { Col, Card, Table } from "react-bootstrap";
import swal from "@sweetalert/with-react";

// Components
import ModalComp from "../../ModalComp/ModalComp";
import TableDataComp from "./TableDataComp";
import PaginationComp from "../PaginationComp/PaginationComp";
import EditClient from "./EditClient";
import { modalOpener } from "../../utils/commonFn";
//utils
import { searchInputStyle } from "../../utils/commonStyles";
//api call
import { getAllUsers, deleteUser } from "../../utils/apiService/commonUser";
import LoadingBalls from "../../pages/Collections/components/LoadingBalls";

const Clients = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openEditClientModal, setOpenEditClientModal] = useState(false);

  const [displayData, setDisplayData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSearchedPage, setCurrentSearchedPage] = useState(1);

  const [isSearched, setIsSearched] = useState(false);

  const [searchedText, setSearchedText] = useState("");
  const [searchedResult, setSearchedResult] = useState("");
  const [isNotFound, setIsNotFound] = useState(false);

  const [clientData, setClientData] = useState("");
  const [clientId, setClientId] = useState("");
  const [dataEdit, setDataEdit] = useState("");

  const [loading, setLoading] = useState(false);
  //Api function
  const getUsers = () => {
    setLoading(true);
    getAllUsers()
      .then((res) => {
        setLoading(false);
        console.log(res, "Res data");
        let dataItem = res?.data.filter((ele) => {
          return ele?.user_type === "client";
        });
        console.log(dataItem, "dataitem");

        dataItem.map((ele, i) => {
          ele.slNo = i + 1;
        });
        // console.log(dataItem,"data item")
        setClientData(dataItem);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };
  useEffect(() => {
    getUsers();
  }, []);

  const handleEdit = (obj) => {
    setDataEdit(obj);
    console.log(dataEdit, "data edit");
    modalOpener(setOpenEditClientModal);
  };

  const handleDelete = (id) => {
    setClientId(id);
    console.log(id, "id of client");
    modalOpener(setOpenModal);
  };

  const handleDeleteConfirm = (id) => {
    if (id) {
      deleteUser(id)
        .then((res) => {
          swal("Well Done!", res.message, "success");
          getUsers();
        })
        .catch((err) => {
          swal("Oops, Something went Wrong!", err.message, "success");
          console.log(err, "error");
        });
    }
    setOpenModal(false);
  };

  const disaplayDataHandler = (data) => {
    setDisplayData(data);
  };

  const provideSearchData = (data, searchedText) => {
    const filteredArr = data.filter((item) => {
      return (
        item?.fullname?.toLowerCase().trim().includes(searchedText) ||
        item?.email_id?.toLowerCase().includes(searchedText) ||
        item?.status?.toLowerCase().trim().includes(searchedText) ||
        item?.delivery_options?.forEach((ele) => {
          ele?.phone?.toString().toLowerCase().includes(searchedText);
        })
        // item?.slNo?.includes(searchedText)
      );
    });
    if (filteredArr.length) {
      setSearchedResult(filteredArr);
      setIsNotFound(false);
      return;
    }
    setIsNotFound(true);
  };

  const handleSearch = (e) => {
    const value = e.target.value;

    if (value) {
      const trimmedSearchedText = value.toString().toLowerCase().trim();

      setIsSearched(true);
      setSearchedText(value);
      provideSearchData(clientData, trimmedSearchedText);
      return;
    }
    setIsSearched(false);
    setSearchedText("");
    setCurrentSearchedPage(1);
    setSearchedResult("");
    setIsNotFound(false);

    return;
  };

  return (
    <div>
      {loading && <LoadingBalls />}
      <Col lg={12}>
        <Card>
          <Card.Header>
            <Card.Title>Clients</Card.Title>
          </Card.Header>
          <Card.Body>
            <div className="form-group">
              <input
                type="search"
                style={searchInputStyle}
                aria-describedby="helpId"
                placeholder="Search anything...."
                onChange={(e) => handleSearch(e)}
              />
            </div>
            {/* {clientData.map((item)=>{
              return(
                <div key={item?.id}>
                <h1>{item.fullname}</h1>
                </div>
              )
            })} */}
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <strong>Sl No.</strong>
                  </th>
                  <th>
                    <strong>Full name</strong>
                  </th>
                  <th>
                    <strong>Email ID</strong>
                  </th>
                  {/* <th>
                    <strong>Phone no</strong>
                  </th> */}
                  <th>
                    <strong>Status</strong>
                  </th>
                  <th>
                    <strong>Actions</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {!isNotFound && displayData ? (
                  <TableDataComp
                    data={displayData}
                    hanndleDelete={(id) => handleDelete(id)}
                    handleEdit={(obj) => {
                      handleEdit(obj);
                    }}
                  />
                ) : (
                  <tr>
                    <td>
                      <h6>No data found!</h6>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Card.Body>
          <Card.Footer>
            {searchedResult ? (
              <PaginationComp
                data={searchedResult}
                currentPage={currentSearchedPage}
                setCurrentPage={setCurrentSearchedPage}
                disaplayDataHandler={disaplayDataHandler}
                searchedText={searchedText}
              />
            ) : clientData ? (
              <PaginationComp
                data={clientData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                disaplayDataHandler={disaplayDataHandler}
                searchedText={searchedText}
              />
            ) : null}
          </Card.Footer>
        </Card>
      </Col>

      <ModalComp
        isOpen={openModal}
        setisOpen={setOpenModal}
        handleConfirm={() => handleDeleteConfirm(clientId)}
        title="Delete Client"
      >
        <p className="text-center">Are you sure?</p>
      </ModalComp>
      <ModalComp
        isOpen={openEditClientModal}
        setisOpen={setOpenEditClientModal}
        title="Edit Client"
        hideButtons
      >
        <EditClient
          obj={dataEdit}
          getList={getUsers}
          closeModal={setOpenEditClientModal}
        />
      </ModalComp>
    </div>
  );
};

export default Clients;
