import axiosInstance from '../../../services/AxiosInstance';
export async function addDiscountApi(value) {
  try {
    const res = await axiosInstance.post('don/discount-code', value);

    if (res?.status !== 200) {
      return 'No Response From API';
    }

    return res?.data;
  } catch (error) {
    console.log('=>', error);

    return false;
  }
}
export async function editDiscountApi(id, value) {
  try {
    const res = await axiosInstance.put(`don/discount-code/${id}`, value);

    if (res?.status !== 200) {
      return 'No Response From API';
    }

    return res?.data;
  } catch (error) {
    console.log('=>', error);

    return false;
  }
}

export async function getDiscountApi() {
  try {
    const res = await axiosInstance.get('don/discount-code');
    return res?.data;
  } catch (err) {
    console.log(err);
  }
}
export async function deleteDiscountApi(id) {
  try {
    const res = await axiosInstance.delete(`don/discount-code/${id}`);
    return res?.data;
  } catch (err) {
    console.log(err);
  }
}
