import { useState, useEffect } from "react";
import { Card, Modal, Table } from "react-bootstrap";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import swal from "@sweetalert/with-react";
// Comp
import SameDayInstallTable from "./SameDayInstallTable";
import ModalComp from "../../ModalComp/ModalComp";
import PaginationComp from "../PaginationComp/PaginationComp";
import AddSubService from "./AddSubService";
import SubTimeSlotModal from "./SubTimeSlotModal";
import AddTimeSlot from "./AddTimeSlot";

// Utils
import {
  duration,
  serviceStatus,
  subServices,
  TimeSlotList,
} from "../../utils/const";
import { modalOpener } from "../../utils/commonFn";
import { customStyles, searchInputStyle } from "../../utils/commonStyles";

// API
import {
  getSdiServicesApi,
  deleteSdiServiceApi,
  getSdiSlotListApi,
  deleteSdiSlotApi,
} from "../../utils/apiService/serviceApis/sameDayInstall";
import { getServicesApi } from "../../utils/apiService/serviceApis/sameDayService";

const SameDayInstall = () => {
  const [openEditAdSDIModal, setOpenEditAdSDIModal] = useState(false);
  const [openAddAdSDIModal, setOpenAddAdSDIModal] = useState(false);
  const [openDeleteSdiModal, setOpenDeleteSdiModal] = useState(false);

  const [openTimeSlotModal, setOpenTimeSlotModal] = useState(false);

  const [openDeleteSlotModal, setOpenDeleteSlotModal] = useState(false);
  const [openEditSlotModal, setOpenEditSlotModal] = useState(false);
  const [openAddTimeSlotModal, setOpenAddTimeSlotModal] = useState(false);

  const [displayData, setDisplayData] = useState("");
  const [currentSearchedPage, setCurrentSearchedPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [isSearched, setIsSearched] = useState(false);

  const [searchedText, setSearchedText] = useState("");
  const [searchedResult, setSearchedResult] = useState("");
  const [isNotFound, setIsNotFound] = useState(false);

  const [subServiceList, setSubServiceList] = useState("");
  const [subSerId, setSubSerId] = useState("");
  const [dataToEdit, setDataToEdit] = useState("");
  const [optionsData, setOptionsData] = useState([]);

  const [slotList, setSlotList] = useState("");

  const [filterServOptions, setFilterServOptions] = useState([]);

  const animatedComponents = makeAnimated();

  const disaplayDataHandler = (data) => {
    setDisplayData(data);
  };

  const handleAddSlot = () => {
    setOpenAddTimeSlotModal(true);
    setOpenTimeSlotModal(false);
  };

  const handleEditSlot = (obj) => {
    setDataToEdit(obj);
    modalOpener(setOpenEditSlotModal);
  };

  const handleTimeSlot = (id) => {
    setSubSerId(id);
    setOpenTimeSlotModal(true);
  };

  const handleDeleteTimeSlot = (id) => {
    setSubSerId(id);
    modalOpener(setOpenDeleteSlotModal);
  };

  const handleConfirmDeleteSlot = (id) => {
    if (id) {
      deleteSdiSlotApi(id)
        .then((res) => {
          swal("Success", res.message, "success");
          getSdiSlotList();
          setSubSerId("");
        })
        .catch((err) => {
          console.log(err);
        });
      setOpenDeleteSlotModal(false);
    }
  };

  const getSdiSlotList = () => {
    getSdiSlotListApi()
      .then((res) => {
        res.data.map((item, index) => {
          item.sNo = index + 1;
        });
        setSlotList(res.data);
      })
      .catch((err) => console.log(err));
  };

  const handleDelete = (id) => {
    setSubSerId(id);
    modalOpener(setOpenDeleteSdiModal);
  };

  const handleEdit = (obj) => {
    setDataToEdit(obj);
    modalOpener(setOpenEditAdSDIModal);
  };

  const handleConfirmDelete = (id) => {
    if (id) {
      deleteSdiServiceApi(id)
        .then((res) => {
          swal("Success", res.message, "success");
          getSdiServiceList();
          setSubSerId("");
        })
        .catch((err) => {
          console.log(err);
        });

      setOpenDeleteSdiModal(false);
    }
  };

  const getSdiServiceList = () => {
    getSdiServicesApi()
      .then((res) => {
        let filterData = res.data
          .filter((sa) => sa.service.service_type === "appointments")
          .map((item, index) => {
            item.sNo = index + 1;

            return item;
          });

        setOptionsData(filterData);
        setSubServiceList(filterData);
      })
      .catch((err) => console.log(err));

    getSdiServicesApi()
      .then((res) => {
        let filterData = res.data.filter(
          (value) => value.service.service_type === "appointments"
        );

        const uniqueData = [
          ...new Set(filterData.map((obj) => obj.service.service_name)),
        ];
        console.log("unique", uniqueData);
        const arr = [];
        uniqueData.map((item, index) => {
          const data = {
            label: item,
            value: index,
          };
          arr.push(data);
        });
        setFilterServOptions(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const provideSearchData = (data, searchedText) => {
    const filteredArr = data.filter((item) => {
      return (
        item.sub_service_name?.toLowerCase().trim().includes(searchedText) ||
        item.duration_value
          ?.toString()
          .toLowerCase()
          .trim()
          .includes(searchedText) ||
        item.duration_type?.toLowerCase().includes(searchedText) ||
        item.price?.toString().toLowerCase().includes(searchedText) ||
        item.status?.toLowerCase().trim().includes(searchedText)
      );
    });
    if (filteredArr.length) {
      setSearchedResult(filteredArr);
      setIsNotFound(false);
      return;
    }
    setIsNotFound(true);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    if (value) {
      const trimmedSearchedText = value.toString().toLowerCase().trim();
      setIsSearched(true);
      setSearchedText(value);
      provideSearchData(subServiceList, trimmedSearchedText);
      return;
    }
    setIsSearched(false);
    setSearchedText("");
    setCurrentSearchedPage(1);
    setSearchedResult("");
    setIsNotFound(false);

    return;
  };

  useEffect(() => {
    getSdiServiceList();
    getSdiSlotList();
  }, []);

  const FilterChangeHandle = (event) => {
    console.log("EVENT", event);
    if (event !== null) {
      const filterResult = optionsData.filter(
        (val) => val.service.service_name === event.label
      );
      setSubServiceList(filterResult);
    } else {
      setSubServiceList(optionsData);
    }
  };
  return (
    <div>
      <Card.Header>
        <Card.Title>Services</Card.Title>

        <button
          onClick={() => {
            modalOpener(setOpenAddAdSDIModal);
          }}
          className="btn btn-info shadow  "
        >
          Add Option
        </button>
      </Card.Header>
      <Card.Body>
        <div className="row">
          <div className="col-sm-8">
            <div class="form-group mt-4">
              <input
                type="search"
                style={searchInputStyle}
                aria-describedby="helpId"
                placeholder="Search anything...."
                onChange={(e) => handleSearch(e)}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <label className="text-dark">Select service</label>
            <Select
              styles={customStyles}
              placeholder="Select service"
              options={filterServOptions}
              components={animatedComponents}
              isMulti={false}
              isClearable={true}
              onChange={FilterChangeHandle}
            />
          </div>
        </div>

        <Table responsive>
          <thead>
            <tr>
              <th>
                <strong>Appointment Name</strong>
              </th>
              <th>
                <strong>Option Name</strong>
              </th>

              <th>
                <strong>Price</strong>
              </th>
              <th>
                <strong>Duration</strong>
              </th>
              <th>
                <strong>Status</strong>
              </th>
              <th>
                <strong>Actions</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            {!isNotFound && displayData ? (
              <SameDayInstallTable
                data={displayData}
                handleEdit={(obj) => handleEdit(obj)}
                handleDelete={(id) => handleDelete(id)}
                handleTimeSlot={(id) => handleTimeSlot(id)}
              />
            ) : (
              <tr>
                <td>
                  <h2>No data found</h2>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card.Body>
      <Card.Footer>
        {searchedResult.length ? (
          <PaginationComp
            data={searchedResult}
            currentPage={currentSearchedPage}
            setCurrentPage={setCurrentSearchedPage}
            disaplayDataHandler={disaplayDataHandler}
            searchedText={searchedText}
          />
        ) : subServiceList ? (
          <PaginationComp
            data={subServiceList}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            disaplayDataHandler={disaplayDataHandler}
          />
        ) : null}
      </Card.Footer>

      <ModalComp
        isOpen={openDeleteSdiModal}
        setisOpen={setOpenDeleteSdiModal}
        handleConfirm={() => handleConfirmDelete(subSerId)}
        title="Delete Sub Service"
      >
        <p>Are you sure?</p>
      </ModalComp>

      <ModalComp
        isOpen={openEditAdSDIModal}
        setisOpen={setOpenEditAdSDIModal}
        title="Edit Service"
        hideButtons
      >
        <AddSubService
          options1={duration}
          options2={serviceStatus}
          closeModal={setOpenEditAdSDIModal}
          getList={getSdiServiceList}
          obj={dataToEdit}
        />
      </ModalComp>

      <ModalComp
        isOpen={openAddAdSDIModal}
        setisOpen={setOpenAddAdSDIModal}
        title="Add Sub Service"
        hideButtons
      >
        <AddSubService
          options1={duration}
          options2={serviceStatus}
          closeModal={setOpenAddAdSDIModal}
          getList={getSdiServiceList}
        />
      </ModalComp>

      {/* TIME SLOT */}
      <ModalComp
        isOpen={openTimeSlotModal}
        setisOpen={setOpenTimeSlotModal}
        title="Time Allotment"
        hideButtons
      >
        <SubTimeSlotModal
          handleDelete={(id) => handleDeleteTimeSlot(id)}
          handleAddSlot={handleAddSlot}
          handleEdit={(obj, id) => handleEditSlot(obj, id)}
          data={slotList}
        />
      </ModalComp>

      <ModalComp
        isOpen={openDeleteSlotModal}
        setisOpen={setOpenDeleteSlotModal}
        handleConfirm={() => handleConfirmDeleteSlot(subSerId)}
        title="Delete time slot"
      >
        <p>Are you sure?</p>
      </ModalComp>

      <ModalComp
        isOpen={openEditSlotModal}
        setisOpen={setOpenEditSlotModal}
        title="Edit Slot"
        hideButtons
      >
        <AddTimeSlot
          id={subSerId}
          obj={dataToEdit}
          getList={getSdiSlotList}
          closeModal={setOpenEditSlotModal}
        />
      </ModalComp>

      <ModalComp
        isOpen={openAddTimeSlotModal}
        setisOpen={setOpenAddTimeSlotModal}
        title="Time Allotment"
        hideButtons
      >
        <AddTimeSlot
          id={subSerId}
          getList={getSdiSlotList}
          closeModal={setOpenAddTimeSlotModal}
        />
      </ModalComp>
    </div>
  );
};

export default SameDayInstall;
