const MembersTableComp = ({ data, handleEdit, hanndleDelete }) => {
  return (
    <>
      {data.map((member, index) => {
        return (
          <tr key={member?.id}>
            <td className="py-3">
              <>{member?.slNo}</>
            </td>
            <td className="py-3">
              {member?.firstName} {member?.firstName}
            </td>
            <td className="py-3">
              <>{member?.email_id}</>
            </td>

            <td className="py-3">
              {member?.user_type ? member?.user_type : "-"}
            </td>
            <td className="py-3">{member?.status}</td>

            <td className="py-3">
              <div className="d-flex">
                <button
                  onClick={() => {
                    handleEdit(member);
                  }}
                  className="btn btn-primary shadow btn-xs sharp mr-3"
                >
                  <i className="fa fa-pencil"></i>
                </button>
                <button
                  onClick={() => {
                    hanndleDelete(member?.id);
                  }}
                  className="btn btn-danger shadow btn-xs sharp"
                >
                  <i className="fa fa-trash"></i>
                </button>
              </div>
            </td>
          </tr>
        );
      })}
    </>
  );
};

export default MembersTableComp;
