const DropOffAdsTable = ({ data, handleEdit, handleDelete }) => {
  console.log("DATANEWS", data);
  return (
    <>
      {data.map((addService) => {
        return (
          <tr key={addService.id}>
            <td className="py-3">
              <>
                {addService.service?.service_name}
                {/* {`${addService.sNo}. */}
              </>
            </td>
            <td className="py-3">
              <>{addService.sub_service_name}</>
            </td>
            <td className="py-3">R{addService.price}</td>
            <td className="py-3">{`${addService.duration_value} ${addService.duration_type}`}</td>
            <td className="py-3">{addService.status}</td>

            <td className="py-3">
              <div className="d-flex">
                <button
                  onClick={() => handleEdit(addService)}
                  className="btn btn-primary shadow btn-xs sharp mr-3"
                >
                  <i className="fa fa-pencil"></i>
                </button>
                <button
                  onClick={() => {
                    handleDelete(addService.id);
                  }}
                  className="btn btn-danger shadow btn-xs sharp"
                >
                  <i className="fa fa-trash"></i>
                </button>
              </div>
            </td>
          </tr>
        );
      })}
    </>
  );
};

export default DropOffAdsTable;
