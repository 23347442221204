import { useState, useEffect, useRef } from "react";
import swal from "@sweetalert/with-react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Card, Button } from "react-bootstrap";
// Component
import DateTimeComp from "./DateTimeComp";
// Utils
import { status, discountType } from "../../utils/const";
import { btnStyle, inputStyle, customStyles } from "../../utils/commonStyles";

import {
  addDiscountApi,
  editDiscountApi,
} from "../../utils/apiService/discountManagement";

const AddDiscount = ({ closeModal, getList, obj }) => {
  const animatedComponents = makeAnimated();

  const [newDiscount, setNewDiscount] = useState({
    coupon_code: "",
    valid_from: "",
    valid_till: "",
    discount_type: "",
    discount_value: "",
    status: "",
  });

  const checkValidation = () => {
    if (!newDiscount.coupon_code) {
      swal("Error", "Coupon code required!", "error");
    } else if (!newDiscount.valid_from) {
      swal("Error", "Valid from required!", "error");
    } else if (!newDiscount.valid_till) {
      swal("Error", "Valid till required!", "error");
    } else if (!newDiscount.discount_type) {
      swal("Error", "Discount type required!", "error");
    } else if (!newDiscount.discount_value) {
      swal("Error", "Discount value required!", "error");
    } else if (!newDiscount.status) {
      swal("Error", "Status required!", "error");
    }
  };

  const formRef = useRef();

  const handleCancel = () => {
    closeModal(false);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setNewDiscount({ ...newDiscount, [name]: value });
    console.log(newDiscount);
  };

  const handleChangeDrpdwn = (e, key) => {
    const value = e.value;
    setNewDiscount({ ...newDiscount, [key]: value });
  };

  const handleSubmit = () => {
    if (
      newDiscount?.coupon_code &&
      newDiscount?.valid_from &&
      newDiscount?.valid_till &&
      newDiscount?.discount_type &&
      newDiscount?.discount_value &&
      newDiscount?.status
    ) {
      !obj
        ? addDiscountApi(newDiscount)
            .then((res) => {
              closeModal(false);

              swal("Success", res.message, "success");
              getList();
              formRef.current.reset();
              setNewDiscount({});
            })
            .catch((err) => console.log(err))
        : editDiscountApi(obj.id, newDiscount)
            .then((res) => {
              closeModal(false);
              swal("Success", res.message, "success");
              getList();
              formRef.current.reset();
              setNewDiscount({});
            })
            .catch((err) => console.log(err));
    } else {
      checkValidation();
    }
  };

  useEffect(() => {
    if (obj)
      setNewDiscount({
        ...newDiscount,
        coupon_code: obj.coupon_code,
        valid_from: obj.valid_from,
        valid_till: obj.valid_till,
        discount_type: obj.discount_type,
        discount_value: obj.discount_value,
        status: obj.status,
      });
  }, []);

  return (
    <Card className="  light">
      <Card.Body className="p-0 mb-0 container-div ">
        <form
          ref={formRef}
          style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          action=""
        >
          <div className="d-flex flex-column ">
            <label className="text-dark" htmlFor="coupon_code">Cupon Code</label>
            <input
              onChange={(e) => handleChange(e)}
              style={inputStyle}
              type="text"
              name="coupon_code"
              value={newDiscount.coupon_code}
            />
          </div>

          {/* Date Time Picker */}
          <div className="d-flex flex-row justify-content-between">
            <div
              style={{ flex: "1", maxWidth: "49%" }}
              className="d-flex flex-column "
            >
              <DateTimeComp
                onChange={(e) => handleChange(e)}
                label="Valid From"
                name="valid_from"
                defaultValue={obj ? newDiscount.valid_from : ""}
              />
            </div>

            <div
              style={{ flex: "1", maxWidth: "49%" }}
              className="d-flex flex-column "
            >
              <DateTimeComp
                onChange={(e) => handleChange(e)}
                label="Valid Till"
                name="valid_till"
                defaultValue={obj ? newDiscount.valid_till : ""}
              />
            </div>
          </div>

          {/* Discount  */}
          <div className="d-flex flex-row justify-content-between">
            <div
              style={{ flex: "1", maxWidth: "49%" }}
              className="d-flex flex-column "
            >
              <label>Discount Type</label>

              <Select
                styles={customStyles}
                placeholder="Discount type"
                options={discountType}
                components={animatedComponents}
                isMulti={false}
                onChange={(e) => handleChangeDrpdwn(e, "discount_type")}
                defaultValue={
                  obj
                    ? {
                        label: obj.discount_type,
                        value: obj.discount_type,
                      }
                    : ""
                }
              />
            </div>
            <div
              style={{ flex: "1", maxWidth: "49%" }}
              className="d-flex flex-column "
            >
              <label className="text-dark" htmlFor="discount_value">Discount Value</label>
              <input
                onChange={(e) => handleChange(e)}
                value={newDiscount.discount_value}
                style={inputStyle}
                type="text"
                name="discount_value"
              />
            </div>
          </div>

          <div className="d-flex flex-column my-3">
            <label className="text-dark">Status</label>
            <Select
              styles={customStyles}
              placeholder="Status"
              options={status}
              components={animatedComponents}
              isMulti={false}
              onChange={(e) => handleChangeDrpdwn(e, "status")}
              defaultValue={
                obj
                  ? {
                      label: obj.status,
                      value: obj.discount_type,
                    }
                  : ""
              }
            />
          </div>

          <div className="d-flex align-items-center justify-content-end my-4">
            <Button
              style={btnStyle}
              className="mr-4"
              onClick={handleCancel}
              variant="danger light"
              type="button"
            >
              cancel
            </Button>
            <Button style={btnStyle} variant="success light" type="submit">
              Submit
            </Button>
          </div>
        </form>
      </Card.Body>
    </Card>
  );
};

export default AddDiscount;
