import React, { useState, useEffect } from "react";
import { Card, Table } from "react-bootstrap";

//components
import { clientlist } from "../../utils/const";

import { searchInputStyle } from "../../utils/commonStyles";
import PageTitle from "../../layouts/Pagetitle";
import PaginationComp from "../PaginationComp/PaginationComp";

import { modalOpener } from "../../utils/commonFn";

import ModalComp from "../../ModalComp/ModalComp";
import axiosInstance from "../../../services/AxiosInstance";
import BookingsData from "./BookingsData";

const Bookings = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [displayData, setDisplayData] = useState("");
  const [foundResult, setFoundResult] = useState(clientlist);
  const [name, setName] = useState("");
  const [openAddOrderModal, setOpenAddOrderModal] = useState(false);
  const [bookingsData, setBookingsData] = useState([]);
  const disaplayDataHandler = (data) => {
    setDisplayData(data);
  };
  const filter = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      const results = clientlist.filter((user) => {
        return (
          user?.id.toLowerCase().startsWith(keyword.toLowerCase()) ||
          user?.email.toLowerCase().startsWith(keyword.toLowerCase()) ||
          user?.name.toLowerCase().startsWith(keyword.toLowerCase()) ||
          user?.phone_no.toLowerCase().startsWith(keyword.toLowerCase()) ||
          user?.price.toLowerCase().startsWith(keyword.toLowerCase()) ||
          user?.status.toLowerCase().startsWith(keyword.toLowerCase())
        );
      });
      setFoundResult(results);
    } else {
      setFoundResult(clientlist);
    }
    setName(keyword);
  };

  useEffect(() => {
    axiosInstance.get("don/get-bookings").then((res) => {
      console.log("ORders data", res.data.data);
      setBookingsData(res.data.data);
    });
  }, []);
  return (
    <div>
      {/* <PageTitle activeMenu="Orders" motherMenu="Clients" /> */}
      <div className="col-sm-12">
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <Card>
              <Card.Header>
                <Card.Title>Bookings List</Card.Title>
                {/*       <button
                  onClick={() => modalOpener(setOpenAddOrderModal)}
                  className="btn btn-info shadow"
                >
                  Add Order
                </button> */}
              </Card.Header>
              <Card.Body>
                <div className="table-responsive">
                  <div class="form-group">
                    <input
                      type="search"
                      onChange={filter}
                      value={name}
                      style={searchInputStyle}
                      id=""
                      aria-describedby="helpId"
                      placeholder="Search anything...."
                    />
                  </div>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th scope="col" className="text-white">
                          Date
                        </th>
                        <th scope="col" className="text-white">
                          Booking Type
                        </th>
                        <th scope="col" className="text-white">
                          Booking Id
                        </th>
                        <th className="text-white">Order Amount</th>

                        {/*        <th className="text-white">Email</th> */}
                        <th scope="col" className="text-white">
                          Status
                        </th>
                        <th scope="col" className="text-white">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {bookingsData && foundResult.length > 0 ? (
                        <BookingsData data={bookingsData} />
                      ) : (
                        <h6>No results found!</h6>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
              <Card.Footer>
                {foundResult ? (
                  <PaginationComp
                    data={foundResult}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    disaplayDataHandler={disaplayDataHandler}
                    searchedText={name}
                  />
                ) : (
                  <PaginationComp
                    data={bookingsData}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    disaplayDataHandler={disaplayDataHandler}
                    searchedText={name}
                  />
                )}
              </Card.Footer>
            </Card>
          </div>
        </div>
      </div>
      {/*       <ModalComp
        isOpen={openAddOrderModal}
        setisOpen={setOpenAddOrderModal}
        title="Add Member"
        hideButtons
      >
        <AddOrder closeModal={setOpenAddOrderModal} />
      </ModalComp> */}
    </div>
  );
};
export default Bookings;
