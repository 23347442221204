import { useState, useEffect } from "react";
import { Card, Table } from "react-bootstrap";
import swal from "@sweetalert/with-react";

// Comp
import AdditionalServiceTable from "./AdditionalServiceTable";
import AddAdditionalService from "./AddAdditionalService";
import ModalComp from "../../ModalComp/ModalComp";
import PaginationComp from "../PaginationComp/PaginationComp";

// Utils
import {
  addServiceList,
  additionalServices,
  serviceStatus,
} from "../../utils/const";
import { modalOpener } from "../../utils/commonFn";

import { searchInputStyle } from "../../utils/commonStyles";

// API
import {
  getAdditionalServicesApi,
  deleteAdditionalServiceApi,
} from "../../utils/apiService/serviceApis/additionalService";

const AdditionalServices = () => {
  const [openAddAdditionalService, setOpenAddAdditionalService] =
    useState(false);
  const [openEditAdditionalService, setOpenEditAdditionalService] =
    useState(false);
  const [openDeleteAdditionalService, setOpenDeleteAdditionalService] =
    useState(false);

  const [displayData, setDisplayData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSearchedPage, setCurrentSearchedPage] = useState(1);

  const [isSearched, setIsSearched] = useState(false);

  const [searchedText, setSearchedText] = useState("");
  const [searchedResult, setSearchedResult] = useState("");
  const [isNotFound, setIsNotFound] = useState(false);

  const [additionalServiceList, setAdditionalServiceList] = useState("");
  const [addSerId, setAddSerId] = useState("");
  const [dataToEdit, setDataToEdit] = useState("");

  const disaplayDataHandler = (data) => {
    setDisplayData(data);
  };

  const handleDelete = (id) => {
    setAddSerId(id);
    modalOpener(setOpenDeleteAdditionalService);
  };

  const handleEdit = (obj) => {
    setDataToEdit(obj);
    modalOpener(setOpenEditAdditionalService);
    console.log(obj);
  };

  const handleConfirmDelete = (id) => {
    if (id) {
      deleteAdditionalServiceApi(id)
        .then((res) => {
          swal("Success", res.message, "success");
          getAddServiceList();
          setAddSerId("");
        })
        .catch((err) => {
          console.log(err);
        });
      setOpenDeleteAdditionalService(false);
    }
  };

  const getAddServiceList = () => {
    getAdditionalServicesApi()
      .then((res) => {
        console.log(res);
        res?.map((item, index) => {
          item.sNo = index + 1;
        });
        setAdditionalServiceList(res);
      })
      .catch((err) => console.log(err));
  };

  const provideSearchData = (data, searchedText) => {
    const filteredArr = data.filter((item) => {
      return (
        item.service.toLowerCase().trim().includes(searchedText) ||
        item.sub.toLowerCase().trim().includes(searchedText) ||
        item.price.toLowerCase().includes(searchedText) ||
        item.status.toLowerCase().trim().includes(searchedText)
      );
    });
    if (filteredArr.length) {
      setSearchedResult(filteredArr);
      setIsNotFound(false);
      return;
    }
    setIsNotFound(true);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    if (value) {
      const trimmedSearchedText = value.toString().toLowerCase().trim();
      setIsSearched(true);
      setSearchedText(value);
      provideSearchData(addServiceList, trimmedSearchedText);
      return;
    }
    setIsSearched(false);
    setSearchedText("");
    setCurrentSearchedPage(1);
    setSearchedResult("");
    setIsNotFound(false);

    return;
  };

  useEffect(() => {
    getAddServiceList();
  }, []);

  return (
    <div>
      <Card.Header>
        <Card.Title>Services</Card.Title>

        <button
          onClick={() => {
            modalOpener(setOpenAddAdditionalService);
          }}
          className="btn btn-info shadow  "
        >
          Add Additional Service
        </button>
      </Card.Header>
      <Card.Body>
        <div class="form-group">
          <input
            type="search"
            style={searchInputStyle}
            aria-describedby="helpId"
            placeholder="Search anything...."
            onChange={(e) => handleSearch(e)}
          />
        </div>
        <Table responsive>
          <thead>
            <tr>
              <th>
                <strong>Additional Services</strong>
              </th>
              <th>
                <strong>Additional Sub Services</strong>
              </th>

              <th>
                <strong>Price</strong>
              </th>
              <th>
                <strong>Duration</strong>
              </th>
              <th>
                <strong>Status</strong>
              </th>
              <th>
                <strong>Actions</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            {!isNotFound && displayData ? (
              <AdditionalServiceTable
                data={displayData}
                handleEdit={(obj) => handleEdit(obj)}
                handleDelete={(id) => handleDelete(id)}
              />
            ) : (
              <tr>
                <td>
                  <h2>No data found</h2>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card.Body>
      <Card.Footer>
        {searchedResult.length ? (
          <PaginationComp
            data={searchedResult}
            currentPage={currentSearchedPage}
            setCurrentPage={setCurrentSearchedPage}
            disaplayDataHandler={disaplayDataHandler}
            searchedText={searchedText}
          />
        ) : additionalServiceList ? (
          <PaginationComp
            data={additionalServiceList}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            disaplayDataHandler={disaplayDataHandler}
          />
        ) : null}
      </Card.Footer>

      <ModalComp
        isOpen={openDeleteAdditionalService}
        setisOpen={setOpenDeleteAdditionalService}
        handleConfirm={() => handleConfirmDelete(addSerId)}
        title="Delete Additional Service"
      >
        <p>Are you sure?</p>
      </ModalComp>

      <ModalComp
        isOpen={openEditAdditionalService}
        setisOpen={setOpenEditAdditionalService}
        title="Edit Service"
        hideButtons
      >
        <AddAdditionalService
          options1={additionalServices}
          options2={serviceStatus}
          closeModal={setOpenEditAdditionalService}
          getList={getAddServiceList}
          obj={dataToEdit}
        />
      </ModalComp>
      <ModalComp
        isOpen={openAddAdditionalService}
        setisOpen={setOpenAddAdditionalService}
        title="Add Service"
        hideButtons
      >
        <AddAdditionalService
          options1={additionalServices}
          options2={serviceStatus}
          closeModal={setOpenAddAdditionalService}
          getList={getAddServiceList}
        />
      </ModalComp>
    </div>
  );
};

export default AdditionalServices;
