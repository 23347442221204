import { useState } from "react";
import { Col, Card } from "react-bootstrap";

// Comp
import SameDayInstall from "./SameDayInstall";
import AdditionalServices from "./AdditionalServices";
import SliderBtn from "./SliderBtn/SliderBtn";
import ServiceComp from "./SeviceComp";

const Services = () => {
  const [compToShow, setCompToShow] = useState(1);

  return (
    <div>
      <Col lg={12}>
        <Card>
          <div>
            <SliderBtn
              controller={compToShow}
              setController={setCompToShow}
              first="Appointment Type"
              second="Options"
              third="Additional services"
            />
          </div>
          {compToShow === 1 && <ServiceComp />}
          {compToShow === 2 && <SameDayInstall />}
          {compToShow === 3 && <AdditionalServices />}
        </Card>
      </Col>
    </div>
  );
};

export default Services;
