import React, { useState, useEffect } from "react";

import { Box, Card, Grid } from "@material-ui/core";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import axiosInstance from "../../../services/AxiosInstance";
import { customStyles } from "../../utils/commonStyles";
import Select from "react-select";
import { imageBaseURL } from "../../utils/const";
import swal from "sweetalert";
import moment from "moment";

function BookingDetailsPage(route) {
  const { state } = useLocation();

  const [bookingType, setBookingType] = useState(null);

  const [bookingDetails, setBookingDetails] = useState({
    orderinfo: null,
    order_items: null,
    billing_address: null,
  });
  console.log(bookingDetails);
  const [loading, setLoading] = useState(false);

  console.log("BOOKING STATE", state);

  console.log("loading", loading);

  const getBookingDetails = () => {
    setBookingType(state.type);

    if (state.type === "appointments") {
      axiosInstance.get(`don/appointmentorderbyid/${state.id}`).then((res) => {
        console.log("DATA", res.data.data);
        setBookingDetails(res.data.data);

        console.log("restatus", res.status);
        /* 
        if (res.status === 200) {
          setLoading(false);
        } */
      });
    } else {
      axiosInstance.get(`don/getdropoffbyid/${state.id}`).then((res) => {
        console.log("DATA", res.data.data);
        setBookingDetails(res.data.data);

        console.log("restatus", res.data.data);
        let orderStatusValue = res?.data?.data?.order_status;

        setOrderStatus({
          value: orderStatusValue,
          label: orderStatusValue?.toUpperCase(),
        });

        /* 
        if (res.status === 200) {
          setLoading(false);
        } */
      });
    }
    /*     setLoading(true);
     */
    /*  axiosInstance.get(`don/booking/${state.id}`).then((res) => {
      console.log("DATA", res.data.data);
      setBookingDetails(res.data.data);

      console.log("restatus", res.status);

      if (res.status === 200) {
        setLoading(false);
      }
    }); */
  };

  useEffect(() => {
    getBookingDetails();

    return () => {
      getBookingDetails();
    };
  }, []);

  console.log("DATA", bookingDetails);
  const [orderStatus, setOrderStatus] = useState(null);
  const options = [
    { value: "pending payment", label: "PENDING PAYMENT" },
    { value: "processing", label: "PROCESSING" },
    { value: "completed", label: "COMPLETED" },
  ];

  const orderStatusChange = (e) => {
    console.log("updatebookingstatus", e);
    setOrderStatus(e);
  };

  const updateOrderStatusHandle = () => {
    console.log("SUBMITTED");

    let payloadupdate = {
      order_status: orderStatus.value,
    };

    axiosInstance
      .put(`don/updatebookingstatus/${state.id}`, payloadupdate)
      .then((res) => {
        console.log("UDPATED", res.data.message);
        if (res.data.message === "order status updated!") {
          getBookingDetails();
          swal("Order Status Updated", "", "success");
        }
      });
  };

  return (
    <>
      {loading === true ? (
        <div>
          <span>Loading</span>
        </div>
      ) : (
        <div className="col-md-12">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ paddingBottom: 10 }}>
              <div
                styles={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                }}
              >
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: 20,
                    color: "text-dark",
                  }}
                >
                  Booking Number
                </span>
                <span
                  style={{ fontWeight: "bold", fontSize: 20, color: "orange" }}
                >
                  {" "}
                  {bookingDetails?.id}
                </span>
              </div>
            </div>

            <div style={{ paddingBottom: 10 }}>
              <div
                style={{
                  background: "#0080002b",
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                <span
                  style={{
                    color: "yellowgreen",
                    fontSize: 20,
                    fontWeight: "bold",
                  }}
                >
                  {bookingDetails?.order_status?.toUpperCase()}
                </span>
              </div>
            </div>
          </div>

          <Grid
            container
            md={12}
            sm={12}
            xs={12}
            style={{
              fontSize: 14,
              display: "flex",
              justifyContent: "space-between",
            }}
            spacing={2}
          >
            <Grid item md={6} sm={12} xs={12}>
              <div style={{ marginBottom: 5, marginTop: 20 }}>
                <span
                  style={{
                    color: "grey",
                    fontSize: 16,
                  }}
                >
                  Customer Details
                </span>
              </div>
              <div
                style={{
                  borderRadius: 10,
                  padding: 10,

                  flexDirection: "column",
                  display: "flex",
                  boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                  background: "white",
                  minHeight: 220,
                }}
              >
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <span style={{ minWidth: 130 }}>Customer Name </span>
                  <span>
                    :{" "}
                    {bookingDetails?.user?.firstName +
                      " " +
                      bookingDetails?.user?.lastName}
                  </span>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <span style={{ minWidth: 130 }}>Email Id </span>

                  <span>: {bookingDetails?.user?.email_id}</span>
                </div>

                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <span style={{ minWidth: 130 }}>Phone</span>
                  <span> : {bookingDetails?.user?.email_id}</span>{" "}
                </div>

                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <span style={{ minWidth: 130 }}> Preferred Name</span>

                  <span>
                    {" "}
                    : {bookingDetails?.orderInfo?.user?.preferred_name}
                  </span>
                </div>

                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <span style={{ minWidth: 130 }}> Pronouns</span>
                  <span> : {bookingDetails?.user?.pronouns}</span>
                </div>

                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <span style={{ minWidth: 130 }}> DOB</span>
                  <span> : {bookingDetails?.user?.dob}</span>{" "}
                </div>

                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <span style={{ minWidth: 130 }}> Occupation</span>
                  <span> : {bookingDetails?.user?.occupation}</span>
                </div>

                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <span style={{ minWidth: 130 }}>Skintone</span>
                  <span> : {bookingDetails?.user?.skintone}</span>
                </div>

                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <span style={{ minWidth: 130 }}>Head Size</span>
                  <span> : {bookingDetails?.user?.headsize}</span>
                </div>
              </div>
            </Grid>

            <Grid item md={6} sm={12} xs={12}>
              <div style={{ marginBottom: 5, marginTop: 20 }}>
                <span
                  style={{
                    color: "grey",
                    fontSize: 16,
                  }}
                >
                  Delivery Address
                </span>
              </div>

              <div
                style={{
                  borderRadius: 10,
                  padding: 10,
                  flexDirection: "column",
                  display: "flex",
                  boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                  background: "white",
                  fontSize: 14,
                  minHeight: 220,
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span style={{ whiteSpace: "nowrap", minWidth: 120 }}>
                    Street Name
                  </span>
                  <span>
                    {" "}
                    {bookingDetails?.booking_delivery_address?.street_name}
                  </span>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span style={{ whiteSpace: "nowrap", minWidth: 120 }}>
                    Area
                  </span>
                  <span> {bookingDetails?.booking_delivery_address?.area}</span>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span style={{ whiteSpace: "nowrap", minWidth: 120 }}>
                    Province
                  </span>
                  <span>
                    {" "}
                    {bookingDetails?.booking_delivery_address?.province}
                  </span>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span style={{ whiteSpace: "nowrap", minWidth: 120 }}>
                    City
                  </span>
                  <span> {bookingDetails?.booking_delivery_address?.city}</span>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span style={{ whiteSpace: "nowrap", minWidth: 120 }}>
                    Postal Code
                  </span>
                  <span>
                    {" "}
                    {bookingDetails?.booking_delivery_address?.postalcode}
                  </span>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span style={{ whiteSpace: "nowrap", minWidth: 120 }}>
                    Phone
                  </span>
                  <span>
                    {" "}
                    {bookingDetails?.booking_delivery_address?.phone}
                  </span>
                </div>
              </div>
            </Grid>
            {/*   <Grid item md={6} sm={6} xs={6}>
        <div
          style={{
            border: "1px solid grey",
            borderRadius: 10,
            padding: 10,
            margin: 10,
            flexDirection: "column",
            display: "flex",
          }}
        >
          <span style={{ color: "white", fontSize: 19 }}>Order Summary</span>
          <span>Date : Sun, may 2, 2022, 06:24 AM</span>

          <span>Sub Total : R250</span>
          <span>Discount : R150</span>

          <span>Total Amount : R250</span>
        </div>
      </Grid> */}
          </Grid>

          <div>
            <div style={{ marginBottom: 5, marginTop: 20 }}>
              <span
                style={{
                  color: "grey",
                  fontSize: 16,
                }}
              >
                Booking Details
              </span>
            </div>

            {state.type === "appointments" ? (
              <Grid
                container
                md={12}
                sm={12}
                xs={12}
                style={{
                  borderRadius: 10,
                  boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                  background: "white",
                }}
              >
                <Grid item md={12} sm={12} xs={12}>
                  <div
                    style={{
                      margin: 10,
                    }}
                  >
                    <div>
                      <h4>Booking Type : {bookingDetails?.booking_type}</h4>
                      <h4>Appointment Details : </h4>
                      <span>
                        {bookingDetails?.booking_order_item?.installation_date}
                      </span>
                      <br />
                      <span>
                        {bookingDetails?.booking_order_item?.appointment_type} :
                        {bookingDetails?.booking_order_item
                          ?.appointment_option === "undefined"
                          ? "None"
                          : bookingDetails?.booking_order_item
                              ?.appointment_option}
                      </span>
                    </div>
                    <br />
                    <div>
                      <h4>Drop Off Details :</h4>
                      <span>
                        {
                          bookingDetails?.booking_order_item
                            ?.drop_off_service_date
                        }
                      </span>
                      <br />
                      {bookingDetails?.booking_adnl_services?.map((val) => (
                        <div>
                          <span>
                            {val.drop_off_parent_name} :{" "}
                            {val.adnl_sub_service_name}
                          </span>
                        </div>
                      ))}
                    </div>
                    <br />
                    <div>
                      <h4>Styling :</h4>
                      <span>
                        {bookingDetails?.booking_order_item?.step3_text}
                      </span>
                    </div>
                  </div>
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                md={12}
                sm={12}
                xs={12}
                style={{
                  borderRadius: 10,
                  boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                  background: "white",
                }}
              >
                <Grid item md={12} sm={12} xs={12}>
                  <div
                    style={{
                      margin: 10,
                    }}
                  >
                    <div>
                      <h4>
                        Booking Type :{" "}
                        {bookingDetails?.booking_type?.toUpperCase()}
                      </h4>
                    </div>
                    <br />

                    {bookingDetails?.booking_drop_items?.map((val) => (
                      <div>
                        <div
                          style={{
                            background: "white",
                            width: 160,
                            height: 35,
                            justifyContent: "flex-start",
                            alignItems: "center",
                            display: "flex",
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        >
                          <span style={{ color: "black", fontWeight: "bold" }}>
                            {val?.wig_name}
                          </span>
                        </div>

                        <div style={{ marginTop: 25, marginBottom: 10 }}>
                          <h4>Drop off:</h4>
                          <span>{val?.drop_off_service_date}</span>
                          <br />
                          {val?.additional_services?.map((adval) => (
                            <div>
                              <span>
                                {adval?.drop_off_parent_name} :{" "}
                                {adval?.adnl_sub_service_name}
                              </span>
                            </div>
                          ))}
                        </div>
                        <div style={{ marginTop: 25, marginBottom: 10 }}>
                          <h4>Appointment:</h4>

                          {val?.appointment_switch === "true" ? (
                            <div>
                              <span>{val?.installation_date}</span>
                              <br />
                              <span>
                                {val?.appointment_type} :{" "}
                                {val?.appointment_option === "undefined"
                                  ? "None"
                                  : val?.appointment_option}
                              </span>
                            </div>
                          ) : (
                            <div>
                              <span>Appointment not selected</span>
                            </div>
                          )}
                        </div>
                        <div>
                          <h4>Styling :</h4>
                          <span>{val?.step3_text}</span>
                        </div>

                        <div style={{ marginTop: 5, marginBottom: 5 }}>
                          <h4>Images :</h4>
                          {val?.step_3_images?.length > 0 ? (
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              {val?.step_3_images?.map((imageval) => (
                                <div>
                                  <img
                                    src={`${imageBaseURL}${imageval?.images_url?.substring(
                                      0
                                    )}`}
                                    style={{
                                      height: 200,
                                      width: 200,
                                      objectFit: "contain",
                                      margin: 5,
                                      background: "black",
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div>
                              <span>Images not uploaded</span>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}

                    <br />
                  </div>
                </Grid>
              </Grid>
            )}
          </div>

          <div>
            <div style={{ marginBottom: 5, marginTop: 20 }}>
              <span
                style={{
                  color: "grey",
                  fontSize: 16,
                }}
              >
                Step3 Images
              </span>
            </div>

            <Grid
              container
              md={12}
              sm={12}
              xs={12}
              style={{
                borderRadius: 10,
                boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                background: "white",
              }}
            >
              <Grid item md={12} sm={12} xs={12}>
                <div style={{ display: "flex" }}>
                  {bookingDetails?.step_3_images?.map((vas) => (
                    <div
                      style={{
                        margin: 5,
                      }}
                    >
                      <img
                        src={`${imageBaseURL}${vas?.images_url?.substring(1)}`}
                        style={{
                          height: 150,
                          width: 150,
                          objectFit: "contain",
                          margin: 5,
                        }}
                      />
                    </div>
                  ))}
                </div>
              </Grid>
            </Grid>
          </div>
          <div>
            <Grid
              container
              md={12}
              sm={12}
              xs={12}
              style={{
                justifyContent: "space-between",
                padding: 10,
              }}
              spacing={2}
            >
              <Grid
                item
                md={6}
                sm={12}
                xs={12}
                style={{ borderRadius: 10, padding: 0 }}
              >
                <div style={{ marginBottom: 5, marginTop: 20 }}>
                  <span
                    style={{
                      color: "grey",
                      fontSize: 16,
                    }}
                  >
                    Booking Summary
                  </span>
                </div>

                <div
                  style={{
                    borderRadius: 10,
                    padding: 10,
                    flexDirection: "column",
                    display: "flex",
                    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                    background: "white",
                    fontSize: 14,
                  }}
                >
                  <h5>Appointment:</h5>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                    }}
                  >
                    {/* <span style={{ minWidth: 100 }}>
                      Install : Glue Frontal
                    </span>
                    <span> R850</span> */}
                    {bookingDetails?.booking_drop_items?.map?.(
                      (order, orderIndex) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>
                              <b>{order?.wig_name} </b>
                              <br />
                              {order?.appointment_switch === "false"
                                ? "No appointment"
                                : `${order?.appointment_type}: ${order?.appointment_option}`}
                            </span>
                            <span>
                              {order?.appointment_switch === "false" ? "" : "R"}
                              {order?.appointment_switch === "false"
                                ? "NA"
                                : order?.appointment_option_price === "none"
                                ? order?.appointment_type_price
                                : order?.appointment_option_price}
                            </span>
                          </div>
                        );
                      }
                    )}
                    <br />
                  </div>
                  <h5>Additional Services :</h5>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* <span style={{ minWidth: 100 }}>
                      Lace Customisation: Bleach knots
                    </span>
                    <span> R850</span> */}
                    {bookingDetails?.booking_drop_items?.map?.(
                      (order, orderIndex) => {
                        return (
                          <div
                            key={orderIndex}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              // justifyContent: "space-between",
                            }}
                          >
                            <span>
                              <b>{order?.wig_name} </b>
                            </span>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                              }}
                            >
                              {order?.additional_services.map(
                                (service, serviceIndex) => {
                                  return (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <span>
                                        {service?.drop_off_parent_name}:{" "}
                                        {service?.adnl_sub_service_name}
                                      </span>
                                      <span>
                                        {service?.adnl_sub_service_price}
                                      </span>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                  <br />
                  <h5>Additional Charge :</h5>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    {bookingDetails?.booking_drop_items?.map(
                      (type, typeIndex) => {
                        return (
                          <div
                            key={typeIndex}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>
                              <b>{type?.wig_name}</b>
                            </span>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <span style={{ minWidth: 100 }}>
                                {type?.don_hair_type_type}
                              </span>
                              <span> R{type?.don_hair_type_price}</span>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                  <br />
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h5 style={{ minWidth: 100 }}>Sub Total</h5>
                    <h5> R{bookingDetails?.sub_total}</h5>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h5 style={{ minWidth: 100 }}>Discount</h5>
                    <h5>
                      {" "}
                      {bookingDetails?.discount_amount === null
                        ? "NA"
                        : "R" + bookingDetails?.discount_amount ?? "NA"}
                    </h5>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h5 style={{ minWidth: 100 }}>Total</h5>
                    <h5> R{bookingDetails?.total_amount}</h5>
                  </div>

                  {bookingDetails?.orderInfo?.delivery_ToDon === "false" && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ whiteSpace: "nowrap", minWidth: 120 }}>
                          Street Name
                        </span>
                        <span>
                          {" "}
                          {bookingDetails?.shipping_address?.street_name}
                        </span>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ whiteSpace: "nowrap", minWidth: 120 }}>
                          Area
                        </span>
                        <span> {bookingDetails?.shipping_address?.area}</span>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ whiteSpace: "nowrap", minWidth: 120 }}>
                          Province
                        </span>
                        <span>
                          {" "}
                          {bookingDetails?.shipping_address?.province}
                        </span>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ whiteSpace: "nowrap", minWidth: 120 }}>
                          City
                        </span>
                        <span> {bookingDetails?.shipping_address?.city}</span>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ whiteSpace: "nowrap", minWidth: 120 }}>
                          Postal Code
                        </span>
                        <span>
                          {" "}
                          {bookingDetails?.shipping_address?.postal_code}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </Grid>
              <Grid
                item
                md={5}
                sm={12}
                xs={12}
                style={{ borderRadius: 10, padding: 0 }}
              >
                <div style={{ marginBottom: 5, marginTop: 20 }}>
                  <span
                    style={{
                      color: "grey",
                      fontSize: 16,
                    }}
                  >
                    Payment Summary
                  </span>
                </div>
                <div
                  style={{
                    borderRadius: 10,
                    padding: 10,

                    flexDirection: "column",
                    display: "flex",
                    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                    background: "white",
                    fontSize: 14,
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span>Date </span>
                    <span>
                      {moment(bookingDetails?.updatedAt).format(
                        "YYYY-MM-DD HH:mm"
                      )}
                    </span>
                  </div>

                  <div
                    style={{
                      borderBottom: "1px solid grey",
                      paddingTop: 5,
                      paddingBottom: 5,
                    }}
                  />

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span style={{ fontSize: 20 }}>Total Amount </span>
                    <span style={{ fontSize: 20 }}>
                      R{bookingDetails?.total_amount}
                    </span>
                  </div>
                  <div
                    style={{
                      borderTop: "1px solid grey",
                      paddingTop: 5,
                      paddingBottom: 5,
                    }}
                  />

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span>Payment Status </span>
                    <span>
                      {" "}
                      {bookingDetails?.payment_detail?.payment_status}
                    </span>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span>Payment ID</span>
                    <span>
                      {" "}
                      {bookingDetails?.payment_detail?.PG_payment_id}
                    </span>
                  </div>

                  <div style={{ marginBottom: 5, marginTop: 20 }}>
                    <span
                      style={{
                        color: "grey",
                        fontSize: 16,
                      }}
                    >
                      Booking Status Update
                    </span>
                  </div>

                  <div
                    style={{
                      borderRadius: 10,
                      padding: 10,
                      flexDirection: "column",
                      display: "flex",
                      boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                      background: "white",
                      fontSize: 14,
                    }}
                  >
                    {/*         <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span style={{ minWidth: 100 }}>Delivery to The Don</span>
                    <span> No</span>
                  </div> */}
                    <Select
                      value={orderStatus}
                      onChange={orderStatusChange}
                      options={options}
                      styles={customStyles}
                      className="select"
                    />
                    <br />

                    <button
                      className="btn btn-primary btn-sm"
                      onClick={updateOrderStatusHandle}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </>
  );
}

export default BookingDetailsPage;
