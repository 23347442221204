import { useState, useEffect, useRef } from "react";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import swal from "@sweetalert/with-react";

import { Card, Button } from "react-bootstrap";
import {
  inputStyle,
  btnStyle,
  customStyles,
} from "../../../utils/commonStyles";

// API
import {
  addAdditionalServiceApi,
  editAdditionalServiceApi,
  getAdditionalStaticServicesApi,
} from "../../../utils/apiService/serviceApis/additionalService";

const AddDropOffAds = ({ closeModal, getList, obj, options2 }) => {
  const durationType = [{ label: "Day", value: "Day" }];
  const animatedComponents = makeAnimated();

  const [serviceListForDrpdwn, setServiceListForDrpdwn] = useState("");
  const [newAdditionalService, setNewAdditionalService] = useState({});

  const formRef = useRef();

  const handleCancel = () => {
    closeModal(false);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setNewAdditionalService({ ...newAdditionalService, [name]: value });
  };

  const handleChangeDrpdwn = (e, key) => {
    const value = e.value;
    setNewAdditionalService({ ...newAdditionalService, [key]: value });
  };

  const checkValidation = () => {
    // if (!newAdditionalService.service_id) {
    //   swal('Error', 'Service required!', 'error');
    // } else if (!newAdditionalService.sub_service_name) {
    //   swal('Error', 'Sub service required!', 'error');
    // } else if (!newAdditionalService.price) {
    //   swal('Error', 'Price required!', 'error');
    // } else if (!newAdditionalService.duration_value) {
    //   swal('Error', 'Duration value required!', 'error');
    // } else if (!newAdditionalService.duration_type) {
    //   swal('Error', 'Duration type required!', 'error');
    // } else if (!newAdditionalService.status) {
    //   swal('Error', 'Status required!', 'error');
    // }
  };

  const handleSubmit = () => {
    if (
      newAdditionalService?.service_id &&
      newAdditionalService?.sub_service_name &&
      newAdditionalService?.price &&
      newAdditionalService?.duration_type &&
      newAdditionalService?.status &&
      newAdditionalService.duration_value
    ) {
      if (!obj) {
        console.log("newAddtional Service", newAdditionalService);
        addAdditionalServiceApi(newAdditionalService)
          .then((res) => {
            closeModal(false);
            swal("Success", res.message, "success");
            getList();
            formRef.current.reset();
            setNewAdditionalService({});
          })
          .catch((err) => console.log(err));
      } else {
        editAdditionalServiceApi(obj.id, newAdditionalService)
          .then((res) => {
            closeModal(false);
            swal("Success", res.message, "success");
            getList();
            formRef.current.reset();
            setNewAdditionalService({});
          })
          .catch((err) => console.log(err));
      }
    } else {
      checkValidation();
    }
  };
  useEffect(() => {
    getAdditionalStaticServicesApi()
      .then((res) => {
        const arr = [];
        res?.data?.map((item) => {
          const data = {
            label: item.service_name,
            value: item.id,
          };
          arr.push(data);
        });
        setServiceListForDrpdwn(arr);
      })
      .catch((err) => {
        console.log(err);
      });

    if (obj)
      setNewAdditionalService({
        ...newAdditionalService,
        service_id: obj.service_id,
        sub_service_name: obj.sub_service_name,
        duration_value: obj.duration_value,
        duration_type: obj.duration_type,
        status: obj.status,
        price: obj.price,
      });

    console.log(obj);
  }, []);

  return (
    <>
      <Card className="light">
        <Card.Body className=" mb-0 container-div">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            action=""
          >
            <div className="d-flex flex-column my-3">
              <label className="text-dark">Select service</label>
              {
                <Select
                  styles={customStyles}
                  placeholder="Select service"
                  options={serviceListForDrpdwn}
                  components={animatedComponents}
                  isMulti={false}
                  onChange={(e) => handleChangeDrpdwn(e, "service_id")}
                  defaultValue={
                    obj
                      ? {
                          label: obj.service.service_name,
                          value: obj.service_id,
                        }
                      : ""
                  }
                />
              }
            </div>

            <div className="d-flex flex-column ">
              <label className="text-dark" htmlFor="sub_service_name">Sub service</label>
              <input
                onChange={(e) => handleChange(e)}
                style={inputStyle}
                type="text"
                name="sub_service_name"
                value={newAdditionalService.sub_service_name}
              />
            </div>

            <div className="d-flex flex-column my-3">
              <label className="text-dark" htmlFor="price">Price</label>
              <input
                onChange={(e) => handleChange(e)}
                style={inputStyle}
                type="text"
                name="price"
                value={newAdditionalService.price}
              />
            </div>
            <div className="d-flex flex-column my-3">
              <label className="text-dark" htmlFor="duration_value">Duration</label>
              <div className=" my-3">
                <div className="row">
                  <div className="col-sm-7">
                    <input
                      onChange={(e) => handleChange(e)}
                      style={inputStyle}
                      type="text"
                      name="duration_value"
                      value={newAdditionalService.duration_value}
                    />
                  </div>
                  <div className="col-sm-5">
                    <Select
                      styles={customStyles}
                      placeholder="Duration"
                      options={durationType}
                      components={animatedComponents}
                      isMulti={false}
                      onChange={(e) => handleChangeDrpdwn(e, "duration_type")}
                      defaultValue={
                        obj
                          ? {
                              label: obj.duration_type,
                              value: obj.duration_type,
                            }
                          : ""
                      }
                    ></Select>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column my-3">
              <label className="text-dark">Status</label>
              <Select
                styles={customStyles}
                placeholder="Select status"
                options={options2}
                components={animatedComponents}
                isMulti={false}
                onChange={(e) => handleChangeDrpdwn(e, "status")}
                defaultValue={
                  obj
                    ? {
                        label: obj.status,
                        value: obj.status,
                      }
                    : ""
                }
              />
            </div>

            <div className="d-flex align-items-center justify-content-end my-4">
              <Button
                style={btnStyle}
                className="mr-4"
                onClick={handleCancel}
                variant="danger light"
                type="button"
              >
                cancel
              </Button>
              <Button style={btnStyle} variant="success light" type="submit">
                Submit
              </Button>
            </div>
          </form>
        </Card.Body>
      </Card>
    </>
  );
};

export default AddDropOffAds;
