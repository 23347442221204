import axios from "axios";
import axiosInstance from "../../../services/AxiosInstance";

// Slider image  API Call
export async function addSliderCollectionImage(obj) {
  try {
    const res = await axiosInstance.post("don/collection-image", obj);
    if (res.status !== 200) {
      return "No response from server";
    }
    return res?.data;
  } catch (error) {
    return false;
  }
}
//

//get slider img
export async function getSliderImg() {
  try {
    const res = await axiosInstance.get("don/collection-image");
    if (res?.status !== 200) {
      return "No Response from API";
    }
    return res?.data;
  } catch (error) {
    return false;
  }
}

//get by id
export async function getSliderImgByID(id) {
  try {
    const res = await axiosInstance.get(`don/collection-image/${id}`);
    if (res?.status !== 200) {
      return "No Response from API";
    }
    return res?.data;
  } catch (error) {
    return false;
  }
}

//updateSliderImg
export async function updateSliderImg(id, obj) {
  try {
    const res = await axiosInstance.put(`don/collection-image/${id}`, obj);
    if (res?.status !== 200) {
      return "No Response from API";
    }
    return res?.data;
  } catch (error) {
    return false;
  }
}

// delete Slider Img
export async function deleteSliderImg(id) {
  try {
    const res = await axiosInstance.delete(`don/collection-image/${id}`);
    if (res?.status !== 200) {
      return "No Response from API";
    }
    return res?.data;
  } catch (error) {
    return false;
  }
}
//-----------------------------------------------------------------------------------------------------------
//add feature details
export async function addFeatureDetails(obj) {
  try {
    const res = await axiosInstance.post("don/collection-details-images", obj);
    if (res.status !== 200) {
      return "No response from server";
    }
    return res?.data;
  } catch (error) {
    return false;
  }
}

//get feature img
export async function getFeatureImg() {
  try {
    const res = await axiosInstance.get("don/collection-details-images");
    if (res?.status !== 200) {
      return "No Response from API";
    }
    return res?.data;
  } catch (error) {
    return false;
  }
}

//updateFeatureImg
export async function updateFeatureImg(id, obj) {
  try {
    const res = await axiosInstance.put(
      `don/collection-details-images/${id}`,
      obj
    );
    if (res?.status !== 200) {
      return "No Response from API";
    }
    return res?.data;
  } catch (error) {
    return false;
  }
}

// delete Feature Img
export async function deleteFeatureImg(id) {
  try {
    const res = await axiosInstance.delete(
      `don/collection-details-images/${id}`
    );
    if (res?.status !== 200) {
      return "No Response from API";
    }
    return res?.data;
  } catch (error) {
    return false;
  }
}
//-------------------------------------------------------------------------------------------------------
//Long form data
export async function addCollection(obj) {
  try {
    const res = await axiosInstance.post("don/collections", obj);
    if (res.status !== 200) {
      return "No response from server";
    }
    return res?.data;
  } catch (error) {
    return error;
  }
}
//

//get collection
export async function getAllCollection() {
  try {
    const res = await axiosInstance.get("don/collections");
    if (res?.status !== 200) {
      return "No Response from API";
    }
    return res?.data;
  } catch (error) {
    return false;
  }
}

//updateCollection
export async function updateCollection(id, obj) {
  try {
    const res = await axiosInstance.put(`don/collections/${id}`, obj);
    if (res?.status !== 200) {
      return "No Response from API";
    }
    return res?.data;
  } catch (error) {
    return false;
  }
}

// delete collection
export async function deleteCollection(id) {
  try {
    const res = await axiosInstance.delete(`don/collections/${id}`);
    if (res?.status !== 200) {
      return "No Response from API";
    }
    return res?.data;
  } catch (error) {
    return false;
  }
}
