import { useEffect, useState } from "react";
import { Col, Card, Table } from "react-bootstrap";
import swal from "sweetalert";
// Components
import ModalComp from "../../ModalComp/ModalComp";
import MembersTableComp from "./MembersTableComp";
import AddMember from "./AddMember";

import PaginationComp from "../PaginationComp/PaginationComp";

// Utils
import { modalOpener } from "../../utils/commonFn";
import { memberList } from "../../utils/const";

//utils
import { searchInputStyle } from "../../utils/commonStyles";

//api call
import {
  getAllUsers,
  deleteUser,
  getAllMembers,
} from "../../utils/apiService/commonUser";

const Members = () => {
  const [openDeleteMemberModal, setOpenDeleteMemberModal] = useState(false);
  const [openEditMemberModal, setOpenEditMemberModal] = useState(false);
  const [openAddMemberModal, setOpenAddMemberModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [displayData, setDisplayData] = useState("");
  const [foundResult, setFoundResult] = useState("");
  const [name, setName] = useState("");
  const [membersData, setMembersData] = useState("");
  const [memberId, setMemberId] = useState("");
  const [memberObj, setmemberObj] = useState("");
  const [isFound, setIsfound] = useState(false);
  useEffect(() => {
    getUsers();
  }, [openAddMemberModal, openEditMemberModal]);

  const disaplayDataHandler = (data) => {
    setDisplayData(data);
  };

  const filter = (e) => {
    const keyword = e.target.value;
    if (keyword !== "") {
      const results = membersData.filter((user) => {
        return (
          // user?.id.toLowerCase().startsWith(keyword.toLowerCase()) ||

          user?.email_id?.toLowerCase().trim().includes(keyword) ||
          user?.firstName?.toLowerCase().trim().includes(keyword) ||
          user?.lastName?.toLowerCase().trim().includes(keyword) ||
          user?.password?.toLowerCase().trim().includes(keyword) ||
          // user?.delivery_options?.phone.includes(keyword) ||
          user?.role?.toLowerCase().trim().includes(keyword) ||
          user?.status?.toLowerCase().trim().includes(keyword)
        );
      });
      setFoundResult(results);
      setIsfound(false);
      console.log("calling 1st ", foundResult);
    } else {
      setFoundResult(membersData);
      setIsfound(true);
      // console.log("calling 2nd ", foundResult);
    }
    setName(keyword);
  };

  const handleConfirm = () => {
    console.log("confirmed");
  };
  const handleEdit = (obj) => {
    setmemberObj(obj);
    modalOpener(setOpenEditMemberModal);
  };
  const handleDelete = (id) => {
    setMemberId(id);
    console.log(memberId, id, "id member");
    modalOpener(setOpenDeleteMemberModal);
  };
  const handleDeleteConfirm = (id) => {
    if (id) {
      deleteUser(id)
        .then((res) => {
          swal("Well Done!", "Deleted successfully", "success");
          console.log(res, "res");
          getUsers();
          setOpenDeleteMemberModal(false);
        })
        .catch((err) => {
          console.log(err, "error");
          setOpenDeleteMemberModal(false);
        });
    }
  };
  const getUsers = () => {
    getAllMembers()
      .then((res) => {
        // let dataItem = res;
        let dataItem = res?.data.filter((item) => {
          return item.user_type === "staff";
        });
        dataItem.map((ele, i) => {
          ele.slNo = i + 1;
        });
        setMembersData(dataItem);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  return (
    <div>
      <Col lg={12}>
        <Card>
          <Card.Header>
            <Card.Title>Members</Card.Title>
            <button
              onClick={() => {
                modalOpener(setOpenAddMemberModal);
              }}
              className="btn btn-info shadow  "
            >
              Add Member
            </button>
          </Card.Header>
          <Card.Body>
            <div class="form-group">
              <input
                type="search"
                onChange={filter}
                value={name}
                style={searchInputStyle}
                id=""
                aria-describedby="helpId"
                placeholder="Search anything...."
              />
            </div>
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <strong>S.No</strong>
                  </th>
                  <th>
                    <strong>Full name</strong>
                  </th>
                  <th>
                    <strong>Email ID</strong>
                  </th>

                  <th>
                    <strong>User Role</strong>
                  </th>
                  <th>
                    <strong>Status</strong>
                  </th>
                  <th>
                    <strong>Actions</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {!isFound && displayData ? (
                  <MembersTableComp
                    data={displayData}
                    handleEdit={(obj) => {
                      handleEdit(obj);
                    }}
                    hanndleDelete={(id) => handleDelete(id)}
                  />
                ) : (
                  <h6>No results found!</h6>
                )}
              </tbody>
            </Table>
          </Card.Body>
          <Card.Footer>
            {foundResult ? (
              <PaginationComp
                data={foundResult}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                disaplayDataHandler={disaplayDataHandler}
                searchedText={name}
              />
            ) : membersData ? (
              <PaginationComp
                data={membersData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                disaplayDataHandler={disaplayDataHandler}
                searchedText={name}
              />
            ) : null}
          </Card.Footer>
        </Card>
      </Col>

      <ModalComp
        isOpen={openDeleteMemberModal}
        setisOpen={setOpenDeleteMemberModal}
        handleConfirm={() => handleDeleteConfirm(memberId)}
        title="Delete Member"
      >
        <p className="text-center">Are you sure?</p>
      </ModalComp>

      <ModalComp
        isOpen={openEditMemberModal}
        setisOpen={setOpenEditMemberModal}
        title="Edit Member"
        hideButtons
      >
        <AddMember
          editModal={openEditMemberModal}
          obj={memberObj}
          getList={getUsers}
          closeModal={setOpenEditMemberModal}
        />
      </ModalComp>
      <ModalComp
        isOpen={openAddMemberModal}
        setisOpen={setOpenAddMemberModal}
        title="Add Member"
        hideButtons
      >
        <AddMember getList={getUsers} closeModal={setOpenAddMemberModal} />
      </ModalComp>
    </div>
  );
};

export default Members;
