import MetisMenu from "metismenujs";
import React, { Component, useContext, useEffect } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link, NavLink } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import profile from "../../../images/Untitled-1.jpg";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
    //  this.mm("dispose");
    // console.log(this.mm);
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}
const SideBar = () => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);

  const services = ["same-day", "drop-off"];

  let scrollPosition = useScrollPosition();
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  return (
    <div
      className={`deznav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? scrollPosition > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="deznav-scroll">
        {/* <div className='main-profile'>
          <img src={profile} alt='' />
          <Link to={'#'}>
            <i className='fa fa-cog' aria-hidden='true'></i>
          </Link>
          <h5 className='mb-0 fs-20 text-black '>
            <span className='font-w400'>Hello,</span> Marquez
          </h5>
          <p className='mb-0 fs-14 font-w400'>marquezzzz@mail.com</p>
        </div> */}
        <MM className="metismenu" id="menu">
          <li className={`${path === "clients" ? "mm-active" : ""}`}>
            <NavLink className="ai-icon" to="/clients">
              <i className="flaticon-153-user"></i>
              <span className="nav-text">Clients</span>
            </NavLink>
          </li>
          {/*           <li className={`${path === "orders" ? "mm-active" : ""}`}>
            <NavLink to="/orders" className="ai-icon">
              <i className="flaticon-091-shopping-cart"></i>
              <span className="nav-text">Orders</span>
            </NavLink>
          </li> */}
          <li className={`${services.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#">
              <i className="flaticon-091-shopping-cart"></i>
              Orders
            </Link>
            <ul className={`${services.includes(path) ? "mm-show" : ""}`}>
              <li>
                <Link
                  className={`${path === "same-day" ? "mm-active" : ""}`}
                  to="/orders"
                >
                  Collections
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "drop-off" ? "mm-active" : ""}`}
                  to="/all-bookings"
                >
                  Bookings
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${path === "collections" ? "mm-active" : ""}`}>
            <NavLink className="ai-icon" to="/collections">
              <i className="flaticon-381-box-2"></i>
              <span className="nav-text">Collections</span>
            </NavLink>
          </li>

          {/* Start */}
          <li className={`${services.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#">
              <i className="flaticon-073-settings"></i>
              Services
            </Link>
            <ul className={`${services.includes(path) ? "mm-show" : ""}`}>
              <li>
                <Link
                  className={`${path === "same-day" ? "mm-active" : ""}`}
                  to="/same-day"
                >
                  Appointments
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "drop-off" ? "mm-active" : ""}`}
                  to="/drop-off"
                >
                  Additional / Drop off service
                </Link>
              </li>
            </ul>
          </li>
          {/*  */}

          <li className={`${path === "members" ? "mm-active" : ""}`}>
            <NavLink className="ai-icon" to="/members">
              <i className="flaticon-381-user-4"></i>
              <span className="nav-text">Members</span>
            </NavLink>
          </li>

          <li
            className={`${path === "discount-management" ? "mm-active" : ""}`}
          >
            <NavLink className="ai-icon" to="/discount-management">
              <i className="flaticon-381-percentage"></i>
              <span className="nav-text">Discount Management</span>
            </NavLink>
          </li>
          <li className={`${path === "user-role" ? "mm-active" : ""}`}>
            <NavLink className="ai-icon d-flex" to="/user-role">
              <i className="flaticon-381-lock"></i>
              <span className="nav-text" style={{ whiteSpace: "pre" }}>
                User Roles & Permissions
              </span>
            </NavLink>
          </li>
          <li className={`${path === "addons" ? "mm-active" : ""}`}>
            <NavLink className="ai-icon" to="/addons">
              <i className="flaticon-019-add-user"></i>
              <span className="nav-text">Add Ons</span>
            </NavLink>
          </li>
        </MM>
      </PerfectScrollbar>
    </div>
  );
};
export default SideBar;
