import { useState } from "react";
import { Col, Card } from "react-bootstrap";

// Comp
import DropOffSds from "./DropOffSds";
import DropOffSdi from "./DropOffSdi";
import DropOffAds from "./DropOffAds";
import SliderBtndropoff from "../SliderBtn/SliderBtndropoff";
const DropOffService = () => {
  const [compToShow, setCompToShow] = useState(1);

  return (
    <div>
      <Col lg={12}>
        <Card>
          <div>
            <SliderBtndropoff
              controller={compToShow}
              setController={setCompToShow}
              first="Services"
              /*          second='Sub services' */
              third="Add sub service"
            />
          </div>
          {compToShow === 1 && <DropOffSds />}

          {compToShow === 3 && <DropOffAds />}
        </Card>
      </Col>
    </div>
  );
};

export default DropOffService;
