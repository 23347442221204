import {
  ADD_VARIANT_DATA,
  GET_VARIANT_DATA,
  REMOVE_VARIANT_DATA,
} from "../actions/VariantTypes";

const initialState = {
  variantdata: [],
};

export function VariantReducer(state = initialState, action) {
  if (action.type === ADD_VARIANT_DATA) {
    const data = [...state.variantdata];
    data.push(action.payload);
    return {
      ...state,
      data,
    };
  }

  return state;
}
