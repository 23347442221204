import "./slidebtndropoff.css";
const SliderBtndropoff = ({
  controller,
  setController,
  first,
  second,
  third,
}) => {
  const handleSlide = (value) => {
    setController(value);
  };
  return (
    <div className="slider-container">
      <button
        className={`${controller === 1 ? "active-btn" : null}`}
        onClick={(e) => handleSlide(1)}
      >
        {first}
      </button>

      <button
        className={`${controller === 3 ? "active-btn" : null}`}
        onClick={(e) => handleSlide(3)}
      >
        {third}
      </button>
    </div>
  );
};

export default SliderBtndropoff;
