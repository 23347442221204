import { useRef, useState, useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Button } from "react-bootstrap";
//components
import { btnStyle, inputStyle, customStyles } from "../../utils/commonStyles";
import { orderType } from "../../utils/const";

const AddOrder = ({ closeModal }) => {
  const formRef = useRef();
  const animatedComponents = makeAnimated();
  const [addOrderData, setAddOrderData] = useState({
    collection_name:"",
    collection_amount:"",
    order_type:"",
    order_id:"",
    collection_id:""
  })

  const handleCancel = () => {
    closeModal(false);
  };
  const handleChange = (e) =>{
    const name = e.target.name;
    const value = e.target.value;
    setAddOrderData({...addOrderData, [name]: value})
  }
  const handleSelect = (e)=>{
    const value = e.value;
    setAddOrderData({...addOrderData, order_type:value})
  }
  console.log(addOrderData,"add order data")
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-lg 12">
            <form
              ref={formRef}
              onSubmit={(e) => {
                e.preventDefault();
              }}
              action=""
            >
              <div className="form-group">
                <label className="text-dark" htmlFor="">Collection Name</label>
                <input
                  type="text"
                  style={inputStyle}
                  name="collection_name"
                  class="form-control"
                  placeholder="Collection name"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label className="text-dark" htmlFor="">Collection Amount</label>
                <input
                  type="text"
                  style={inputStyle}
                  name="collection_amount"
                  class="form-control"
                  placeholder="Collection Amount"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label className="text-dark" htmlFor="">Collection Amount</label>
                <Select
                  styles={customStyles}
                  placeholder="Status"
                  options={orderType}
                  components={animatedComponents}
                  isMulti={false}
                  onChange={handleSelect}
                  //   onChange={(e) => handleChangeDrpdwn(e, "status")}
                  //   defaultValue={
                  //     obj
                  //       ? {
                  //           label: obj.status,
                  //           value: obj.status,
                  //         }
                  //       : ""
                  //   }
                />
              </div>
              <div className="row">
                <div className="col-sm-1"></div>
                <div className="col-sm-10">
                  <div className="row">
                    <div className="col-sm-6 col-lg-6">
                      <Button
                        style={btnStyle}
                        className="mr-4"
                        onClick={handleCancel}
                        variant="danger light"
                        type="button"
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="col-sm-6 col-lg-6">
                      <Button
                        style={btnStyle}
                        className="mr-4"
                        variant="success light"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="col-sm-1"></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddOrder;
